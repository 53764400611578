// bunch of old pages still using this one
#sr_contain {
  // margin-top:20px;
}

/*both search and results*/

#sr_right_sort{
  margin-bottom:10px;
}

.landing_d {
  margin-bottom:-10px;
}

.landing_d hr{
  margin-top:30px;
}

#sr_left {
  background-color:#f1f3f5;
}

.sr_left_sgroup{
  float:left;
  width:100%;
  border-bottom:1px solid #CCCCCC;
  padding-bottom:10px;
}

.sr_refine_row {
  margin-bottom:20px;
}

.sr_row {
  margin-bottom:20px;
}

.sr_results_found {
  padding-top:8px;
}

.sr_more_list_loc{
  height:20px;
}

.sr_more_list_loc > strong{
  text-decoration:underline;
  margin-left:4px;
}

a.sr_more_list:link {
  margin-left:4px;
}

a.sr_more_list:visited {
  margin-left:4px;
}

a.sr_more_list:hover {
  margin-left:4px;
}

a.sr_more_list:active {
  margin-left:4px;
}

.item_image{
  position:absolute;
  top:0;
  left:0;
  margin-bottom:10px;
/*  background-color:#666;*/
  z-index:20;
}

a.sr_img_lnk:link{
  z-index:100;
  display:block;
}

.sr_image{
  position:relative;
  margin-bottom:0px;
  margin-top:10px;
  top:0px;
  left:0px;
  width:168px;
  height:224px;
/*  background-color:#ccc;*/
}

.corner_holder {
  position:absolute;
  top:0px;
  left:0px;
  height:60px;
  width:50px;
  z-index:30;
}

.sr_corner_new {
  background-image: url('/images/2014/Overlay_New.png');
  background-repeat: no-repeat;
  background-position:top left;
  float:left;
  height:50px;
  width:22px;
  top:0px;
  left:0px;
  z-index:21;
}

.sr_corner_disc {
  background-image: url('/images/2014/Overlay_Sale.png');
  background-repeat: no-repeat;
  background-position:top left;
  float:left;
  height:50px;
  width:22px;
  top:0px;
  left:0px;
  z-index:21;
}
.sr_spacer {
  float:left;
  height:50px;
  width:100%;
}

/**************** results page RESULTS ********************/
.reshow {
  color:#347CA3;
  font-weight:bold;
  cursor: pointer;
  padding-left:5px;
  display:none;
  float:right;
}
