.text-uppercase {
  text-transform: uppercase;
}

.text-normal-weight {
  font-weight: 400;
}

.text-weight-700 {
  font-weight: 700;
}

.text-style-normal {
  font-style: normal;
}

.muted {
  @extend %body-txt-small;
  padding: 0 0.2em;
}

.d-flex {
  display: flex;
}

.skip-links {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  z-index: 10;

  &__link {
    height: 0;
    width: 0;
    overflow: hidden;
  }

  &__link:focus {
    height: auto;
    width: auto;
    overflow: auto;
    left: 0;
    color: white;
    background-color: $bs-green;
    padding: 12px;
    border: 2px solid black;
    border-radius: 5px;
    z-index: 999;
  }
}



.sidebar-bg {
  background: $light-blue;
  padding: 15px;

  &.sidebar-bg--left {
    padding: 0 5px;
  }

  &.sidebar-bg--centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  a {
    color: $body-text;
    font-weight: 400;

    &.btn-grn {
      // old nvend style buttons
      color: #fff;
      font-weight: 600;
    }
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

*[hide] {
  display: none;
}

// need to opt elements into this so can override styles from Bootstrap
p,
div,
section,
figure,
img,
h2,
h3,
hr,
a,
button,
table {
  &.mb0 {
    margin-bottom: 0em;
  }

  &.mb05 {
    margin-bottom: 0.5em;
  }

  &.mb1 {
    margin-bottom: 1em;
  }

  &.mb15 {
    margin-bottom: 1.5em;
  }

  &.mb2 {
    margin-bottom: 2em;
  }

  &.mt05 {
    margin-top: 0.5em;
  }

  &.mt1 {
    margin-top: 1em;
  }

  &.mt15 {
    margin-top: 1.5em;
  }

  &.mt2 {
    margin-top: 2em;
  }


  &.mt3 {
    margin-top: 3em;
  }

  &.pb2 {
    padding-bottom: 2em;
  }

  &.mb4 {
    margin-bottom: 4em;
  }

  &.mt4 {
    margin-top: 4em;
  }

  &.pb4 {
    padding-bottom: 2em;
  }

}

// @media screen and (min-width: 768px) and (max-width: 992px) {
//   .container {
//     width: initial;
//   }
// }

@mixin vert-dividers {
  // vertical divider between list items
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: -3px;
    top: 0;
    bottom: 0;
    width: 2px;
    margin: 0.36em 0;
  }

  &:last-of-type::after {
    display: none;
  }
}

// prevent zooming on mobile (esp. iOS) devices
@media screen and (max-width: 767px) {

  input[type="text"],
  input[type="email"],
  input[type="search"],
  select,
  select.form-control,
  textarea,
  textarea.form-control {
    font-size: 1.6rem;
  }
}

@mixin plain-text-input {
  appearance: none;
  border-radius: 0;
}

@mixin bs-btn {
  font-family: $bs-font-family-sans-serif;
  color: $white;
  font-weight: 900;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  border-radius: 4px;
  height: 55px;
  background-color: $bs-green;
  min-width: 180px;
  border: none;
  padding: 0 20px;

  @media screen and (max-width: 315px) {
    min-width: 150px;
  }

  @media screen and (max-width: $screen-xs-max),
  (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    font-size: 15px;
    height: 50px;
  }

  @media screen and (min-width: $screen-md-min) {
    font-size: 16px;
  }

  &:hover,
  &:active,
  &:focus {
    background: $bs-hover-green;
    color: #fff;
    text-decoration: none;
  }
}

.bs-btn {
  @include bs-btn;
}

@mixin bs-btn--secondary {
  @include bs-btn;
  height: 50px;
  background-color: $white;
  border: 3px solid $bs-green;
  color: $bs-green;

  @media screen and (max-width: $screen-xs-max),
  (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    height: 45px;
  }

  &:hover,
  &:active,
  &:focus {
    border: 3px solid $bs-green-secondary !important;
    color: $bs-green-secondary;
    background-color: $white;
  }
}

.bs-btn--secondary {
  @include bs-btn--secondary;
}

.bs-btn--secondary.reversed {
  @include bs-btn--secondary;
  background-color: transparent;
  border: 3px solid $white;
  color: $white;

  &:hover,
  &:active,
  &:focus {
    border-color: $white;
    color: $white;
    background-color: transparent;
  }
}

@mixin bs-btn-caption {
  font-family: $bs-font-family-sans-serif;
  color: $white;
  font-weight: 900;
  border-radius: 4px;
  height: 32px;
  min-width: 90px;
  background-color: $bs-soft-black;

  @media screen and (max-width: $screen-xs-max),
  (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    font-size: 15px;
  }

  @media (min-width: $screen-md-min) {
    font-size: 16px;
  }
}

.bs-btn--caption {
  @include bs-btn-caption;
}

@mixin bs-btn-alert {
  font-family: $bs-font-family-sans-serif;
  color: $white;
  font-weight: bold;
  height: 55px;
  background-color: $bs-red;

  @media screen and (max-width: $screen-xs-max) {
    font-size: 15px;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    font-size: 16px;
  }

  @media (min-width: $screen-md-min) {
    font-size: 18px;
  }
}

.bs-btn--alert {
  @include bs-btn-alert;
}

@mixin bs-btn-copyright {
  font-family: $bs-font-family-sans-serif;
  color: $white;
  height: 35px;
  background-color: $bs-red;

  @media screen and (max-width: $screen-xs-max) {
    font-size: 15px;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    font-size: 16px;
  }

  @media (min-width: $screen-md-min) {
    font-size: 16px;
  }
}

.bs-btn--copyright {
  @include bs-btn-copyright;
}

.bs-btn--red {
  @include bs-btn;
  background-color: $bs-red;

  &:hover,
  &:active,
  &:focus {
    background: $bs-dark-red;
    color: #fff;
    text-decoration: none;
  }
}

.rsp-btn,
a.rsp-btn {
  // @include bs-btn;
  color: #fff;
  background-color: $button-bg;
  border-radius: 3px;
  border: 1px solid transparent;
  font-weight: 600;
  line-height: 24px;
  line-height: calc(2.4/1.4);
  padding-top: 0.498em;
  padding-bottom: 0.503em;
  padding: 0.498em 0.9em 0.503em 0.9em;
  text-transform: uppercase;
  white-space: nowrap;
  min-width: 7.5em;
  height: 40px;

  &:hover,
  &:active,
  &:focus {
    // background: $bs-hover-green;
    background: $dark-green;
    color: #fff;
    // keep bootstrap from changing the font
    // font-weight: 600;
    font-weight: 600;
    text-decoration: none;
  }
}


.rsp-btn-inner {
  margin: 0 auto;
}

a.rsp-btn {
  display: inline-block;
}

a.rsp-btn--secondary,
.rsp-btn--secondary {
  background: transparent;
  border: 3px solid #fff;
  border-radius: 0;
  padding: 0.365em 0 0.355em;
  letter-spacing: 0.05em;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    background: inherit;
  }

  &.rsp-btn--grey {
    color: $grey;
    border-color: $grey;
    background: #fff;
    font-weight: bold;
  }

}

.rsp-btn--red,
a.rsp-btn--red {
  background-image: linear-gradient(to bottom, $red, $dark-red);

  &:hover,
  &:active,
  &:focus {
    background: #a62c15;
  }
}

.rsp-btn--cart {
  padding-left: 2em;
  padding-right: 2em;

  .m-cart-icon {
    fill: #fff;
    width: 1.5em;
    height: 1.5em;
    float: left;
  }

  .rsp-btn-txt {
    padding-left: 0.4em;
  }
}

// .m-account-icon {
//   position: relative;
//   right: 10px;
// }

.mobile-menu-icon {
  display: inline-block;
  width: 30px !important;
  height: 30px !important;
}

.rsp-btn--link,
a.rsp-btn--link {
  color: $blue;
  background-color: white;
  background-image: none;

  &:hover,
  &:active,
  &:focus {
    color: $blue;
    background: white;
    text-decoration: underline;
  }
}

.btn-link--lbg {
  color: darken($blue, 8%);
}

// cta: short for "call to action"

.cta {
  margin: 100px 0 45px 0;

  @media screen and (max-width: $screen-sm-max) {
    margin: 50px 0 25px 0;
  }

  &.cta--landing {
    margin-top: 0;
  }
}

.cta-promo-header,
.cs_title {
  @extend %headline-large;
}

.cta-promo-body,
.callout,
.intro {
  @extend %body-txt-large;
}

.container--main {
  margin-top: 0;

  h1,
  h2.h1 {}
}

%no-bottom-margin {
  margin-bottom: 0;
}

.mb-none {
  @extend %no-bottom-margin;
}

// toggle-able sections
// TODO: refactor the footer (and possibly the main menu) html if
// possible to use these styles as a common starting point

.toggle-group {
  width: 100%;
  float: left;
  border-top: 1px solid #ddd;

  &:last-child {
    border-bottom: 1px solid #ddd;
  }

  ul {
    margin-bottom: 0;
  }

  @media screen and (min-width: 993px) {
    border: none 0;
    width: auto;
    float: none;

    &:last-child {
      border: none 0;
    }
  }

}

.toggle-group-body {
  display: none;
  float: left;
  width: 100%;
  padding-bottom: 1em;

  @media screen and (min-width: 993px) {
    display: block;
  }
}

.toggle-group-toggle {
  z-index: 1;
  position: absolute;
  opacity: 0;

  &:checked~.toggle-group-body {
    display: block;
  }

  &:checked~.toggle-group-heading:after {
    content: '\2013';
    right: 16px;
  }
}

.toggle-group-heading {
  display: block;
  padding: 1em 15px 0.8em 15px;
  font-size: 1em;

  &:after {
    content: '+';
    font-size: 2em;
    position: absolute;
    top: 1px;
    right: 15px;
  }

  @media screen and (min-width: 993px) {
    &:after {
      display: none;
    }
  }
}

.toggle-group-link {
  display: block;

  @media screen and (max-width: 992px) {
    padding: 0.3em 0;
  }

}

.mb {
  display: block;
  display: flex;
  background: $light-blue;
  padding: 10px;
  margin: 1em 0;
}

.mb-fig {
  width: 40%;
}

.mb-img {
  max-width: 100%;
  padding-right: 0.25em;
}

.mb-txt {
  width: 60%;
  padding-left: 0.25em;
}

.mb-head {
  margin-bottom: 0;
}

.mb--pots {
  @media screen and (min-width: 599px) and (max-width: 991px) {
    margin-top: calc(44px + 10px - 1em); // the amount we've shuffled things around minus the original margin amount
  }
}

.mb--catalog,
.mb--pots {
  .mb-head {
    @extend %headline-small;
    color: $blue;

    @media screen and (max-width: 325px) {
      font-size: 1.2em; // tiny bit smaller than design, so it won't wrap
    }
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  .mb-body {
    @extend %body-txt-small;
    color: $body-text;
  }

  @media screen and (min-width: 599px) {
    .mb-fig {
      width: auto;
    }

    .mb-img {
      margin-top: -44px;
      margin-bottom: -10px;
      max-width: 176px;
    }
  }
}

.mb-promos {
  margin-bottom: 2em;
}

.sharethis-buttons {
  margin: 1em 0;
}

.pop-cart-container {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-pop-cart-container;
  padding: 15px;
}

.pop-cart {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  background: #fff;
  padding: 35px 35px 50px;
  border: 10px solid #e1f7f7;

  @media screen and (min-width: 500px) {
    margin: 0 0 0 auto;
  }
}

.pc-close-btn {
  position: absolute;
  right: 10px;
  top: 10px;

  .icon--close {
    width: 20px;
    height: 20px;
    color: black;
  }

}

.pc-added,
.item-avail,
.pc-error {
  font-weight: 600;
}

.pc-added {
  color: $bs-green;
}

.pc-error {
  color: $red;
  margin-bottom: 1.5em;
}

.cart-item {
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-bottom: 1.5em;
  padding-bottom: 1em;

  .wishlist-body & {
    border-bottom: none;
  }
}

.pop-cart .cart-item {
  padding-bottom: 30px;
}

.pc-multi-item-table {
  display: block;
  padding-bottom: 1.5em;
  margin-bottom: 0.5em;
  border-bottom: 2px solid #ddd;
}

.pc-multi-item-link {
  display: block;
  margin-right: 0.5em;
  font-size: 1.1em;
}

.pc-cross-mark {
  font-size: 1.2em;
}

.item-subtotal-amount {
  float: right;
  font-weight: 900;
}

.item-subtotal-label--multi {
  display: inline-block;
  margin-right: 0.5em;
}


.pc-deet {
  @extend %no-bottom-margin;
}

h4.pc-deet {
  color: #042003 !important;
}

.item-sku,
.item-qty,
.pc-meta-cart-link {
  @include bs-body-txt-small;
}

.item-details {

  .item-sku,
  .item-avail,
  .item-low-inventory {
    @extend %no-bottom-margin;
  }
}

.item-qty-label {
  margin-right: 0.4em;
  @include bs-body-txt-small;
}

.item-free {
  color: $red;
}

.item-name,
.item-link,
.h3,
.dlist dt,
a.h3 {
  margin-top: 0;
  @include bs-headline-small;
  margin-bottom: 0;
}

.item-fig {
  margin-right: 15px;
}

.item-thumb-link {
  display: block;
  width: 100%;
  text-align: center;
}

.pc-meta {
  margin-bottom: 1.5em;
}

.pc-subtotal-label {
  padding-right: 0.5em;
}

.pc-subtotal-amount {
  font-weight: 700;
}

.trustpilot-widget {
  margin-bottom: -2.5em;
}

.sidebar-heading {
  @extend %headline-x-small;
  color: #333;
  @extend %no-bottom-margin;
  margin-top: 0.5em;
}

.sidebar-copy {
  @include bs-body-txt-small;
}

.low-inventory {
  font-weight: 700;
  color: $bs-red;
}

.msg {
  a {
    text-decoration: underline;
  }

  &.msg--success {
    color: $dark-green;
  }

  &.msg--error {
    // add common error style here when we get one from Jess
    color: $red;
  }
}

// inherited from nvend.css

.darkLink {
  font-weight: 700;
  color: $blue;
}

.dlist dt {
  margin: 20px 0 0px 0px;
}

.dlist dd,
.dlist dd img {
  margin: 15px 0 15px 0px;
}

.listlink {
  display: block;
  font-weight: 600;
  line-height: 1.3;
}

.product-badge-contain {
  position: absolute;
  top: 15px;
  right: 0px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.product-badge-contain--tiles {
  right: 25px;
}

.product-tile {
  position: relative;
}

[v-cloak] {
  display: none;
}

// custom form labels and checkboxes

.custom-checkbox-input[type=checkbox],
.custom-radio-input[type=radio] {
  opacity: 0;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.custom-label {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  cursor: pointer;
  line-height: 1;
  font-weight: 400;
}

// .custom-label:focus-visible {
//   border: 1px solid black;
// }
:focus-visible {
  outline: 0px solid white;
  ;
  border: 0px solid white;
  ;
}

:focus {
  outline: 0px solid white;
  ;
  border: 0px solid white;
  ;
}

.checkbox label.custom-label.custom-label--checkbox {
  padding-left: 0;
}

.checkbox label.custom-label.custom-label--checkbox>span {
  align-self: baseline;
}

.custom-label:before {
  display: inline-flex;
  content: '';
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid $bs-light-grey;
  align-self: baseline;
}

// .custom-label--checkbox:before,
// .custom-label--color-block:before {
//   border: 2px solid rgb(192,192,195);
// }

.custom-checkbox-input:checked~.custom-label--checkbox:before,
.custom-checkbox-input:checked~.custom-label--color-block:before {
  background: $bs-checkbox-green;
}

.custom-checkbox-input:checked~.custom-label--checkbox:after,
.custom-checkbox-input:checked~.custom-label--color-block:after {
  position: absolute;
  content: '';
  cursor: pointer;
}

.custom-checkbox-input:checked~.custom-label--color-block:after {
  z-index: 1;
  top: 2px;
  left: 2px;
  border: 2px solid #fff;
  width: 16px;
  height: 16px;

  // .custom-checkbox-input:checked ~ .custom-label--checkbox:after {
  //   top: 3px;
  //   left: 7px;
  //   width: 6px;
  //   height: 11px;
  //   border: 2px solid $white;
  //   transform: rotate(45deg);
  //   border-top: none;
  //   border-left: none;
  // }
}

.custom-checkbox-input:checked~.custom-label--checkbox:after {
  @media screen and (max-width: $screen-sm-max) {
    top: 7px;
  }

  top: 7px;
  left: 7px;
  width: 6px;
  height: 11px;
  border: 3px solid $white;
  transform: rotate(45deg);
  border-top: none;
  border-left: none;
}

.custom-label--radio::before {
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid hsla(0, 0, 0, 0.4);
}

.custom-radio-input:checked~.custom-label--radio:before,
.custom-radio-input:checked~label .custom-label--radio:before {
  background-color: $bs-checkbox-green;
  ;
}

.custom-radio-input:checked~.custom-label--radio:after,
.custom-radio-input:checked~label .custom-label--radio:after {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 11px;
  left: 7px;
  display: block;

  @media screen and (max-width: $screen-xs-max) {
    top: 9px;
  }
}

.bp-custom-radio-button {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  font-weight: normal;
  margin-bottom: 1.3em;
}

.bp-custom-radio-button input[type="radio"] {
  position: absolute;
  width: 22px;
  height: 22px;
  opacity: 0.00001;
  margin-top: 0;
}

.bp-custom-radio-button svg {
  width: 22px;
  height: 22px;
  margin-right: 0.5em;
  flex-shrink: 0;
}

.bp-custom-radio-button input[type="radio"]+svg .rb-bg {
  fill: #fff;
  stroke: rgba(0, 0, 0, 0.4);
}

.bp-custom-radio-button input[type="radio"]:checked+svg .rb-bg {
  fill: $bs-green;
}

.bp-custom-radio-button input[type="radio"]:focus+svg .rb-bg {
  stroke-width: 10;
  stroke: $cb-orange;
}

.bp-custom-radio-button input[type="radio"]:checked+svg .rb-dot {
  fill: #fff;
}

.fresh-badge {
  position: fixed;
  bottom: 0;
  left: 0;
  background: $dark-green;
  text-shadow: 1px 1px 2px rgba($dark-green, 0.75);
  color: #fff;
  padding: 0.25em 5em;
  margin: 0;
  border-radius: 0 5px 0 0;
}

.alert.alert-danger a {
  color: $alert-danger-text;
}

.text-serif {
  font-family: $font-family-serif;
}

.taller-input {
  height: 44px;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}

.larger-input {
  height: 44px;
}

.right-border-md {
  padding-right: 30px;

  @media screen and (min-width: $screen-md-min) {
    border-right: 1px solid #ccc;
  }
}

.black-header {
  color: #333;
  font-weight: bold;
}

.line-height-14 {
  line-height: 14px;
}

.left-border-md {
  @media screen and (min-width: $screen-md-min) {
    padding-left: 30px;
  }

  @media screen and (max-width: $screen-sm-max) {
    margin-top: 30px;
  }
}

input,
button,
a {
  &.bold-rsp-button {
    padding-left: 2em;
    padding-right: 2em;
    font-weight: bold;
  }

  &.bold-rsp-button:hover,
  &.bold-rsp-button:active,
  &.bold-rsp-button:focus {
    font-weight: bold;
  }
}

.max-width-500 {
  max-width: 500px;
}

.grid-page-header {
  padding-bottom: 20px;
  margin: 30px 0 15px;
}

h1.account-header {
  font-size: 3.5rem;
}

.account-header-separator {
  padding: 0 10px;
}

.grid-page-header {
  h1 {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .grid-page-header {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}

@media screen and (min-width: $screen-sm-min) {

  .grid-page-header {
    display: flex;
    align-items: center;

    span.email {
      margin-left: auto;
    }
  }

}

.inline-separator {
  display: inline-block;
  padding: 0 10px;
}

ul.vertical-menu {
  list-style-type: none;
  padding-left: 0px;
}

ul.vertical-menu>li {
  border-bottom: 1px solid #ccc;
  padding-top: 9px;
  padding-bottom: 9px;

  &:last-child {
    border: none 0;
  }

  @extend .body-txt-small;
}

.vertical-menu-item a {
  color: #333;
  font-weight: normal;
}

.vertical-menu-item-active a {
  font-weight: bold;
}



.account-form {
  margin-top: 30px;
}

.account-form .control-label {
  margin-bottom: 15px;
}

.account-form .form-group {
  margin-bottom: 25px;
  max-width: 600px;
}

.edisplay,
.wdisplay {
  font-weight: bold;
  padding: 8px 15px;
  margin: 15px 0;
  border: 0 none;
  border-radius: 0;
}

.wdisplay {
  background-color: #f4f7f0;
}

.wishlist-items .item-actions {
  margin-top: 10px;
}

.wishlist-items .cart-action-link {
  font-weight: 600;
  font-size: 14px;
}

.wishlist-items .item-avail {
  font-weight: normal;
}

.wishlist-items p {
  font-size: 14px;
}

.wishlist-items .item-link {
  font-weight: 600;
  font-size: 18px;
}

.wishlist-items .item-name {
  margin-bottom: 5px;
}

h2.black-header {
  margin-top: 5px;
  font-size: 24px;
}

h3.black-header {
  font-size: 20px;
}

.address-compact {
  margin-top: 15px;
}

.address-compact p {
  margin-bottom: 0;
}

.account-address-edit-links {
  font-size: 1.3rem;
  margin-top: 15px;
}

.shipping-equal-billing-link:hover,
.shipping-equal-billing-link:focus,
.shipping-equal-billing-link:active,
.shipping-equal-billing-link {
  background-color: $bs-checkbox-green;
  display: inline-flex;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid $bs-light-grey;
  align-self: baseline;
  position: relative;
}

.shipping-equal-billing-link+span {
  vertical-align: super;
}

.shipping-equal-billing-link::after {
  content: "";
  top: 2px;
  left: 6px;
  width: 6px;
  height: 11px;
  transform: rotate(45deg);
  border: 3px solid #fff;
  border-top: none;
  border-left: none;
  position: absolute;
}

@mixin text-over-images-shadow {
  // use em's (not pixels!) to ensure the shadow scales well across all font-sizes

  text-shadow:
    0 0.1em 0.1em rgba(0, 0, 0, 0.3),
    0 0.1em 0.5em rgba(0, 0, 0, 0.2);
}

@mixin radial-translucent-bg {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  // background-image: radial-gradient(circle at center, rgba(0,0,0,0.4), transparent 90%);
}

// Based on new design 2022
// enable kern
body {
  font-feature-settings: "liga", "kern";
}

%segment {
  white-space: nowrap;
  float: left;
  position: relative;
  display: block;
  margin: 0 calc(0.15em + 1vw);

  &::before {
    content: '\007C';
    display: block;
    position: absolute;
    left: calc(-1 * (0.37em + 1vw));
    font-weight: bolder;
    opacity: 0.5;
  }
}

.separator::after {
  content: "\007C";
  font-weight: bolder;
  opacity: .2;
  margin-right: 10px;
  margin-left: 10px;
}

// Carousel

.carousel-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  align-items: center;
}

/* Mobile View */
@media screen and (max-width: 767px) {
  .carousel-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .shop-all.body-txt-link {
    width: auto;
    margin: 0;
  }
}

.shop-all:hover {
  text-decoration: underline;
  color: #255518;
}

.carousel-heading>h2 {
  margin: 0;
}

.carousel-container {
  display: grid;
  column-gap: 35px;
}

/* Tablet View */
@media screen and (max-width: 991px) {
  .carousel-container {
    width: 100vh;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    padding-right: calc(100vh - 100%);
  }

  .carousel-container.six-columns.categories {
    grid-template-columns: repeat(12, 1fr);
  }
}

.shop-all.body-txt-link {
  display: flex;
  align-items: center;
}

.shop-all--arrow {
  width: 20px;
  height: 20px;
  margin-left: 1rem;
}

.skeleton {
  margin-top: 20px;
}

figure.product-tile-fig {
  transition: all 0.2s ease-in-out;
}

figure.product-tile-fig:hover {
  transform: scale(1.05);
}

.product-title-wrap {
  scroll-snap-align: start;
}

.carousel-root {
  margin-top: 5em;

  @media screen and (max-width: 991px) {
    margin-top: 2.5em;
  }
}

.search-landing .carousel-img-responsive,
.carousel-img-responsive.six-columns {
  @media screen and (max-width: $screen-xs-max) {
    max-width: 128px;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    max-width: 178px;
  }

  @media (min-width: $screen-md-min) {
    // max-width: 188px;
  }
}

.carousel-img-responsive.four-columns {
  @media screen and (max-width: $screen-xs-max) {
    max-width: 270px;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    max-width: 285px;
  }

  @media (min-width: $screen-md-min) {
    max-width: 300px;
  }
}

.carousel-container.four-columns {
  grid-template-columns: repeat(4, 1fr);
}

.carousel-container.six-columns {
  grid-template-columns: repeat(6, 1fr);
}

/* Tablet View */
#maincontent {
  overflow-x: hidden;
}

.product-tile--results>.body-txt-small,
.product-tile--results>h3.body-txt-small,
.product-tile--results>.product-tile-prices {
  margin-top: 10px;
  margin-bottom: 0;
}

.body-text--saleprice {
  margin-left: 1rem;
}

.link-wrapper {
  overflow: hidden;
  position: relative;
}

.product-tile-fig {
  margin-bottom: 0;
}

.solutions {
  text-align: center;
}

.solutions>h3 {
  margin-top: 10px;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

// a{
//   @include bs-body-txt-link;
// }

body,
.virtual-tour__text,
.faq_list_a {
  @include bs-body-txt-medium;
}

.cs_content {
  margin: 0 auto;
  margin-bottom: 120px;

  @media (max-width: $screen-sm-max) {
    max-width: 100%;
  }

  @media (min-width: $screen-md-min) {
    max-width: 80%;
  }

  ul {
    padding-left: 20px;

    li {
      margin-bottom: 20px;

      &::marker {
        color: $bs-green;
      }
    }
  }
}

.cs-body {
  max-width: 860px;
  margin: 0 auto;
}

.faq_general {
  margin: 0 auto;
  margin-bottom: 80px;
  max-width: 860px;
}

.bs-faqs>.bs-faq:first-child {
  border-top: none;
}

.faq_general_heading {
  margin-top: 70px;
}

.no-case {
  text-transform: none !important;
}


.page-link,
.faq_list a,
.item-name a {
  @include bs-headline-small;
  text-transform: none !important;
  color: $bs-dark-green !important;
}

.always-visible {
  display: block !important;
}

.co .row {
  margin-left: 0;
  margin-right: 0;
}


.topic-title {
  margin-top: 60px;
}

.community-article-wrapper {
  @media screen and (max-width: $screen-sm-max) {
    margin-bottom: 90px;
  }

  @media (min-width: $screen-md-min) {
    margin-bottom: 150px;
  }

  &__img {
    margin: 0 auto;
    width: 100%;
    max-width: 900px;
    margin-top: 40px !important;
  }
}

.tips-and-tricks,
.community-article,
.gift-cert-content {
  margin-bottom: 120px;
}

.community-tips-link {
  @media screen and (max-width: $screen-sm-max) {
    margin-top: 50px;
  }

  @media (min-width: $screen-md-min) {
    margin-top: 75px;
  }
}

.community-article {
  margin: 0 auto;
  margin-top: 40px;
  max-width: 900px;
}

.community-article {
  p {
    @extend .body-txt-medium;
  }

  h3 {
    font-family: $bs-font-family-serif;
    font-weight: 900;
    color: $bs-dark-green;
    margin-top: 50px;
    margin-bottom: 10px;

    @media screen and (max-width: $screen-xs-max),
    (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      font-size: 22px;
      line-height: 26px;
    }

    @media screen and (min-width: $screen-md-min) {
      font-size: 26px;
      line-height: 30px;
    }
  }
}

@media screen and (max-width: 991px) {
  .tips-link.shop-all {
    margin: 10px 0 50px;
  }
}

.body-txt-medium.secondary-header--description a {
  @extend .link;
  @extend .bold;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.body-txt-medium a {
  @extend .link;
  @extend .bold;
}

.orders-table {
  margin-top: 40px;
}

.account,
#orderprintable {
  margin-bottom: 70px;
}

#orderprintable {
  .cs_title {
    display: flex;
    align-items: center;

    a {
      margin-left: auto;
    }
  }
}

@media print {
  #orderprintable {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.form-control.bs-input-static.bs-input {
  border-color: #e5e5e5;
  color: #757575;
  cursor: disabled;
}

input[type=checkbox].account-edit-checkbox {
  height: 20px;
  width: 20px;
  margin-left: -25px;
}

.flypage.bs-faqs--wrapper {
  margin-top: 45px;
}

details.bs-faq>summary.bs-faq--question {

  &>h3,
  h4 {
    position: relative;
    padding-right: 20px;

    &::after {
      content: url(/dist/rsp/svg/intermediate-site-icons/Icon_Plus.svg);
      position: absolute;
      right: 0;
    }
  }
}

details.bs-faq[open]>summary {

  &>h3,
  h4 {
    position: relative;

    &::after {
      /* content: '—'; */
      content: url(/dist/rsp/svg/intermediate-site-icons/Icon_Minus.svg);
      position: absolute;
      position: absolute;
      right: 0;
    }
  }
}

details.bs-faq {
  padding: 32px 0;
  border-top: 2px solid #e5e5e5;
  cursor: pointer;
}

details.bs-faq:last-child {
  border-bottom: 2px solid #e5e5e5;
}

.bs-faq--answer {
  margin: auto;
  margin-top: 30px;
}

.bs-faq--question>h3 {
  margin: 0;
}

summary::-webkit-details-marker {
  display: none;
}

.bs-faq h4 {
  // margin-top: 30px;
}

.bs-faq ul>li {
  @extend .body-txt-medium;
}

details.bs-faq.faq-page {
  @media (max-width: 991px) {
    padding: 9px 0;
  }

  @media (min-width: 992px) {
    padding: 16px 0;
  }
}

/* Gift for gardeners, community and community article four column items design */
.row {
  margin-left: 0;
  margin-right: 0;
}

.community-title {
  margin-top: 20px !important;
}

.shop-all--arrow {
  width: 18px;
  height: 18px;
}

.img-responsive {
  margin: 0 auto;
}

.cta-promo-body {
  border: none;
  padding: 0;
}

.topics-view {
  display: flex;
  column-gap: 35px;
  flex-direction: column;
}

@media screen and (max-width: 766px) {
  .fp_cat_row {
    display: grid;
    gap: 35px;
    grid-template-columns: repeat(2, 1fr);
  }

  .fp_cat>p {
    display: none;
  }

  .fp_cat h3 {
    text-align: center;
  }

  .gift-cert-content {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 767px) {
  .gift-cert-content {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 991px) {
  .fp_cat_row {
    display: grid;
    column-gap: 35px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .fp_cat_row {
    display: grid;
    column-gap: 35px;
    grid-template-columns: repeat(4, 1fr);
  }

  .topics-view {
    flex-direction: row;
  }

  .fp_cat {
    width: 100%;
  }
}

.fp_cat_row {
  @media screen and (max-width: $screen-sm-max) {
    margin-bottom: 90px;
  }

  @media (min-width: $screen-md-min) {
    margin-bottom: 120px;
  }
}

.topics-view-item:first-child {
  display: none;
}

.third-promo--wrapper.article {
  @media screen and (max-width: $screen-sm-max) {
    margin-top: 0px;
  }
}

.js-tips-zip-finder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}

.article-separator {
  margin-bottom: 50px;
}

h2.explore-more {
  @media (min-width: $screen-md-min) {
    font-size: 30px;
  }

  margin-bottom: 30px;
}

.new-account-form {
  margin-bottom: 175px;
}

.pl0 {
  padding-left: 0;
}

.pr0 {
  padding-right: 0;
}

.no-orders {
  padding: 15px;
  border: 1px solid $bs-soft-grey;
}

.catalog-request-response-title {
  margin-top: 60px;
}

.catalog-request-response {
  margin-bottom: 90px;
}

.carousel-container.four-columns.catalog-request-container {
  display: flex;

  @media screen and (max-width: $screen-sm-max) {
    justify-content: flex-start;
  }

  @media screen and (min-width: $screen-md-min) {
    justify-content: center;
  }
}

.catalog-request-img-responsive {
  max-width: 190px;
}

.link-as-btn {
  font-size: 16px!important;
  font-weight: 900!important;
  letter-spacing: 1.5px!important;
  margin-left: 25px!important;
}

.account-msg{
  margin-bottom: 30px;
}