.pd-meta-and-reviews {
  margin-top: 45px;
}
.txt-care, .txt-info, .long-desc {
  a {
    color: $bs-green !important;
    font-weight: bold;
    @include bs-body-txt-medium;
  }
}
.pd-details-section {
  margin-bottom: 3em;
  display: flex;
  justify-content: space-between;
  column-gap: 75px;

  @media screen and (max-width: 1150px){
    flex-direction: column;
  }
  @media screen and (min-width: 1151px) and (max-width: 1300px){
    column-gap: 35px;
  }
}

.pd-thumbs {
  @media screen and (min-width: 1151px) and (max-width: 1300px){
    display: none;
  }
}

.pd-thumb-wrap {
  margin-bottom: 7px;
  max-width: 85px;

  &:focus {
    border-color: #999;
    border-width: 2px;
  }

  &.slick-active {
    // if we decide to highlight the active thumbnail
    //border-color: #999;
  }
}

.pd-detail-image {
  position: relative;
  width: 100%;
  // min-height: 390px;

  @media screen and (min-width: 420px) {
    // min-height: 460px;
  }

  @media screen and (min-width: 425px) and (max-width: 767px) {
    // min-height: 530px;
  }

  @media screen and (min-width: 767px) {
    // min-height: 455px;
  }

  @media screen and (min-width: 993px) {
    float: left;
  }

  @media screen and (min-width: 1200px) {
    // min-height: 645px;
  }

  @media screen and (max-width: 767px) {
    // margin-bottom: 1.5em;
  }
}

.pd-img-wrap {
  display: block;
  position: relative;
}

.pd-zoom-icon {
  position: absolute;
  right: 13px;
  bottom: 15px;
  fill: #fff;
  z-index: 1;

  transform: scaleX(-1);
  width: 40px;
  height: 40px;

  .pd-zoom-wrap:hover & {
    opacity: 1;
  }

  .zoom-ready & {
    opacity: 1;
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.pd-images-wrap {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 30px;
  // vertical centering for wide images
  .pd-large-images.slick-initialized .pd-wide-image.slick-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.pd-images{
  display: grid;
  @media screen and (min-width: 1151px) and (max-width: 1300px), (max-width: 767px){
    grid-template-columns: minmax(0px, 100%);
  }
  @media screen and (min-width: 1301px), (min-width: 768px) and (max-width: 1150px){
    grid-template-columns: 85px minmax(0px, 100%);
    column-gap: 30px;
  }
}

.pd-wide-image {
  background-color: $soft-grey;
}

.pd-large-images {
  

  @media screen and (max-width: $screen-xs-max){

  }

  // @media screen and (min-width: $screen-sm-min){
  //   margin-left: 30px;
  // }
  // @media screen and (min-width: $screen-md-min){
  //   margin-left: 35px;
  // }

  li.pd-large-image:nth-child(1n+2) {
    // hide these until Slick is initialized
    display: none;
  }

  &.slick-initialized .pd-large-image.slick-slide {
    display: block;
  }

  .pd-large-image {
    
    // max-width: 715px;
    .figure-caption{
      position: absolute;
      padding: 6px 6px;
      background-color: gray;
      opacity: 0.8;
      color: white;
      font-size: 1.5rem;
      width: 100%;
      bottom: 0;
      margin: 0;
    }

    .caption-title{
      display: block;
      font-weight: bold;
      font-size: 1.75rem;
    }
  
    .caption-descr{
      font-size: 1.5rem;
    }
  }

  // @media screen and (min-width: 768px) and (max-width: 992px) {
  //   margin: 0 15px;
  // }
}

.pd-detail-image-img {
  height: auto;
  width: 100%;
}
.pd-detail-image-img.desktop-view, .desktop-view{
  @media screen and (max-width: $screen-xs-max){
    display: none;
  }
  @media screen and (min-width: $screen-sm-min){
    display: block
  }
}
.pd-detail-image-img.mobile-view, .mobile-view{
  @media screen and (max-width: $screen-xs-max){
    display: block
  }
  @media screen and (min-width: $screen-sm-min){
    display: none;
  }
}

.ribbon-edge, .ribbon-txt {
  display: block;
  float: left;
}

.ribbon-edge {
  margin-right: -1px;
  fill: #777;

  .product-badge--new & {
    fill: $bs-yellow;
  }

  .product-badge--sale & {
    fill: $bs-red;
  }

  .product-badge--trial & {
    fill: $c-trial;
  }

  .product-badge--ondeck & {
    fill: $c-ondeck;
  }

  .product-badge--droped & {
    fill: $c-dropped;
  }

  .product-badge--dropna & {
    fill: $c-drop-na;
  }

  .product-badge--dropdied & {
    fill: $c-drop-died;
  }

  .product-badge--dropls & {
    fill: $c-drop-ls;
  }

  .product-badge--dropnl & {
    fill: $c-drop-nl;
  }

  .product-badge--dropbv & {
    fill: $c-drop-bv;
  }

  .product-badge--stkhold & {
    fill: $c-stkhold;
  }

  .product-badge--futurenew & {
    fill: $c-futurenew;
  }
}

.ribbon-txt {
  background: #777;
  padding: 0 15px 0 11px;
  height: 20px;

  .product-badge--new & {
    background-color: $bs-yellow;
    color: $black;
  }
  .product-badge--sale & {
    background-color: $bs-red;
  }
  .product-badge--trial & {
    background-color: $c-trial;
    color:$white;
  }
  .product-badge--ondeck & {
    background-color: $c-ondeck;
    color:$white;
  }
  .product-badge--dropna & {
    background-color: $c-drop-na;
  }
  .product-badge--dropdied & {
    background-color: $c-drop-died;
  }
  .product-badge--dropls & {
    background-color: $c-drop-ls;
  }
  .product-badge--dropnl & {
    background-color: $c-drop-nl;
  }
  .product-badge--dropbv & {
    background-color: $c-drop-bv;
  }
  .product-badge--stkhold & {
    background-color: $c-stkhold;
  }
  .product-badge--futurenew & {
    background-color: $blue;
    color:$white;
  }
}

.product-badge {
  float:right;
  height: 20px;
  z-index: 1;
  line-height: 20px;
  color: #fff;
  text-transform: uppercase;
  // transform: rotate(-90deg);
  // transform-origin: bottom right;
  margin: 0;
}
.product-badge {
  &--new,
  &--sale,
  &--top,
  &--trial,
  &--ondeck,
  &--dropna,
  &--dropdied,
  &--dropls,
  &--dropnl,
  &--dropbv,
  &--stkhold{
    font-family: $bs-font-family-sans;
    font-size: 14px;
    font-weight: 900;
  }
}
.product-badge--futurenew {
  margin-right:-13px;
  margin-left:-13px;
  margin-top:-26px;
}

.pd-title-head {
  margin-top: 0;
  margin-bottom: 0;
}

.pd-head-species {
  font-size: 0.64em;
  @extend %serif-italic;
}

.series-and-name{
  margin-top: 10px;
}

.pd-prices:has(> *) {
  margin: 20px 0 30px 0;
}

.price--slashed {
  text-decoration: line-through;
}

.pd-item-price {
 @include bs-body-txt-price--large;

  // &.price--sale {
  //   color: $red;
  // }

  &.price--slashed {
    @include bs-body-txt-old-price;;
  }

  &.price--sale{
    color: $bs-red;
  }
}

.pd-qty-price {
  @extend %headline-x-small;


  &.price--sale {
    color: $red;
  }
}

.pd-three-plus {
  color: $bs-green;
  margin-top: 5px;
  margin-bottom: 30px;
}

.pd-bulk-discount-price {
  @extend %headline-x-small;
}

#mv_order_quantity{
  @media screen and (max-width: 425px){
    padding: 12px 0px;
  }
}

.pd-add-to-cart {
  margin: 1em 0;
  display: flex;
  
  @media screen and (max-width: 425px){
    column-gap: 10px;
  }
  @media screen and (min-width: 426px){
    column-gap: 20px;
  }

  span.input-container{
    display: inline-flex;
    align-items: center;
    outline: 1px solid $bs-light-grey;
    border-radius: 3px;
    @media screen and (max-width: $screen-xs-max){
      height: 50px;
    }
    @media screen and (min-width: $screen-sm-min){
      height: 55px;
    }
    .icon{
      width: 15px;
      height: 15px;
    }
    &> .left, &> .right{
      text-align: center;
      cursor: pointer;
      @media screen and (max-width: $screen-xs-max){
        width: 50px;
        height: 50px;
        line-height: 50px;
      }
    
      @media screen and (min-width: $screen-sm-min){
        width: 48px;
        height: 55px;
        line-height: 55px;
      }
    }
    > input{
      

        width: 55px;
      text-align: center;
      border: none;
    }
  }
}

.archive-message{
  margin-top: 40px;
  margin-bottom: 40px;
  color: $bs-red;
}

a.pop-cart-btn {
  padding-right: 2.5em;
  padding-left: 2.5em;
}

// .pd-qty-input {
//   border: 1px solid #ccc;
//   display: block;
//   float: left;

//   @media screen and (min-width: 550px) {
//     margin-right: 0.5em;
//   }

//   @media screen and (max-width: 549px) {
//     display: block;
//     margin-bottom: 1.5em;
//   }
// }

.pd-cart-btn {
  @media screen and (max-width: $screen-xs-max){
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-width: auto;
    padding: 0;
  }
  @media screen and (min-width: $screen-sm-min){
    height: 55px;
  }
  width: max-content
}

.pd-stock-info {
  @extend %no-bottom-margin;
}

.pd-guarantee {
  font-family: $font-family-serif;
  font-weight: 700;
  font-size: 1.35em;
  @extend %no-bottom-margin;
}

.pd-meta-nav,
.pd-meta-sections {
  margin-bottom: 2em;
}

.pd-meta-nav > li:first-child > a.pd-meta-nav-link {
  margin-left: 0;
}

.pd-save-notes-header {
  display: flex;
  justify-content: space-between;
}

.save-notes-msg {
  font-size: 0.9em;
}

.nav-tabs > li > a.pd-meta-nav-link {
  border: none 0;
  border-bottom: 4px solid transparent;
  border-radius: 0;
  @extend %headline-small;
  color: $body-text;
  padding-left: 0;
  padding-right: 0;
  margin: 0 15px;

  &:hover, &:active, &:focus {
    color: $blue;
    background-color: transparent;
    border: none 0;
    border-bottom: 4px solid transparent;
  }
}

.nav-tabs > li.active > .pd-meta-nav-link {
  border-bottom-color: $blue;
  color: $blue;
  &:hover, &:focus {
    border: none 0;
    border-bottom: 4px solid $blue;
    color: $blue;
  }
}

.pd-desc {
  @extend %body-txt-large;
  margin-bottom: 1em;
}

.pd-logo {
  margin-right: 1.5em;
}

.pd-desc-logo-pair {
}

.pd-specs-head {
  @extend %headline-small;
  @media screen and (min-width: 992px) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 991px) {
    margin-top: 13px;
  }
  &.right{
    margin-bottom: 5px;
    @media screen and (max-width: 991px) {
      margin-top: 45px;
    }
  }
}

.pd-specs-h {
  font-weight: 600;
  padding-left: 0;
}

.pd-specs-h,
.pd-specs-d {
  padding: 0.5em;
  background: url(/rsp/css-images/dotted-rule.png) #fff repeat-x bottom left;
}

.pd-specs-table tr:last-child {
  .pd-specs-h, .pd-specs-d {
    background-image: none;
  }
}

.product-tiles {
  border-top: 1px solid #ccc;
  padding-top: 2em;
  margin-bottom: 2em;
}

.product-tiles--recently-viewed,
.product-tiles--recommended {
  .product-tile-wrap:nth-child(1n+4) {
    @media screen and (min-width: 769px) and (max-width: 992px) {
      // we only want to show three/row for tablet
      // 2/row on mobile
      // 4/row on desktop
      display: none;
    }
  }
}

.product-tile-head {
  @extend %headline-large;
  text-align: center;
  margin-bottom: 1em;
}

.product-tile {
  margin-bottom: 30px;
}


.product-tile-link {
  @extend %headline-small;
  @extend %no-bottom-margin;
  display: block;
  // commenting this for now until I hear for sure Jess wants to
  // get rid of it.
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  color: $blue;
}

.product-tile-availability {
  @extend %body-txt-small;
  @extend %no-bottom-margin;
}

.product-tile-price {
  @extend %headline-x-small;
}

.slick-dots {
  bottom: -34px;
  display: none;
}

.slick-dots li button:before {
  font-size: 43px;
}

.slick-dots li.slick-active button:before,
.slick-dots li button:hover:before {
  color: $body-text;
}

.pd-item-out-of-stock {
  color: $red;
  font-weight: 700;
}
.planting-sites {
    @media screen and (min-width:768px) and (max-width:992px) {
        margin-bottom: 50px;
        margin-top: 50px;
    }
    @media screen and (max-width: 767px) {
        margin-bottom: 50px;
        margin-top: 50px;
    }
    @media screen and (min-width:992px) {
        margin-top: 45px;
    }
}
.features {
    margin-left: 18px;
}
.icon-feature {
    width: 35px;
    height: 35px;
}
.pd-feature {
  margin-bottom: 15px;
  margin-top: 0;
}
.txt-info, .txt-care {
    margin-top: 0;
    margin-bottom: 0;
}
.pstn-info {
    @media screen and (min-width: 992px) { 
        height: auto;
    }
}
.pstn-care {
    @media screen and (min-width: 992px) and (max-width: 1200px) { 
        position: relative;
        grid-row: 3;
        grid-column: 2;
        grid-row-end: 2;
        margin-top: 40px;
    }
    @media screen and (min-width: 1200px) { 
        position: relative;
        grid-row: 3;
        grid-column: 2;
        margin-top: 40px;
    }
}
.secondary-section {
    @media screen and (min-width: 992px) and (max-width: 1200px){ 
        display: grid;
        grid-template-columns: 0.9fr 2fr;
        grid-column-gap: 67px;
    }
    @media screen and (min-width: 1200px){ 
        display: grid;
        grid-template-columns: 0.9fr 2fr;
    }
}  
.pd-details-section .pd-item-in-cart {
  margin-bottom: 1.4em;

  a {
    color: $dark-green;
    font-weight: 700;
  }
}

.pd-display-options legend {
  font-weight: 700;
  margin-bottom: 0.3em;
  border: none 0;
  font-size: 1.6rem;
}

.plants-included-table {
  th, td {
    padding: 0 5px 10px 0;
  }
}

.pd-details .body-txt-small{
  font-weight: bold!important;
  margin-top: 35px;
  margin-bottom: 40px;
}

.addtolist-wrapper{
  margin-top: 35px;
}

.js-addtolist{
  font-weight: bold;
  svg {
    width: 28px;
    height: 28px;
    vertical-align: sub;
  }
}

div.slick-active{
  border: 3px solid $white!important;
  padding: 2px;
}

div.slick-current{
  border: 3px solid $bs-green!important;
  padding: 2px;
}

.pd-thumb-img{
  width: 100%;
}

.pd-thumb-wrap.thumbnail-more.slick-slide{
  max-width: 85px;
  height: 100px;
  border: 1px solid $bs-light-grey!important;
}
.pd-thumb-img.thumbnail-more{
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  font-size: 20px;
}

// remove spacing when there is no dots displayed i.e only one dot
.pd-images-wrap:has(.slick-dots>li:only-child){
  margin-bottom: 0px;
}
.long-desc{
  letter-spacing: 0.2px;
}

.icon.help-icon{
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: auto 10px;
}

.h3.short-description, .pd-desc-logo-pair{
  margin-top: 40px;
}

.pd-details--wrapper{
  flex-grow: 1;
  @media screen and (min-width: 1151px) {
    max-width: 510px;
  }
  @media screen and (max-width: $screen-xs-max) {
    margin-top: 30px;
  }
  
}

.plant-details{
  &.holiday{
    @media screen and (max-width: 991px) {
      margin-top: 0px;
    }
    @media screen and (min-width: 992px) {
      margin-top: 100px;
    }
  }
  background: $bs-cyan;
  &.full-bleed{
    box-shadow: 0 0 0 100vmax $bs-cyan;
    clip-path: inset(0 -100vmax);
  }
  // &.container{
  //   max-width: none;
  //   width: auto;
  // }
  >.container{
    >.wrapper{
      display: flex;
      flex-direction: column;
      @media screen and (min-width: $screen-md-min) and (max-width: 1399px){
        height: 480px;
      }
      @media screen and (min-width: 1200px) {
        height: 480px;
      }
      flex-wrap: wrap;
      padding-top: 45px ;
      padding-bottom: 60px ;
      // width: -webkit-fill-available;
      > .detail-row{
        border-bottom: 3px solid white;
        display: flex;
        align-items: center;
        flex: 1 1 60px;
        margin: 0;
        @media screen and (min-width: $screen-md-min) {
          
        }
        @media screen and (min-width: $screen-md-min) and (max-width: 1399px){
          width: calc(50% - 9px);
        }
        @media screen and (min-width: 1200px) {
          width: calc(50% - 15px);
        }
        max-height: 60px;
        >.property{
          font-weight: bold;

          .icon.help-icon{
            margin-left: 5px;
            margin-right: 0;
          }
        }
        >.detail{
          margin-left: auto;
          width: 60%;

        }
      }
      @media screen and (min-width: $screen-md-min) {
        >.detail-row:nth-child(-n+8){
          margin-right: 30px;
        }
        > .detail-row:nth-child(6){
          border-bottom: none;
        }
      }
      > .detail-row:nth-child(12){
        border-bottom: none;
      }
    }
  }
}

.pd-details-section{
  margin-top: 55px;

  @media screen and (max-width: 1200px){
    margin-top: 0px;
  }
}

#flypage-timeframe{
  color: $bs-dark-green;
}

.viewer-back-link{
  color: white;
}

a:focus, a:hover {
  color: inherit;
  text-decoration: none;
}

li.fp-viewer-thumb-box > a{
  &:focus, &:hover{
    border: 3px solid #356c25;
    padding: 2px!important;
    background-color: white!important;
    margin-bottom: 5px;
  }
}
.pre-planned{
  grid-column-start: 2;
  margin-top: 40px;
  .body-txt-link{
    text-transform: none;
  }
  .body-txt-input-small{
    font-weight: bold;
  }
}

.marketing-promo{
  background: #fff;
  border: 10px solid #e1f7f7;
  margin: 70px 0 35px 0;
  display: flex;
  flex-direction: row;
  padding: 30px;
  @media screen and (max-width: $screen-xs-max) {
    flex-direction: column;
    align-items: center;
  }
}

.pd-logo-fig {
  max-width: 164px;
  margin-right: 25px;
}

.marketing-promo-text h3{
  margin-bottom: 5px;
}

.bulb_package{

}

.bulb-package-item{
  padding-top: 10px;
  // padding-bottom: 10px;
  display: flex;
  align-items: baseline;
}

.bulb-package-item:not(:last-child){
  border-bottom: 1px solid #bfbfbf;
}

.bulb-package-label{
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
}

.bulb-package-title{
  // margin-left: 5px;
  flex: auto;
  @media screen and (min-width: 1301px) {
    max-width: 69%;
    font-size: 20px;
  }
  @media screen and (max-width: 1300px) {
    max-width: 60%;
  }
}

.bulb-package-label::before{
  align-self: auto;
}

.bulb-package-label::after{
  @media screen and (min-width: 769px) {
    top: 14px!important;
  }
  @media screen and (max-width: 768px) {
    top: 12px!important;
  }
}

.bulb-package-label .bulb-price .pd-item-price {
  font-size: 22px!important;
  line-height: 34px!important;
}
.bulb-package-label .bulb-price .pd-item-price.price--slashed {
  font-size: 18px!important;
  line-height: 34px!important;
  font-weight: normal;
}
.bulb-package-label .bulb-price .pd-item-price.item-price--sale {
  font-size: 22px!important;
  line-height: 34px!important;
  color: $bs-red;
  font-weight: normal;
}

.bulb-package-label .bulb-price .muted.pd-three-plus {
  display: none;
}

.js-cart-form.holiday-bulbs{
  .plant-details .wrapper.holiday{
    @media screen and (min-width: 1200px) {
      height: 300px!important;
    }
    @media screen and (min-width: 1450px) {
      >.detail-row:nth-child(n+4){
        border-bottom: none;
      }
    }
    @media screen and (min-width: $screen-md-min) and (max-width: 1399px){
      height: 300px;
    }
    @media screen and (min-width: 1400px) {
      flex-direction: row;
      height: 225px!important;

    }

  }
  .detail-row{
    @media screen and (min-width: $screen-md-min) and (max-width: 1399px){
      width: calc(50% - 15px)!important;
    }
    @media screen and (min-width: 1200px) {
      flex: 1 1 60px!important;
    }
    @media screen and (min-width: 1400px) {
      min-width: calc(33% - 26px)!important;
      flex: 0 1 47%!important;
    }
    @media screen and (min-width: 1450px) {
      min-width: calc(33% - 26px)!important;
      flex: 0 1 60px!important;
    }
    .detail{
      width: 70%!important;
    }
  }

}

.pd-meta-and-reviews.holiday-bulbs{
  .secondary-section{
  grid-template-columns: 1fr!important;
  }
}

.buying-options{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buying-option{
  display: flex;
  gap: 30px;
  >.img{
    @media screen and (min-width: 768px){ 
      max-width: 150px;
    }
    @media screen and (max-width: 767px){ 
      max-width: 130px;
    }
    height: fit-content;
  }

  .content{
    text-align: left;
  }
}

.content-container{
  max-width: 860px;
  margin: 0 auto;
}

.homeoutside-embed-wrapper{
  margin-top: 3em;
  margin-bottom: 3em;
}
.garden-3d{
  width: 100%;
  @media screen and (max-width: 575px){
    max-height: 750px;
  }
  @media screen and (min-width: 576px) and (max-width: 991px){
    max-height: 300px;
  }
  @media screen and (min-width: 992px){
    max-height: 450px;
  }
}

.container.recently-viewed {
  margin-bottom: 40px;
  > .carousel-root{
    margin-top: 15px;
  }
}

.container.carousel-might-like {
  > .carousel-root{
    margin-top: 55px;
  }
}

.pd-sets-help.qty-discount {
  margin-left: 10px;
}

@media screen and (max-width: 767px){
  .qty-price-intro{
    display: block;
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 768px){
  .qty-price-intro{
    margin-right: 5px;
  }
}
.qty-price-separator{
  margin: 0 8px;
  color: #9E9E9E;
}