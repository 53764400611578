.pd-pricing {
  .address-selector {
    .shipto {
      @include bs-headline-small;
    }
  }
}

.group-discount-msg {
  margin-bottom: 30px;

  div.msg-wrapper {
    background: #EEF7EB;
    border-radius: 4px;
    padding: 15px;
    padding-left: 20px;
    display: grid;
    grid-template-columns: 0fr 1fr;
    align-items: center;

    // > div {
    //   display: inline-block;
    // }

    img {
      // height: 35px;
      // width: 35px;
      margin-right: 12px;
    }

    strong {
      display: block;
      font-size: 20px;
      line-height: 26px;
      color: $bs-green;

      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
