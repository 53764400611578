.banner {
  // background-color: $bs-dark-green;
  // background-color: $navy;
  // background-position: top center;
  // background-repeat: no-repeat;
  // background-size: cover;
  position: relative;
  &::after {
    @include radial-translucent-bg;
  }
}
.banner-inner {
  text-align: center;
  padding: 1.8em 0 2.2em;
  overflow: hidden;
  z-index: 1;
  @include text-over-images-shadow;
  margin-top: -40px;

  //padding: 4em 0 6em;
  &:hover, &:active, &:focus {
    text-decoration: none;
  }

  @media screen and (min-width: 768px) {
    &.banner-inner--homepage {
      margin-bottom: 10px;
    }
  }

  @media screen and (min-width: 993px) {
    padding: 2.5em 0;
    &.banner-inner--homepage {
      padding-bottom: 7em;
      padding: 30px;
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 3.5em 0 4.5em;
    &.banner-inner--homepage {
      padding: 2.5em 0;
    }
  }
}

.banner-inner--gallery,
.banner-inner--solutions {
  padding: 2em 0 2.3em;

  @media screen and (min-width: 768px) {
    padding: 2.7em 0 2.9em;
  }

  @media screen and (min-width: 992px) {
    padding: 3.8em 0 4.2em;
  }
}

.banner-headline {
  @include headline-x-large;
  // @include headline-large;
  margin: 0;

  &.banner-headline--homepage {
    @include slideshow-header;
  }
}

.banner-subhead {
  &.banner-subhead--homepage {
    @include slideshow-subhead;
    margin-bottom: 0.5em;
  }
}

.banner-link {
  padding-left: 2em;
  padding-right: 2em;
  display: inline-block;
  text-decoration: none;
}

.banner-link--homepage {
  text-shadow: none;
}


@media screen and (max-width: 767px) {
  .banner--results {
    background-position: top left;
    background-size: auto;
  }

  .banner-inner--results {
    padding: 3em 0 2.73em 0;
  }
}

.banner--results {
  background-position: top left;
  background-color: #fff;
}

@media screen and (min-width: 768px) {
  .banner--results {
    background-size: contain;
  }

  .banner-inner--results {
    padding: 5em 0 0.65em 0;
  }
}

@media screen and (min-width: 940px) {
  .banner--results {
    background-position: top center;
    background-size: auto;
    max-width: 940px;
    margin: 0 auto;
  }

  .banner-inner--results {
    padding-bottom: 2.2em;
  }
}

.secondary-header{
  .secondary-header--img{
    width: 100%;
    @media screen and (max-width: $screen-xs-max) {
      height: 65px;
      object-fit: cover;
    }
  }
  .headline-secondary{
    margin-bottom: 15px;
    
    @media screen and (max-width: $screen-xs-max) {
      margin-top: 30px;
      margin-bottom: 0px;
    }
  
    @media (min-width: $screen-sm-min) { 
      margin-top: 55px;
    }
  }
  .secondary-header--description{
    margin-top: 0;
    margin-bottom: 0;
  }
}
