
.gallery {
  p {
    @extend %body-txt-large;
  }
  .desc{
    margin-bottom: 60px;
  }
}



.cta--gallery,
.cta--holiday {
  margin-top: 0;
}

.gallery-tiles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 25px;
  place-items: center;
  align-items: normal;
  @media screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 767px){
    grid-template-columns: 1fr 1fr;
  }

}
.gallery-tile-description{
  @media screen and (max-width: 991px){
    display: none;
  }
}


@supports (-moz-appearance:none) {
.gallery-tiles { align-items:normal; }
}
@supports (-webkit-appearance:none){
  
}

.gallery-tile {
  display: flex;
  margin-bottom: 80px;
}

.garden-img {
  height: 140px;
  margin: 0 auto;
  @media screen and (max-width: 767px){
    height: 110px;
  }


}

.gallery-tile--expanded {
  margin-bottom: 80px;
  @media screen and (min-width: 768px) {
    // this is needed b/c we have 4-wide on desktop and 3-wide
    // on tablet. Height needs to be set so the tiles don't overlap
    // b/c of their varying description text.
  }
}

.gallery-tile-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

p {
  flex: 1 0 auto;
  font-size: 20px;
  line-height: 28px;
  color: #454545;
  margin-top: 5px;

}

.gallery-tile-link {
  display: flex;
  margin-top: 15px;
  @extend %headline-small;
  color: #042003;
  width: 100%;
  transition: 0.4s ease;
  overflow: hidden;
  &:hover,
  &:active,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}
.gallery-tile-image{
  width: 100%;
  overflow: hidden;
  transition: transform 0.4s ease;
}
.gallery-tile-image:hover{
  transform: scale(1.1);
  width:100%;
}

.rsp-btn--galleries {
  padding-left: 2em;
  padding-right: 2em;

  @media screen and (max-width: 450px) {
    width: 100%;
  }
}
