@import "variables.scss";

// vendor styles
$slick-font-family: $font-family-sans;
@import "slick.scss";
@import "slick-theme-custom.scss";

@import "colors";
// green from the current site
$bp-green: #6d9213;

@import "nvend";
@import "nvend_results";
@import "type";
@import "shared";
@import "icons";
@import "header";
@import "nav";
@import "alpha";
@import "footer";
@import "flypage";
@import "amaryllis";
@import "cart";
@import "nvend-overrides";
@import "bootstrap-overrides";
@import "home";
@import "gallery";
@import "banners";
@import "gardens";
@import "shipping";
// @import "listrak-overrides";
@import "forms";

body.rsp {
  background-color: $navy;
  background-color: $bs-dark-green;
  // sticky footer styles follow
  font-size: 1.4em;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

::placeholder,
#ltkpopup-form input::placeholder,
.form-control::placeholder {
  color: #595959;
}

.page-wrap {
  background-color: #fff;
  // sticky footer style follows
  flex: 1 0 auto;
  position: relative;
}

.modal.bp .modal-title {
  font-family: "Merriweather Regular", georgia, times, serif;
  color: $bp-green;
  width: 95%;
  font-size: 2em;
  margin-top: 0.5em;
}

.modal.bp .modal-content {
  border-radius: 0;
}

.modal.bp .modal-header,
.modal.bp .modal-footer {
  border-bottom: none 0;
  padding-left: 35px;
  padding-right: 35px;
}

.modal.bp .modal-header,
.modal.bp .modal-body {
  padding-left: 35px;
  padding-right: 35px;
}

.modal.bp .modal-header .close {
  width: 1.8em;
  height: 1.8em;
  opacity: 1;
  margin-top: -0.3em;
  margin-right: -1.34em;
}

.modal.bp .form-control.snug {
  border-right: none 0;
  width: 100%;
}

.newsletter-signup {
  display: flex;
  width: 95%;
  .form-group {
    flex-grow: 1;
  }

  margin-bottom: 1em;
}

.modal.bp button.snug {
  margin-left: -5px;
}

.modal.bp .form-control {
  border-radius: 0;
}

.modal.bp .btn-default {
  text-transform: uppercase;
  background-image: $btn-green;
  color: #fff;
  border-radius: 0 4px 4px 0;
  border-color: $btn-green;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.modal.bp .modal-footer.modal-footer--newsletter {
  background: url(/rsp/images/Email_Modal.jpg) 0 0 no-repeat;
  background-size: cover;
  height: 130px;
}

.modal.bp .modal-footer.no-top-border {
  border-top: none 0;
}

.newsletter-status-msg .msg--success {
  color: $bp-green;
}

// accessibility styles
.accessibility-title, .privacy-policy.cs_title{
  margin-top: 55px;
}
.accessibility-content, .privacy-policy.cs_content{
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 150px;
}
// Retail Outlet Styles
.retail-title{
  margin-top: 55px;
}
.retail-direction--wrapper{
  margin: 0 auto;
  width: fit-content;
}
.retail-direction{
  margin-top: 25px;
  margin-bottom: 50px;
  color: white!important;
  display: flex;
  place-content: center;
  place-items: center;
  align-self: center;
  width: fit-content;
}

.retail-hours--img-responsive{
  margin-left: auto;
  margin-right: auto;
  width: 860px;
}
.retail-hours {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 3em 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 860px;

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }


  &__season {
    margin-bottom: 2em;
  }

  @media screen and (min-width: $screen-sm-min) {
    align-items: flex-start;
  }

  @media screen and (min-width: $screen-md-min) {
    flex-direction: row-reverse;
    justify-content: flex-end;

    &__detail {
      margin-right: 2em;
    }

    &__season:first-child > h2 {
      margin-top: 0;
    }
  }
}

/* ADA tab focus */
*:focus, *:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
  outline-offset: 1px;
}
a:hover,a:active{
  outline: 0;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}

.dialog.scrollable{
  overflow-y: auto;
}
.faq-contact{
  margin-top: 60px;
  margin-bottom: 180px;
  text-align: center;
  display: flex;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.btn-link{
  @extend .bs-btn;
  text-align: center;
  place-content: center;
  display: flex;
  place-items: center;
}
.caption{
  font-style: italic;
}
.cs-body.returns{
  margin-top: 25px;
}

.btn-link.fit-center{
  width: fit-content;
  margin: 0 auto;
}