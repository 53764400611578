.pp-garden-pdfs {
  display: flex;
  flex-direction: column;
}

.pd-meta-and-reviews .pp-garden-pdf-link {
  display: inline-block;
  margin-bottom: 20px;
  text-decoration: none;
}

.pp-garden-pdf-label {
  font-weight: 700;
  margin-left: 0.5em;
  vertical-align: middle;
}
