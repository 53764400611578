@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/rsp/css-images/ajax-loader.gif") center center no-repeat; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  padding-left: 18px;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 1;
    color: black;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 0px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        background: #333;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/*---
what's left of nvend.csss
- in future our hope is to get all of the pages cleaned up and using html and classes etc
  from the ca. 2017 responsive redesign (e.g. results page, about us, faq, checkout pages etc)
- once all the pages are fully integrated into their new home we should be able to refactor and
  clean out these styles and those _nvend_results.scss
---*/
/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-300-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-300-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-300-subset.ttf") format("truetype");
  font-display: swap; }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-regular-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-regular-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-regular-subset.ttf") format("truetype");
  font-display: swap; }

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-600-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-600-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-600-subset.ttf") format("truetype");
  font-display: swap; }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-700-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-700-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-700-subset.ttf") format("truetype");
  font-display: swap; }

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-800-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-800-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-800-subset.ttf") format("truetype");
  font-display: swap; }

/* merriweather-300 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/merriweather-v19-latin/merriweather-v19-latin-300-subset.woff2") format("woff2"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-300-subset.zopfli.woff") format("woff"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-300-subset.ttf") format("truetype");
  font-display: swap; }

/* merriweather-regular - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/merriweather-v19-latin/merriweather-v19-latin-regular-subset.woff2") format("woff2"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-regular-subset.zopfli.woff") format("woff"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-regular-subset.ttf") format("truetype");
  font-display: swap; }

/* merriweather-italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/merriweather-v19-latin/merriweather-v19-latin-italic-subset.woff2") format("woff2"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-italic-subset.zopfli.woff") format("woff"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-italic-subset.ttf") format("truetype");
  font-display: swap; }

/* merriweather-700 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/merriweather-v19-latin/merriweather-v19-latin-700-subset.woff2") format("woff2"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-700-subset.zopfli.woff") format("woff"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-700-subset.ttf") format("truetype");
  font-display: swap; }

/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2022 Schwartzco Inc.
        License: 2208-FHHPES     
*/
@font-face {
  font-family: 'Lyon Text Web';
  src: url("/fonts/Lyon-Commercial-Type/LyonText-BlackItalic-Web.woff2") format("woff2"), url("/fonts/Lyon-Commercial-Type/LyonText-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal; }

.LyonText-BlackItalic-Web {
  font-family: 'Lyon Text Web';
  font-weight: 900;
  font-style: italic;
  font-stretch: normal; }

@font-face {
  font-family: 'Lyon Text Web';
  src: url("/fonts/Lyon-Commercial-Type/LyonText-Black-Web.woff2") format("woff2"), url("/fonts/Lyon-Commercial-Type/LyonText-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap; }

.LyonText-Black-Web {
  font-family: 'Lyon Text Web';
  font-weight: 900;
  font-style: normal;
  font-stretch: normal; }

/* Google Lato - 400 - regular */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato-Google-Fonts/Lato-Regular.ttf") format("truetype");
  font-display: swap; }

/* Google Lato - 400 - regular italic */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/Lato-Google-Fonts/Lato-Italic.ttf") format("truetype");
  font-display: swap; }

/* Google Lato - 700 - bold */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato-Google-Fonts/Lato-Bold.ttf") format("truetype");
  font-display: swap; }

/* Google Lato - 900 - black */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato-Google-Fonts/Lato-Black.ttf") format("truetype");
  font-display: swap; }

a {
  color: #356c25;
  text-decoration: none; }

h1.headline-secondary {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  color: #042003; }
  @media screen and (max-width: 767px) {
    h1.headline-secondary {
      font-size: 34px;
      line-height: 42px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    h1.headline-secondary {
      font-size: 48px;
      line-height: 56px; } }
  @media (min-width: 992px) {
    h1.headline-secondary {
      font-size: 54px;
      line-height: 60px; } }

h1, .headline-large {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  color: #042003;
  font-weight: 900; }
  @media screen and (max-width: 767px) {
    h1, .headline-large {
      font-size: 30px;
      line-height: 42px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    h1, .headline-large {
      font-size: 40px;
      line-height: 56px; } }
  @media (min-width: 992px) {
    h1, .headline-large {
      font-size: 54px;
      line-height: 60px; } }

h2, .headline-medium, .h2 {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px) {
    h2, .headline-medium, .h2 {
      font-size: 30px;
      line-height: calc(38 / 30); } }
  @media (min-width: 768px) and (max-width: 991px) {
    h2, .headline-medium, .h2 {
      font-size: 40px;
      line-height: calc(50 / 40); } }
  @media (min-width: 992px) {
    h2, .headline-medium, .h2 {
      font-size: 44px;
      line-height: calc(54 / 44); } }

.headline-medium-modified, .h2-modified {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .headline-medium-modified, .h2-modified {
      font-size: 24px;
      line-height: 32px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .headline-medium-modified, .h2-modified {
      font-size: 30px;
      line-height: 44px; } }
  @media (min-width: 992px) {
    .headline-medium-modified, .h2-modified {
      font-size: 30px;
      line-height: 44px; } }

h3, .headline-small, .h3 {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    h3, .headline-small, .h3 {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    h3, .headline-small, .h3 {
      font-size: 22px;
      line-height: 30px; } }

h4, .headline-tiny, .h4 {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    h4, .headline-tiny, .h4 {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    h4, .headline-tiny, .h4 {
      font-size: 20px; } }

h5, .headline-h5, .gift-info-detail {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    h5, .headline-h5, .gift-info-detail {
      font-size: 16px; } }
  @media screen and (min-width: 992px) {
    h5, .headline-h5, .gift-info-detail {
      font-size: 18px; } }

.body-txt-link {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 900;
  color: #356c25;
  text-transform: uppercase;
  letter-spacing: 1px; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .body-txt-link {
      font-size: 15px; } }
  @media screen and (min-width: 992px) {
    .body-txt-link {
      font-size: 16px; } }
  .body-txt-link:hover {
    color: #356c25; }

.body-txt-link.normal {
  letter-spacing: normal;
  text-transform: none; }

.normal {
  letter-spacing: normal;
  text-transform: none; }

.body-txt-link.bold, .faq_list li a.body-txt-link, .faq_list_a li a.body-txt-link, .body-txt-medium a.body-txt-link, .community-article p a.body-txt-link, .bs-faq ul > li a.body-txt-link {
  font-weight: 900; }

.body-txt-medium, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b, .faq_list_a li p, .community-article p, .bs-faq ul > li {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .body-txt-medium, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b, .faq_list_a li p, .community-article p, .bs-faq ul > li {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt-medium, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b, .faq_list_a li p, .community-article p, .bs-faq ul > li {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt-medium, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b, .faq_list_a li p, .community-article p, .bs-faq ul > li {
      font-size: 22px;
      line-height: 34px; } }

.body-txt-medium.bold, .faq_list li.bold, .faq_list li a, .faq_list_a li a, .faq_list_a li b.bold, .faq_list_a li p.bold, .community-article p.bold, .body-txt-medium.secondary-header--description .faq_list li a, .faq_list li .body-txt-medium.secondary-header--description a, .faq_list li.secondary-header--description a, .faq_list li a.secondary-header--description a, .community-article p.secondary-header--description .faq_list li a, .faq_list li .community-article p.secondary-header--description a, .faq_list_a li a.secondary-header--description a, .faq_list_a li b.secondary-header--description a, .faq_list_a li p.secondary-header--description a, .body-txt-medium a.body-txt-medium, .community-article p a.body-txt-medium, .bs-faq ul > li a.body-txt-medium, .bs-faq ul > li.bold {
  font-weight: bold; }

.body-txt-medium.link, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b.link, .faq_list_a li p.link, .community-article p.link, .body-txt-medium.secondary-header--description .faq_list li a, .faq_list li .body-txt-medium.secondary-header--description a, .faq_list li.secondary-header--description a, .faq_list li a.secondary-header--description a, .community-article p.secondary-header--description .faq_list li a, .faq_list li .community-article p.secondary-header--description a, .faq_list_a li a.secondary-header--description a, .faq_list_a li b.secondary-header--description a, .faq_list_a li p.secondary-header--description a, .body-txt-medium a.body-txt-medium, .community-article p a.body-txt-medium, .bs-faq ul > li a.body-txt-medium, .bs-faq ul > li.link, .bs-faq .faq_list ul > li, .faq_list .bs-faq ul > li {
  color: #356c25; }

.body-txt-small, ul.vertical-menu > li, .gift-info-detail {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .body-txt-small, ul.vertical-menu > li, .gift-info-detail {
      font-size: 18px;
      line-height: 26px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt-small, ul.vertical-menu > li, .gift-info-detail {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 992px) {
    .body-txt-small, ul.vertical-menu > li, .gift-info-detail {
      font-size: 20px;
      line-height: 28px; } }

.body-txt-input-small {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .body-txt-input-small {
      font-size: 18px;
      line-height: calc(26 / 18); } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt-input-small {
      font-size: 20px;
      line-height: calc(28 / 20); } }
  @media (min-width: 992px) {
    .body-txt-input-small {
      font-size: 20px;
      line-height: 1; } }

.body-txt--price {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .body-txt--price {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt--price {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt--price {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .body-txt--price {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    .body-txt--price {
      font-size: 20px; } }

.price--large {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .price--large {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .price--large {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .price--large {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .price--large {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    .price--large {
      font-size: 20px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .price--large {
      font-size: 24px; } }
  @media screen and (min-width: 992px) {
    .price--large {
      font-size: 28px; } }
  .price--large .sale {
    color: #da4e2b; }

.body-txt--saleprice {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #042003;
  color: #da4e2b; }
  @media screen and (max-width: 767px) {
    .body-txt--saleprice {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt--saleprice {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt--saleprice {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .body-txt--saleprice {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    .body-txt--saleprice {
      font-size: 20px; } }

.body-txt--oldprice {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #757575;
  text-decoration: line-through; }
  @media screen and (max-width: 767px) {
    .body-txt--oldprice {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt--oldprice {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt--oldprice {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .body-txt--oldprice {
      font-size: 16px; } }
  @media screen and (min-width: 992px) {
    .body-txt--oldprice {
      font-size: 18px; } }

.body-txt--copyright {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  height: 35px;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .body-txt--copyright {
      font-size: 15px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt--copyright {
      font-size: 16px; } }
  @media (min-width: 992px) {
    .body-txt--copyright {
      font-size: 16px; } }

.success-txt {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #356c25; }
  @media screen and (max-width: 767px) {
    .success-txt {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .success-txt {
      font-size: 22px;
      line-height: 32px; } }
  @media (min-width: 992px) {
    .success-txt {
      font-size: 22px;
      line-height: 32px; } }

.error-txt, .co-invalid-group p.co-invalid.error,
.invalid-group p.error {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #da4e2b; }
  @media screen and (max-width: 767px) {
    .error-txt, .co-invalid-group p.co-invalid.error,
    .invalid-group p.error {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .error-txt, .co-invalid-group p.co-invalid.error,
    .invalid-group p.error {
      font-size: 22px;
      line-height: 32px; } }
  @media (min-width: 992px) {
    .error-txt, .co-invalid-group p.co-invalid.error,
    .invalid-group p.error {
      font-size: 22px;
      line-height: 32px; } }

.msg-txt {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .msg-txt {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .msg-txt {
      font-size: 22px;
      line-height: 32px; } }
  @media (min-width: 992px) {
    .msg-txt {
      font-size: 22px;
      line-height: 32px; } }

.BodSmall, .wl-action, .muted, .mb--catalog .mb-body,
.mb--pots .mb-body, .quick-links > li > a, .footer-colophon, .product-tile-availability {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 20px;
  line-height: calc(2/1.3); }

.body-txt-large, .cta-promo-body,
.callout,
.intro, .pd-desc, .cart-help, .gallery p {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .body-txt-large, .cta-promo-body,
    .callout,
    .intro, .pd-desc, .cart-help, .gallery p {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt-large, .cta-promo-body,
    .callout,
    .intro, .pd-desc, .cart-help, .gallery p {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt-large, .cta-promo-body,
    .callout,
    .intro, .pd-desc, .cart-help, .gallery p {
      font-size: 22px;
      line-height: 34px; } }

.cart-alert {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  line-height: 34px !important; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .cart-alert {
      font-size: 16px !important; } }
  @media screen and (min-width: 992px) {
    .cart-alert {
      font-size: 20px !important; } }
  .cart-alert--success {
    background: #eef7eb; }
  .cart-alert--error {
    background: #FEF3F0;
    color: #da4e2b; }

.cta-promo-header,
.cs_title, .product-tile-head, .cart-heading > h1,
.cart-heading > h2 {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  color: #042003;
  font-weight: 900; }
  @media screen and (max-width: 767px) {
    .cta-promo-header,
    .cs_title, .product-tile-head, .cart-heading > h1,
    .cart-heading > h2 {
      font-size: 30px;
      line-height: 42px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .cta-promo-header,
    .cs_title, .product-tile-head, .cart-heading > h1,
    .cart-heading > h2 {
      font-size: 40px;
      line-height: 56px; } }
  @media (min-width: 992px) {
    .cta-promo-header,
    .cs_title, .product-tile-head, .cart-heading > h1,
    .cart-heading > h2 {
      font-size: 54px;
      line-height: 60px; } }

.hp-tile-title--single, .hp-tile-subhead {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 2.0rem;
  line-height: 26px;
  line-height: calc(2.6/2);
  color: #fff; }

.mb--catalog .mb-head,
.mb--pots .mb-head, .acct-signin label, .category-promo-fig, .footer-toll-free, .nav-tabs > li > a.pd-meta-nav-link, .pd-specs-head, .product-tile-link, .notice__heading, .gallery-tile-link {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .mb--catalog .mb-head,
    .mb--pots .mb-head, .acct-signin label, .category-promo-fig, .footer-toll-free, .nav-tabs > li > a.pd-meta-nav-link, .pd-specs-head, .product-tile-link, .notice__heading, .gallery-tile-link {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    .mb--catalog .mb-head,
    .mb--pots .mb-head, .acct-signin label, .category-promo-fig, .footer-toll-free, .nav-tabs > li > a.pd-meta-nav-link, .pd-specs-head, .product-tile-link, .notice__heading, .gallery-tile-link {
      font-size: 22px;
      line-height: 30px; } }

.sidebar-heading, .pd-qty-price, .pd-bulk-discount-price, .product-tile-price, .garden-solutions .toggle-group-heading {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 22px;
  line-height: calc(2.2/1.5); }

.footer-signup-msg a, .footer-toll-free, .footer-toll-free .footer-tf-number a, .footer-toll-free .footer-mailto a {
  color: #fff; }

.pd-head-species {
  font-family: "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-style: italic; }

.footer-toll-free .footer-tf-number a, .footer-toll-free .footer-mailto a, .footer-mailto, .footer-colophon a {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

.bold, .faq_list li a, .faq_list_a li a, .body-txt-medium.secondary-header--description a, .faq_list li.secondary-header--description a, .faq_list li a.secondary-header--description a, .faq_list_a li a.secondary-header--description a, .faq_list_a li b.secondary-header--description a, .faq_list_a li p.secondary-header--description a, .community-article p.secondary-header--description a, .bs-faq ul > li.secondary-header--description a, .body-txt-medium a, .community-article p a, .bs-faq ul > li a {
  font-weight: bold; }

.font-weight-normal {
  font-weight: normal; }

.link, .faq_list li, .faq_list li a, .faq_list_a li a, .body-txt-medium.secondary-header--description a, .faq_list li.secondary-header--description a, .faq_list li a.secondary-header--description a, .faq_list_a li a.secondary-header--description a, .faq_list_a li b.secondary-header--description a, .faq_list_a li p.secondary-header--description a, .community-article p.secondary-header--description a, .bs-faq ul > li.secondary-header--description a, .body-txt-medium a, .community-article p a, .bs-faq ul > li a {
  color: #356c25; }

.disabled-section {
  opacity: 0.5;
  cursor: auto !important; }

.sidebar-bg ul.BodSmall {
  list-style: none;
  margin: 0 0 1em 0;
  padding: 0; }

.sidebar-bg .h6 {
  font-weight: 700;
  font-size: 0.93em;
  color: #367391; }

.textlinki,
a.textlinki {
  font-family: "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  font-size: 13px;
  line-height: 16px;
  color: #516f30; }
  .textlinki:hover, .textlinki:active, .textlinki:focus,
  a.textlinki:hover,
  a.textlinki:active,
  a.textlinki:focus {
    color: #52732d; }

.ListRadio {
  font-size: 13px;
  line-height: 20px;
  color: #333333; }

.alignLeft {
  float: left;
  margin-right: 5px; }

.alignRight {
  float: right;
  margin-left: 5px; }

.callout {
  margin-bottom: 10px; }

/**************PRINT ONLY print only **************/
.printonly {
  display: none; }

/**************2012 other lists **************/
.faq_general > ol.faq_list_a {
  padding-left: 20px; }

.faq_list {
  list-style-position: inside;
  padding-left: 0; }

.faq_list li {
  margin-top: 3px; }

.faq_list_a li {
  border-bottom: 1px solid #CCC;
  padding-top: 35px;
  padding-bottom: 50px; }

.faq_list_a li a {
  font-weight: 600; }

.faq_list_a li b {
  font-weight: 600; }

.bluedot {
  list-style-type: disc; }

.bluedot li {
  color: #3399cc;
  margin-bottom: 5px; }

.bluedot li span {
  color: #000; }

/* General Typography adds Bluestone */
.txtl {
  text-align: left; }

.txtr {
  text-align: right; }

.txtc {
  text-align: center; }

.vat {
  vertical-align: top; }

.vam {
  vertical-align: middle; }

.vab {
  vertical-align: bottom; }

.pb3 {
  padding-bottom: 3px; }

.pb5 {
  padding-bottom: 5px; }

.pb10 {
  padding-bottom: 10px; }

.pb15 {
  padding-bottom: 15px; }

.pb20 {
  padding-bottom: 20px; }

.pt3 {
  padding-top: 3px; }

.pt5 {
  padding-top: 5px; }

.pt10 {
  padding-top: 10px; }

.pt15 {
  padding-top: 15px; }

.pl0 {
  padding-left: 0px; }

.pl3 {
  padding-left: 3px; }

.pl5 {
  padding-left: 5px; }

.pl10 {
  padding-left: 10px; }

.pr3 {
  padding-right: 3px; }

.pr5 {
  padding-right: 5px; }

.pr10 {
  padding-right: 10px; }

.pr15 {
  padding-right: 15px; }

.ml3 {
  margin-left: 3px; }

.ml5 {
  margin-left: 5px; }

.ml10 {
  margin-left: 10px; }

.ml20 {
  margin-left: 20px; }

.mr3 {
  margin-right: 3px; }

.mr5 {
  margin-right: 5px; }

.mr10 {
  margin-right: 10px; }

.mr20 {
  margin-right: 20px; }

.mb0 {
  margin-bottom: 0px; }

.mb3 {
  margin-bottom: 3px; }

.mb5 {
  margin-bottom: 5px; }

.mb10 {
  margin-bottom: 10px; }

.mb20 {
  margin-bottom: 20px; }

.mb25 {
  margin-bottom: 25px; }

.mt0 {
  margin-top: 0px; }

.mt5 {
  margin-top: 5px; }

.mt10 {
  margin-top: 10px; }

.mt15 {
  margin-top: 15px; }

.mt20 {
  margin-top: 20px; }

.mt30 {
  margin-top: 30px; }

.mtb10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.mtb15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.mtb20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.ptb10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.ptb20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.nomargin {
  margin: 0 0 0 0; }

.noleftmargin {
  margin-left: 0; }

.noleftpadding {
  padding-left: 0; }

.img-center {
  display: block;
  margin: auto; }

.border_red {
  border: 1px solid red; }

.border_blue {
  border: 1px solid blue; }

.border_green {
  border: 1px solid green; }

.showcursor {
  cursor: pointer; }

.strike {
  text-decoration: line-through; }

/**End generic typography adds**/
.Bod, .cs_content, .dlist dd {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .Bod, .cs_content, .dlist dd {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .Bod, .cs_content, .dlist dd {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .Bod, .cs_content, .dlist dd {
      font-size: 22px;
      line-height: 34px; } }

.BodBold {
  font-weight: 700;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  color: #333333; }

.Bod_hdr {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #333333; }

.ResultPrice {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #000; }

.poff {
  width: 100%;
  opacity: .80;
  height: 16px; }

h2.ResultItem,
a.ResultItem {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 0px;
  margin-top: 0px; }
  h2.ResultItem:hover,
  a.ResultItem:hover {
    color: #005387; }

.BodSmallBold {
  font-weight: 700; }

/* Form adds */
/*Header 2014 */
.tb_pad {
  padding-bottom: 1%; }

#scart_menuline {
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  text-align: right;
  color: #999; }

.container_cart {
  margin-left: auto;
  margin-right: auto; }

#cart_pop_contain {
  display: none;
  position: absolute;
  height: 320px;
  width: 415px;
  top: 50px;
  right: 5px;
  z-index: 9999; }

#cart_pop {
  display: none;
  position: fixed;
  border: 4px solid #50711a;
  padding: 10px;
  height: 3220x;
  width: 415px;
  background-color: #FFF;
  z-index: 9999; }

#cart_pop > .nomargin {
  margin-top: 12px;
  margin-bottom: 3px; }

.fcolor_g {
  color: #6e9214; }

.SansSmall,
a.SansSmall {
  font-family: Arial;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  color: #666; }

/****************plantfinder PLANT FINDER specific********************/
.b_bg {
  background-color: #f1f3f5; }

/****************fly page flypage FLYPAGE specific********************/
.bb {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #CCC; }

.ListBulleted {
  font-family: "Open Sans Regular";
  font-size: 14px;
  line-height: 21px;
  color: #333; }

UL.ListBulleted {
  list-style: none;
  padding-left: 0;
  margin-left: 15px;
  margin-top: 10px; }

UL.ListBulleted li {
  line-height: 26px;
  margin-left: -15px;
  text-transform: capitalize; }

UL.ListBulleted li:before {
  content: "\2022 \00A0";
  color: #347ca3;
  font-size: 1.5em;
  font-weight: bold;
  vertical-align: middle; }

.Price {
  font-weight: 700;
  font-size: 18px;
  color: #000; }

.PriceSale {
  font-weight: 700;
  font-size: 18px;
  color: #cd3722; }

.PriceSlashed {
  font-size: 14px;
  color: #333;
  text-decoration: line-through; }

/****************BANNER BAR********************/
#banner2014 {
  background-color: #ce3722;
  background-color: #3d85ad;
  width: 100%;
  float: left;
  position: relative;
  top: 0; }

/****************FOOTER********************/
#bbb {
  width: 32px;
  height: 60px;
  float: left; }

#bbb img {
  border: none; }

#bbb a:link {
  line-height: 58px; }

.f_guarantee_img {
  float: left; }

/**************GIFT CERT pages styles**************/
#giftdiv {
  display: none; }

#egiftdiv {
  display: block; }

/* IC Templates */
/* common */
/*Alternate buttons and overrides and FORM form stuff*/
.btn-grn {
  color: #ffffff;
  font-weight: 600;
  background-color: #52732d;
  background-image: linear-gradient(to bottom, #6d9213, #52732d); }

.btn-grn:hover,
.btn-grn:focus,
.btn-grn:active,
.btn-grn.active,
.open .dropdown-toggle.btn-grn {
  color: #ffffff;
  background-color: #52732d;
  background-image: none; }

a.btn-grn:hover, a.btn-grn:active, a.btn-grn.active {
  background-color: #52732d;
  background-image: none; }

.btn-grn.disabled,
.btn-grn[disabled],
fieldset[disabled] .btn-grn,
.btn-grn.disabled:hover,
.btn-grn[disabled]:hover,
fieldset[disabled] .btn-grn:hover,
.btn-grn.disabled:focus,
.btn-grn[disabled]:focus,
fieldset[disabled] .btn-grn:focus,
.btn-grn.disabled:active,
.btn-grn[disabled]:active,
fieldset[disabled] .btn-grn:active,
.btn-grn.disabled.active,
.btn-grn[disabled].active,
fieldset[disabled] .btn-grn.active {
  background-color: #638638;
  border-color: #587930; }

.btn-red,
a.btn-red {
  color: #ffffff;
  background-color: #aa2b17;
  background-image: to bottom, #cd3722, #aa2b17; }
  .btn-red:hover, .btn-red:focus, .btn-red:active, .btn-red.active,
  .btn-red.open .dropdown-toggle.btn-red,
  a.btn-red:hover,
  a.btn-red:focus,
  a.btn-red:active,
  a.btn-red.active,
  a.btn-red.open .dropdown-toggle.btn-red {
    color: #ffffff;
    background-color: #aa2b17;
    background-image: none; }

.btn-grey {
  color: #ffffff;
  background-color: #757575; }
  .btn-grey:hover, .btn-grey:focus, .btn-grey:active, .btn-grey.active,
  .btn-grey.open .dropdown-toggle.btn-grey {
    color: #ffffff;
    background-color: #757575;
    background-image: none; }

/*includes*/
/*fp_solutions*/
/************** fp_category **********/
.fp_cat_row {
  margin-bottom: 30px;
  margin-left: -30px; }
  .fp_cat_row::before {
    display: inherit;
    content: unset; }

.fp_sol_row {
  margin-bottom: 30px;
  line-height: 10px; }

.fp_free_cat {
  margin-top: 30px; }

.gift-cert-amount .form-control-feedback-l {
  left: 12px; }

.gift-cert-amount .form-control.gc-amount-input {
  padding-right: 1.83em; }

@media screen and (max-width: 991px) {
  .lost_email {
    margin-bottom: 1em; } }

/*both search and results*/
#sr_right_sort {
  margin-bottom: 10px; }

.landing_d {
  margin-bottom: -10px; }

.landing_d hr {
  margin-top: 30px; }

#sr_left {
  background-color: #f1f3f5; }

.sr_left_sgroup {
  float: left;
  width: 100%;
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 10px; }

.sr_refine_row {
  margin-bottom: 20px; }

.sr_row {
  margin-bottom: 20px; }

.sr_results_found {
  padding-top: 8px; }

.sr_more_list_loc {
  height: 20px; }

.sr_more_list_loc > strong {
  text-decoration: underline;
  margin-left: 4px; }

a.sr_more_list:link {
  margin-left: 4px; }

a.sr_more_list:visited {
  margin-left: 4px; }

a.sr_more_list:hover {
  margin-left: 4px; }

a.sr_more_list:active {
  margin-left: 4px; }

.item_image {
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 10px;
  /*  background-color:#666;*/
  z-index: 20; }

a.sr_img_lnk:link {
  z-index: 100;
  display: block; }

.sr_image {
  position: relative;
  margin-bottom: 0px;
  margin-top: 10px;
  top: 0px;
  left: 0px;
  width: 168px;
  height: 224px;
  /*  background-color:#ccc;*/ }

.corner_holder {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 60px;
  width: 50px;
  z-index: 30; }

.sr_corner_new {
  background-image: url("/images/2014/Overlay_New.png");
  background-repeat: no-repeat;
  background-position: top left;
  float: left;
  height: 50px;
  width: 22px;
  top: 0px;
  left: 0px;
  z-index: 21; }

.sr_corner_disc {
  background-image: url("/images/2014/Overlay_Sale.png");
  background-repeat: no-repeat;
  background-position: top left;
  float: left;
  height: 50px;
  width: 22px;
  top: 0px;
  left: 0px;
  z-index: 21; }

.sr_spacer {
  float: left;
  height: 50px;
  width: 100%; }

/**************** results page RESULTS ********************/
.reshow {
  color: #347CA3;
  font-weight: bold;
  cursor: pointer;
  padding-left: 5px;
  display: none;
  float: right; }

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-300-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-300-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-300-subset.ttf") format("truetype");
  font-display: swap; }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-regular-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-regular-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-regular-subset.ttf") format("truetype");
  font-display: swap; }

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-600-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-600-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-600-subset.ttf") format("truetype");
  font-display: swap; }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-700-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-700-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-700-subset.ttf") format("truetype");
  font-display: swap; }

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-800-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-800-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-800-subset.ttf") format("truetype");
  font-display: swap; }

/* merriweather-300 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/merriweather-v19-latin/merriweather-v19-latin-300-subset.woff2") format("woff2"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-300-subset.zopfli.woff") format("woff"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-300-subset.ttf") format("truetype");
  font-display: swap; }

/* merriweather-regular - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/merriweather-v19-latin/merriweather-v19-latin-regular-subset.woff2") format("woff2"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-regular-subset.zopfli.woff") format("woff"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-regular-subset.ttf") format("truetype");
  font-display: swap; }

/* merriweather-italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/merriweather-v19-latin/merriweather-v19-latin-italic-subset.woff2") format("woff2"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-italic-subset.zopfli.woff") format("woff"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-italic-subset.ttf") format("truetype");
  font-display: swap; }

/* merriweather-700 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/merriweather-v19-latin/merriweather-v19-latin-700-subset.woff2") format("woff2"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-700-subset.zopfli.woff") format("woff"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-700-subset.ttf") format("truetype");
  font-display: swap; }

/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2022 Schwartzco Inc.
        License: 2208-FHHPES     
*/
@font-face {
  font-family: 'Lyon Text Web';
  src: url("/fonts/Lyon-Commercial-Type/LyonText-BlackItalic-Web.woff2") format("woff2"), url("/fonts/Lyon-Commercial-Type/LyonText-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal; }

.LyonText-BlackItalic-Web {
  font-family: 'Lyon Text Web';
  font-weight: 900;
  font-style: italic;
  font-stretch: normal; }

@font-face {
  font-family: 'Lyon Text Web';
  src: url("/fonts/Lyon-Commercial-Type/LyonText-Black-Web.woff2") format("woff2"), url("/fonts/Lyon-Commercial-Type/LyonText-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap; }

.LyonText-Black-Web {
  font-family: 'Lyon Text Web';
  font-weight: 900;
  font-style: normal;
  font-stretch: normal; }

/* Google Lato - 400 - regular */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato-Google-Fonts/Lato-Regular.ttf") format("truetype");
  font-display: swap; }

/* Google Lato - 400 - regular italic */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/Lato-Google-Fonts/Lato-Italic.ttf") format("truetype");
  font-display: swap; }

/* Google Lato - 700 - bold */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato-Google-Fonts/Lato-Bold.ttf") format("truetype");
  font-display: swap; }

/* Google Lato - 900 - black */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato-Google-Fonts/Lato-Black.ttf") format("truetype");
  font-display: swap; }

a {
  color: #356c25;
  text-decoration: none; }

h1.headline-secondary {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  color: #042003; }
  @media screen and (max-width: 767px) {
    h1.headline-secondary {
      font-size: 34px;
      line-height: 42px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    h1.headline-secondary {
      font-size: 48px;
      line-height: 56px; } }
  @media (min-width: 992px) {
    h1.headline-secondary {
      font-size: 54px;
      line-height: 60px; } }

h1, .headline-large {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  color: #042003;
  font-weight: 900; }
  @media screen and (max-width: 767px) {
    h1, .headline-large {
      font-size: 30px;
      line-height: 42px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    h1, .headline-large {
      font-size: 40px;
      line-height: 56px; } }
  @media (min-width: 992px) {
    h1, .headline-large {
      font-size: 54px;
      line-height: 60px; } }

h2, .headline-medium, .h2 {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px) {
    h2, .headline-medium, .h2 {
      font-size: 30px;
      line-height: calc(38 / 30); } }
  @media (min-width: 768px) and (max-width: 991px) {
    h2, .headline-medium, .h2 {
      font-size: 40px;
      line-height: calc(50 / 40); } }
  @media (min-width: 992px) {
    h2, .headline-medium, .h2 {
      font-size: 44px;
      line-height: calc(54 / 44); } }

.headline-medium-modified, .h2-modified {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .headline-medium-modified, .h2-modified {
      font-size: 24px;
      line-height: 32px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .headline-medium-modified, .h2-modified {
      font-size: 30px;
      line-height: 44px; } }
  @media (min-width: 992px) {
    .headline-medium-modified, .h2-modified {
      font-size: 30px;
      line-height: 44px; } }

h3, .headline-small, .h3 {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    h3, .headline-small, .h3 {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    h3, .headline-small, .h3 {
      font-size: 22px;
      line-height: 30px; } }

h4, .headline-tiny, .h4 {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    h4, .headline-tiny, .h4 {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    h4, .headline-tiny, .h4 {
      font-size: 20px; } }

h5, .headline-h5, .gift-info-detail {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    h5, .headline-h5, .gift-info-detail {
      font-size: 16px; } }
  @media screen and (min-width: 992px) {
    h5, .headline-h5, .gift-info-detail {
      font-size: 18px; } }

.body-txt-link {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 900;
  color: #356c25;
  text-transform: uppercase;
  letter-spacing: 1px; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .body-txt-link {
      font-size: 15px; } }
  @media screen and (min-width: 992px) {
    .body-txt-link {
      font-size: 16px; } }
  .body-txt-link:hover {
    color: #356c25; }

.body-txt-link.normal {
  letter-spacing: normal;
  text-transform: none; }

.normal {
  letter-spacing: normal;
  text-transform: none; }

.body-txt-link.bold, .faq_list li a.body-txt-link, .faq_list_a li a.body-txt-link, .body-txt-medium a.body-txt-link, .community-article p a.body-txt-link, .bs-faq ul > li a.body-txt-link {
  font-weight: 900; }

.body-txt-medium, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b, .faq_list_a li p, .community-article p, .bs-faq ul > li {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .body-txt-medium, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b, .faq_list_a li p, .community-article p, .bs-faq ul > li {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt-medium, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b, .faq_list_a li p, .community-article p, .bs-faq ul > li {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt-medium, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b, .faq_list_a li p, .community-article p, .bs-faq ul > li {
      font-size: 22px;
      line-height: 34px; } }

.body-txt-medium.bold, .faq_list li.bold, .faq_list li a, .faq_list_a li a, .faq_list_a li b.bold, .faq_list_a li p.bold, .community-article p.bold, .body-txt-medium.secondary-header--description .faq_list li a, .faq_list li .body-txt-medium.secondary-header--description a, .faq_list li.secondary-header--description a, .faq_list li a.secondary-header--description a, .community-article p.secondary-header--description .faq_list li a, .faq_list li .community-article p.secondary-header--description a, .faq_list_a li a.secondary-header--description a, .faq_list_a li b.secondary-header--description a, .faq_list_a li p.secondary-header--description a, .body-txt-medium a.body-txt-medium, .community-article p a.body-txt-medium, .bs-faq ul > li a.body-txt-medium, .bs-faq ul > li.bold {
  font-weight: bold; }

.body-txt-medium.link, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b.link, .faq_list_a li p.link, .community-article p.link, .body-txt-medium.secondary-header--description .faq_list li a, .faq_list li .body-txt-medium.secondary-header--description a, .faq_list li.secondary-header--description a, .faq_list li a.secondary-header--description a, .community-article p.secondary-header--description .faq_list li a, .faq_list li .community-article p.secondary-header--description a, .faq_list_a li a.secondary-header--description a, .faq_list_a li b.secondary-header--description a, .faq_list_a li p.secondary-header--description a, .body-txt-medium a.body-txt-medium, .community-article p a.body-txt-medium, .bs-faq ul > li a.body-txt-medium, .bs-faq ul > li.link, .bs-faq .faq_list ul > li, .faq_list .bs-faq ul > li {
  color: #356c25; }

.body-txt-small, ul.vertical-menu > li, .gift-info-detail {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .body-txt-small, ul.vertical-menu > li, .gift-info-detail {
      font-size: 18px;
      line-height: 26px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt-small, ul.vertical-menu > li, .gift-info-detail {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 992px) {
    .body-txt-small, ul.vertical-menu > li, .gift-info-detail {
      font-size: 20px;
      line-height: 28px; } }

.body-txt-input-small {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .body-txt-input-small {
      font-size: 18px;
      line-height: calc(26 / 18); } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt-input-small {
      font-size: 20px;
      line-height: calc(28 / 20); } }
  @media (min-width: 992px) {
    .body-txt-input-small {
      font-size: 20px;
      line-height: 1; } }

.body-txt--price {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .body-txt--price {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt--price {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt--price {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .body-txt--price {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    .body-txt--price {
      font-size: 20px; } }

.price--large {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .price--large {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .price--large {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .price--large {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .price--large {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    .price--large {
      font-size: 20px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .price--large {
      font-size: 24px; } }
  @media screen and (min-width: 992px) {
    .price--large {
      font-size: 28px; } }
  .price--large .sale {
    color: #da4e2b; }

.body-txt--saleprice {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #042003;
  color: #da4e2b; }
  @media screen and (max-width: 767px) {
    .body-txt--saleprice {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt--saleprice {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt--saleprice {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .body-txt--saleprice {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    .body-txt--saleprice {
      font-size: 20px; } }

.body-txt--oldprice {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #757575;
  text-decoration: line-through; }
  @media screen and (max-width: 767px) {
    .body-txt--oldprice {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt--oldprice {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt--oldprice {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .body-txt--oldprice {
      font-size: 16px; } }
  @media screen and (min-width: 992px) {
    .body-txt--oldprice {
      font-size: 18px; } }

.body-txt--copyright {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  height: 35px;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .body-txt--copyright {
      font-size: 15px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt--copyright {
      font-size: 16px; } }
  @media (min-width: 992px) {
    .body-txt--copyright {
      font-size: 16px; } }

.success-txt {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #356c25; }
  @media screen and (max-width: 767px) {
    .success-txt {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .success-txt {
      font-size: 22px;
      line-height: 32px; } }
  @media (min-width: 992px) {
    .success-txt {
      font-size: 22px;
      line-height: 32px; } }

.error-txt, .co-invalid-group p.co-invalid.error,
.invalid-group p.error {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #da4e2b; }
  @media screen and (max-width: 767px) {
    .error-txt, .co-invalid-group p.co-invalid.error,
    .invalid-group p.error {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .error-txt, .co-invalid-group p.co-invalid.error,
    .invalid-group p.error {
      font-size: 22px;
      line-height: 32px; } }
  @media (min-width: 992px) {
    .error-txt, .co-invalid-group p.co-invalid.error,
    .invalid-group p.error {
      font-size: 22px;
      line-height: 32px; } }

.msg-txt {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .msg-txt {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .msg-txt {
      font-size: 22px;
      line-height: 32px; } }
  @media (min-width: 992px) {
    .msg-txt {
      font-size: 22px;
      line-height: 32px; } }

.BodSmall, .wl-action, .muted, .mb--catalog .mb-body,
.mb--pots .mb-body, .quick-links > li > a, .footer-colophon, .product-tile-availability {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 20px;
  line-height: calc(2/1.3); }

.body-txt-large, .cta-promo-body,
.callout,
.intro, .pd-desc, .cart-help, .gallery p {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .body-txt-large, .cta-promo-body,
    .callout,
    .intro, .pd-desc, .cart-help, .gallery p {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt-large, .cta-promo-body,
    .callout,
    .intro, .pd-desc, .cart-help, .gallery p {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt-large, .cta-promo-body,
    .callout,
    .intro, .pd-desc, .cart-help, .gallery p {
      font-size: 22px;
      line-height: 34px; } }

.cart-alert {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  line-height: 34px !important; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .cart-alert {
      font-size: 16px !important; } }
  @media screen and (min-width: 992px) {
    .cart-alert {
      font-size: 20px !important; } }
  .cart-alert--success {
    background: #eef7eb; }
  .cart-alert--error {
    background: #FEF3F0;
    color: #da4e2b; }

.cta-promo-header,
.cs_title, .product-tile-head, .cart-heading > h1,
.cart-heading > h2 {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  color: #042003;
  font-weight: 900; }
  @media screen and (max-width: 767px) {
    .cta-promo-header,
    .cs_title, .product-tile-head, .cart-heading > h1,
    .cart-heading > h2 {
      font-size: 30px;
      line-height: 42px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .cta-promo-header,
    .cs_title, .product-tile-head, .cart-heading > h1,
    .cart-heading > h2 {
      font-size: 40px;
      line-height: 56px; } }
  @media (min-width: 992px) {
    .cta-promo-header,
    .cs_title, .product-tile-head, .cart-heading > h1,
    .cart-heading > h2 {
      font-size: 54px;
      line-height: 60px; } }

.hp-tile-title--single, .hp-tile-subhead {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 2.0rem;
  line-height: 26px;
  line-height: calc(2.6/2);
  color: #fff; }

.mb--catalog .mb-head,
.mb--pots .mb-head, .acct-signin label, .category-promo-fig, .footer-toll-free, .nav-tabs > li > a.pd-meta-nav-link, .pd-specs-head, .product-tile-link, .notice__heading, .gallery-tile-link {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .mb--catalog .mb-head,
    .mb--pots .mb-head, .acct-signin label, .category-promo-fig, .footer-toll-free, .nav-tabs > li > a.pd-meta-nav-link, .pd-specs-head, .product-tile-link, .notice__heading, .gallery-tile-link {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    .mb--catalog .mb-head,
    .mb--pots .mb-head, .acct-signin label, .category-promo-fig, .footer-toll-free, .nav-tabs > li > a.pd-meta-nav-link, .pd-specs-head, .product-tile-link, .notice__heading, .gallery-tile-link {
      font-size: 22px;
      line-height: 30px; } }

.sidebar-heading, .pd-qty-price, .pd-bulk-discount-price, .product-tile-price, .garden-solutions .toggle-group-heading {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 22px;
  line-height: calc(2.2/1.5); }

.footer-signup-msg a, .footer-toll-free, .footer-toll-free .footer-tf-number a, .footer-toll-free .footer-mailto a {
  color: #fff; }

.pd-head-species {
  font-family: "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-style: italic; }

.footer-toll-free .footer-tf-number a, .footer-toll-free .footer-mailto a, .footer-mailto, .footer-colophon a {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

.bold, .faq_list li a, .faq_list_a li a, .body-txt-medium.secondary-header--description a, .faq_list li.secondary-header--description a, .faq_list li a.secondary-header--description a, .faq_list_a li a.secondary-header--description a, .faq_list_a li b.secondary-header--description a, .faq_list_a li p.secondary-header--description a, .community-article p.secondary-header--description a, .bs-faq ul > li.secondary-header--description a, .body-txt-medium a, .community-article p a, .bs-faq ul > li a {
  font-weight: bold; }

.font-weight-normal {
  font-weight: normal; }

.link, .faq_list li, .faq_list li a, .faq_list_a li a, .body-txt-medium.secondary-header--description a, .faq_list li.secondary-header--description a, .faq_list li a.secondary-header--description a, .faq_list_a li a.secondary-header--description a, .faq_list_a li b.secondary-header--description a, .faq_list_a li p.secondary-header--description a, .community-article p.secondary-header--description a, .bs-faq ul > li.secondary-header--description a, .body-txt-medium a, .community-article p a, .bs-faq ul > li a {
  color: #356c25; }

.disabled-section {
  opacity: 0.5;
  cursor: auto !important; }

.text-uppercase {
  text-transform: uppercase; }

.text-normal-weight {
  font-weight: 400; }

.text-weight-700 {
  font-weight: 700; }

.text-style-normal {
  font-style: normal; }

.muted {
  padding: 0 0.2em; }

.d-flex {
  display: flex; }

.skip-links {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  z-index: 10; }
  .skip-links__link {
    height: 0;
    width: 0;
    overflow: hidden; }
  .skip-links__link:focus {
    height: auto;
    width: auto;
    overflow: auto;
    left: 0;
    color: white;
    background-color: #356c25;
    padding: 12px;
    border: 2px solid black;
    border-radius: 5px;
    z-index: 999; }

.sidebar-bg {
  background: #f5f8fb;
  padding: 15px; }
  .sidebar-bg.sidebar-bg--left {
    padding: 0 5px; }
  .sidebar-bg.sidebar-bg--centered {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .sidebar-bg a {
    color: #333;
    font-weight: 400; }
    .sidebar-bg a.btn-grn {
      color: #fff;
      font-weight: 600; }

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

*[hide] {
  display: none; }

p.mb0,
div.mb0,
section.mb0,
figure.mb0,
img.mb0,
h2.mb0,
h3.mb0,
hr.mb0,
a.mb0,
button.mb0,
table.mb0 {
  margin-bottom: 0em; }

p.mb05,
div.mb05,
section.mb05,
figure.mb05,
img.mb05,
h2.mb05,
h3.mb05,
hr.mb05,
a.mb05,
button.mb05,
table.mb05 {
  margin-bottom: 0.5em; }

p.mb1,
div.mb1,
section.mb1,
figure.mb1,
img.mb1,
h2.mb1,
h3.mb1,
hr.mb1,
a.mb1,
button.mb1,
table.mb1 {
  margin-bottom: 1em; }

p.mb15,
div.mb15,
section.mb15,
figure.mb15,
img.mb15,
h2.mb15,
h3.mb15,
hr.mb15,
a.mb15,
button.mb15,
table.mb15 {
  margin-bottom: 1.5em; }

p.mb2,
div.mb2,
section.mb2,
figure.mb2,
img.mb2,
h2.mb2,
h3.mb2,
hr.mb2,
a.mb2,
button.mb2,
table.mb2 {
  margin-bottom: 2em; }

p.mt05,
div.mt05,
section.mt05,
figure.mt05,
img.mt05,
h2.mt05,
h3.mt05,
hr.mt05,
a.mt05,
button.mt05,
table.mt05 {
  margin-top: 0.5em; }

p.mt1,
div.mt1,
section.mt1,
figure.mt1,
img.mt1,
h2.mt1,
h3.mt1,
hr.mt1,
a.mt1,
button.mt1,
table.mt1 {
  margin-top: 1em; }

p.mt15,
div.mt15,
section.mt15,
figure.mt15,
img.mt15,
h2.mt15,
h3.mt15,
hr.mt15,
a.mt15,
button.mt15,
table.mt15 {
  margin-top: 1.5em; }

p.mt2,
div.mt2,
section.mt2,
figure.mt2,
img.mt2,
h2.mt2,
h3.mt2,
hr.mt2,
a.mt2,
button.mt2,
table.mt2 {
  margin-top: 2em; }

p.mt3,
div.mt3,
section.mt3,
figure.mt3,
img.mt3,
h2.mt3,
h3.mt3,
hr.mt3,
a.mt3,
button.mt3,
table.mt3 {
  margin-top: 3em; }

p.pb2,
div.pb2,
section.pb2,
figure.pb2,
img.pb2,
h2.pb2,
h3.pb2,
hr.pb2,
a.pb2,
button.pb2,
table.pb2 {
  padding-bottom: 2em; }

p.mb4,
div.mb4,
section.mb4,
figure.mb4,
img.mb4,
h2.mb4,
h3.mb4,
hr.mb4,
a.mb4,
button.mb4,
table.mb4 {
  margin-bottom: 4em; }

p.mt4,
div.mt4,
section.mt4,
figure.mt4,
img.mt4,
h2.mt4,
h3.mt4,
hr.mt4,
a.mt4,
button.mt4,
table.mt4 {
  margin-top: 4em; }

p.pb4,
div.pb4,
section.pb4,
figure.pb4,
img.pb4,
h2.pb4,
h3.pb4,
hr.pb4,
a.pb4,
button.pb4,
table.pb4 {
  padding-bottom: 2em; }

@media screen and (max-width: 767px) {
  input[type="text"],
  input[type="email"],
  input[type="search"],
  select,
  select.form-control,
  textarea,
  textarea.form-control {
    font-size: 1.6rem; } }

.bs-btn, .btn-link {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  font-weight: 900;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  border-radius: 4px;
  height: 55px;
  background-color: #356c25;
  min-width: 180px;
  border: none;
  padding: 0 20px; }
  @media screen and (max-width: 315px) {
    .bs-btn, .btn-link {
      min-width: 150px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .bs-btn, .btn-link {
      font-size: 15px;
      height: 50px; } }
  @media screen and (min-width: 992px) {
    .bs-btn, .btn-link {
      font-size: 16px; } }
  .bs-btn:hover, .btn-link:hover, .bs-btn:active, .btn-link:active, .bs-btn:focus, .btn-link:focus {
    background: #255518;
    color: #fff;
    text-decoration: none; }

.bs-btn--secondary {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  font-weight: 900;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  border-radius: 4px;
  height: 55px;
  background-color: #356c25;
  min-width: 180px;
  border: none;
  padding: 0 20px;
  height: 50px;
  background-color: #fff;
  border: 3px solid #356c25;
  color: #356c25; }
  @media screen and (max-width: 315px) {
    .bs-btn--secondary {
      min-width: 150px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .bs-btn--secondary {
      font-size: 15px;
      height: 50px; } }
  @media screen and (min-width: 992px) {
    .bs-btn--secondary {
      font-size: 16px; } }
  .bs-btn--secondary:hover, .bs-btn--secondary:active, .bs-btn--secondary:focus {
    background: #255518;
    color: #fff;
    text-decoration: none; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .bs-btn--secondary {
      height: 45px; } }
  .bs-btn--secondary:hover, .bs-btn--secondary:active, .bs-btn--secondary:focus {
    border: 3px solid #153A0A !important;
    color: #153A0A;
    background-color: #fff; }

.bs-btn--secondary.reversed {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  font-weight: 900;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  border-radius: 4px;
  height: 55px;
  background-color: #356c25;
  min-width: 180px;
  border: none;
  padding: 0 20px;
  height: 50px;
  background-color: #fff;
  border: 3px solid #356c25;
  color: #356c25;
  background-color: transparent;
  border: 3px solid #fff;
  color: #fff; }
  @media screen and (max-width: 315px) {
    .bs-btn--secondary.reversed {
      min-width: 150px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .bs-btn--secondary.reversed {
      font-size: 15px;
      height: 50px; } }
  @media screen and (min-width: 992px) {
    .bs-btn--secondary.reversed {
      font-size: 16px; } }
  .bs-btn--secondary.reversed:hover, .bs-btn--secondary.reversed:active, .bs-btn--secondary.reversed:focus {
    background: #255518;
    color: #fff;
    text-decoration: none; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .bs-btn--secondary.reversed {
      height: 45px; } }
  .bs-btn--secondary.reversed:hover, .bs-btn--secondary.reversed:active, .bs-btn--secondary.reversed:focus {
    border: 3px solid #153A0A !important;
    color: #153A0A;
    background-color: #fff; }
  .bs-btn--secondary.reversed:hover, .bs-btn--secondary.reversed:active, .bs-btn--secondary.reversed:focus {
    border-color: #fff;
    color: #fff;
    background-color: transparent; }

.bs-btn--caption {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  font-weight: 900;
  border-radius: 4px;
  height: 32px;
  min-width: 90px;
  background-color: rgba(0, 0, 0, 0.6); }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .bs-btn--caption {
      font-size: 15px; } }
  @media (min-width: 992px) {
    .bs-btn--caption {
      font-size: 16px; } }

.bs-btn--alert {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  font-weight: bold;
  height: 55px;
  background-color: #da4e2b; }
  @media screen and (max-width: 767px) {
    .bs-btn--alert {
      font-size: 15px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .bs-btn--alert {
      font-size: 16px; } }
  @media (min-width: 992px) {
    .bs-btn--alert {
      font-size: 18px; } }

.bs-btn--copyright {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  height: 35px;
  background-color: #da4e2b; }
  @media screen and (max-width: 767px) {
    .bs-btn--copyright {
      font-size: 15px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .bs-btn--copyright {
      font-size: 16px; } }
  @media (min-width: 992px) {
    .bs-btn--copyright {
      font-size: 16px; } }

.bs-btn--red {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  font-weight: 900;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  border-radius: 4px;
  height: 55px;
  background-color: #356c25;
  min-width: 180px;
  border: none;
  padding: 0 20px;
  background-color: #da4e2b; }
  @media screen and (max-width: 315px) {
    .bs-btn--red {
      min-width: 150px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .bs-btn--red {
      font-size: 15px;
      height: 50px; } }
  @media screen and (min-width: 992px) {
    .bs-btn--red {
      font-size: 16px; } }
  .bs-btn--red:hover, .bs-btn--red:active, .bs-btn--red:focus {
    background: #255518;
    color: #fff;
    text-decoration: none; }
  .bs-btn--red:hover, .bs-btn--red:active, .bs-btn--red:focus {
    background: #be3917;
    color: #fff;
    text-decoration: none; }

.rsp-btn,
a.rsp-btn {
  color: #fff;
  background-color: #58753c;
  border-radius: 3px;
  border: 1px solid transparent;
  font-weight: 600;
  line-height: 24px;
  line-height: calc(2.4/1.4);
  padding-top: 0.498em;
  padding-bottom: 0.503em;
  padding: 0.498em 0.9em 0.503em 0.9em;
  text-transform: uppercase;
  white-space: nowrap;
  min-width: 7.5em;
  height: 40px; }
  .rsp-btn:hover, .rsp-btn:active, .rsp-btn:focus,
  a.rsp-btn:hover,
  a.rsp-btn:active,
  a.rsp-btn:focus {
    background: #516f30;
    color: #fff;
    font-weight: 600;
    text-decoration: none; }

.rsp-btn-inner {
  margin: 0 auto; }

a.rsp-btn {
  display: inline-block; }

a.rsp-btn--secondary,
.rsp-btn--secondary {
  background: transparent;
  border: 3px solid #fff;
  border-radius: 0;
  padding: 0.365em 0 0.355em;
  letter-spacing: 0.05em;
  text-align: center; }
  a.rsp-btn--secondary:hover, a.rsp-btn--secondary:active, a.rsp-btn--secondary:focus,
  .rsp-btn--secondary:hover,
  .rsp-btn--secondary:active,
  .rsp-btn--secondary:focus {
    background: inherit; }
  a.rsp-btn--secondary.rsp-btn--grey,
  .rsp-btn--secondary.rsp-btn--grey {
    color: #777;
    border-color: #777;
    background: #fff;
    font-weight: bold; }

.rsp-btn--red,
a.rsp-btn--red {
  background-image: linear-gradient(to bottom, #cc3921, #a62c15); }
  .rsp-btn--red:hover, .rsp-btn--red:active, .rsp-btn--red:focus,
  a.rsp-btn--red:hover,
  a.rsp-btn--red:active,
  a.rsp-btn--red:focus {
    background: #a62c15; }

.rsp-btn--cart {
  padding-left: 2em;
  padding-right: 2em; }
  .rsp-btn--cart .m-cart-icon {
    fill: #fff;
    width: 1.5em;
    height: 1.5em;
    float: left; }
  .rsp-btn--cart .rsp-btn-txt {
    padding-left: 0.4em; }

.mobile-menu-icon {
  display: inline-block;
  width: 30px !important;
  height: 30px !important; }

.rsp-btn--link,
a.rsp-btn--link {
  color: #3a7c9c;
  background-color: white;
  background-image: none; }
  .rsp-btn--link:hover, .rsp-btn--link:active, .rsp-btn--link:focus,
  a.rsp-btn--link:hover,
  a.rsp-btn--link:active,
  a.rsp-btn--link:focus {
    color: #3a7c9c;
    background: white;
    text-decoration: underline; }

.btn-link--lbg {
  color: #2f647e; }

.cta {
  margin: 100px 0 45px 0; }
  @media screen and (max-width: 991px) {
    .cta {
      margin: 50px 0 25px 0; } }
  .cta.cta--landing {
    margin-top: 0; }

.container--main {
  margin-top: 0; }

.mb-none, .pc-deet, .item-details .item-sku,
.item-details .item-avail,
.item-details .item-low-inventory, .sidebar-heading, .pd-stock-info, .pd-guarantee, .product-tile-link, .product-tile-availability, .item-price, .gift-info-detail {
  margin-bottom: 0; }

.toggle-group {
  width: 100%;
  float: left;
  border-top: 1px solid #ddd; }
  .toggle-group:last-child {
    border-bottom: 1px solid #ddd; }
  .toggle-group ul {
    margin-bottom: 0; }
  @media screen and (min-width: 993px) {
    .toggle-group {
      border: none 0;
      width: auto;
      float: none; }
      .toggle-group:last-child {
        border: none 0; } }

.toggle-group-body {
  display: none;
  float: left;
  width: 100%;
  padding-bottom: 1em; }
  @media screen and (min-width: 993px) {
    .toggle-group-body {
      display: block; } }

.toggle-group-toggle {
  z-index: 1;
  position: absolute;
  opacity: 0; }
  .toggle-group-toggle:checked ~ .toggle-group-body {
    display: block; }
  .toggle-group-toggle:checked ~ .toggle-group-heading:after {
    content: '\2013';
    right: 16px; }

.toggle-group-heading {
  display: block;
  padding: 1em 15px 0.8em 15px;
  font-size: 1em; }
  .toggle-group-heading:after {
    content: '+';
    font-size: 2em;
    position: absolute;
    top: 1px;
    right: 15px; }
  @media screen and (min-width: 993px) {
    .toggle-group-heading:after {
      display: none; } }

.toggle-group-link {
  display: block; }
  @media screen and (max-width: 992px) {
    .toggle-group-link {
      padding: 0.3em 0; } }

.mb {
  display: block;
  display: flex;
  background: #f5f8fb;
  padding: 10px;
  margin: 1em 0; }

.mb-fig {
  width: 40%; }

.mb-img {
  max-width: 100%;
  padding-right: 0.25em; }

.mb-txt {
  width: 60%;
  padding-left: 0.25em; }

.mb-head {
  margin-bottom: 0; }

@media screen and (min-width: 599px) and (max-width: 991px) {
  .mb--pots {
    margin-top: calc(44px + 10px - 1em); } }

.mb--catalog .mb-head,
.mb--pots .mb-head {
  color: #3a7c9c; }
  @media screen and (max-width: 325px) {
    .mb--catalog .mb-head,
    .mb--pots .mb-head {
      font-size: 1.2em; } }

.mb--catalog:hover, .mb--catalog:active, .mb--catalog:focus,
.mb--pots:hover,
.mb--pots:active,
.mb--pots:focus {
  text-decoration: none; }

.mb--catalog .mb-body,
.mb--pots .mb-body {
  color: #333; }

@media screen and (min-width: 599px) {
  .mb--catalog .mb-fig,
  .mb--pots .mb-fig {
    width: auto; }
  .mb--catalog .mb-img,
  .mb--pots .mb-img {
    margin-top: -44px;
    margin-bottom: -10px;
    max-width: 176px; } }

.mb-promos {
  margin-bottom: 2em; }

.sharethis-buttons {
  margin: 1em 0; }

.pop-cart-container {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 15px; }

.pop-cart {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  background: #fff;
  padding: 35px 35px 50px;
  border: 10px solid #e1f7f7; }
  @media screen and (min-width: 500px) {
    .pop-cart {
      margin: 0 0 0 auto; } }

.pc-close-btn {
  position: absolute;
  right: 10px;
  top: 10px; }
  .pc-close-btn .icon--close {
    width: 20px;
    height: 20px;
    color: black; }

.pc-added,
.item-avail,
.pc-error {
  font-weight: 600; }

.pc-added {
  color: #356c25; }

.pc-error {
  color: #cc3921;
  margin-bottom: 1.5em; }

.cart-item {
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-bottom: 1.5em;
  padding-bottom: 1em; }
  .wishlist-body .cart-item {
    border-bottom: none; }

.pop-cart .cart-item {
  padding-bottom: 30px; }

.pc-multi-item-table {
  display: block;
  padding-bottom: 1.5em;
  margin-bottom: 0.5em;
  border-bottom: 2px solid #ddd; }

.pc-multi-item-link {
  display: block;
  margin-right: 0.5em;
  font-size: 1.1em; }

.pc-cross-mark {
  font-size: 1.2em; }

.item-subtotal-amount {
  float: right;
  font-weight: 900; }

.item-subtotal-label--multi {
  display: inline-block;
  margin-right: 0.5em; }

h4.pc-deet {
  color: #042003 !important; }

.item-sku,
.item-qty,
.pc-meta-cart-link {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .item-sku,
    .item-qty,
    .pc-meta-cart-link {
      font-size: 18px;
      line-height: 26px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .item-sku,
    .item-qty,
    .pc-meta-cart-link {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 992px) {
    .item-sku,
    .item-qty,
    .pc-meta-cart-link {
      font-size: 20px;
      line-height: 28px; } }

.item-qty-label {
  margin-right: 0.4em;
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .item-qty-label {
      font-size: 18px;
      line-height: 26px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .item-qty-label {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 992px) {
    .item-qty-label {
      font-size: 20px;
      line-height: 28px; } }

.item-free {
  color: #cc3921; }

.item-name,
.item-link,
.h3,
.dlist dt,
a.h3 {
  margin-top: 0;
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003;
  margin-bottom: 0; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .item-name,
    .item-link,
    .h3,
    .dlist dt,
    a.h3 {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    .item-name,
    .item-link,
    .h3,
    .dlist dt,
    a.h3 {
      font-size: 22px;
      line-height: 30px; } }

.item-fig {
  margin-right: 15px; }

.item-thumb-link {
  display: block;
  width: 100%;
  text-align: center; }

.pc-meta {
  margin-bottom: 1.5em; }

.pc-subtotal-label {
  padding-right: 0.5em; }

.pc-subtotal-amount {
  font-weight: 700; }

.trustpilot-widget {
  margin-bottom: -2.5em; }

.sidebar-heading {
  color: #333;
  margin-top: 0.5em; }

.sidebar-copy {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .sidebar-copy {
      font-size: 18px;
      line-height: 26px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .sidebar-copy {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 992px) {
    .sidebar-copy {
      font-size: 20px;
      line-height: 28px; } }

.low-inventory {
  font-weight: 700;
  color: #da4e2b; }

.msg a {
  text-decoration: underline; }

.msg.msg--success {
  color: #516f30; }

.msg.msg--error {
  color: #cc3921; }

.darkLink {
  font-weight: 700;
  color: #3a7c9c; }

.dlist dt {
  margin: 20px 0 0px 0px; }

.dlist dd,
.dlist dd img {
  margin: 15px 0 15px 0px; }

.listlink {
  display: block;
  font-weight: 600;
  line-height: 1.3; }

.product-badge-contain {
  position: absolute;
  top: 15px;
  right: 0px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: end; }

.product-badge-contain--tiles {
  right: 25px; }

.product-tile {
  position: relative; }

[v-cloak] {
  display: none; }

.custom-checkbox-input[type=checkbox],
.custom-radio-input[type=radio] {
  opacity: 0;
  position: absolute;
  z-index: 1;
  cursor: pointer; }

.custom-label {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  cursor: pointer;
  line-height: 1;
  font-weight: 400; }

:focus-visible {
  outline: 0px solid white;
  border: 0px solid white; }

:focus {
  outline: 0px solid white;
  border: 0px solid white; }

.checkbox label.custom-label.custom-label--checkbox {
  padding-left: 0; }

.checkbox label.custom-label.custom-label--checkbox > span {
  align-self: baseline; }

.custom-label:before {
  display: inline-flex;
  content: '';
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #757575;
  align-self: baseline; }

.custom-checkbox-input:checked ~ .custom-label--checkbox:before,
.custom-checkbox-input:checked ~ .custom-label--color-block:before {
  background: #356c25; }

.custom-checkbox-input:checked ~ .custom-label--checkbox:after,
.custom-checkbox-input:checked ~ .custom-label--color-block:after {
  position: absolute;
  content: '';
  cursor: pointer; }

.custom-checkbox-input:checked ~ .custom-label--color-block:after {
  z-index: 1;
  top: 2px;
  left: 2px;
  border: 2px solid #fff;
  width: 16px;
  height: 16px; }

.custom-checkbox-input:checked ~ .custom-label--checkbox:after {
  top: 7px;
  left: 7px;
  width: 6px;
  height: 11px;
  border: 3px solid #fff;
  transform: rotate(45deg);
  border-top: none;
  border-left: none; }
  @media screen and (max-width: 991px) {
    .custom-checkbox-input:checked ~ .custom-label--checkbox:after {
      top: 7px; } }

.custom-label--radio::before {
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.4); }

.custom-radio-input:checked ~ .custom-label--radio:before,
.custom-radio-input:checked ~ label .custom-label--radio:before {
  background-color: #356c25; }

.custom-radio-input:checked ~ .custom-label--radio:after,
.custom-radio-input:checked ~ label .custom-label--radio:after {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 11px;
  left: 7px;
  display: block; }
  @media screen and (max-width: 767px) {
    .custom-radio-input:checked ~ .custom-label--radio:after,
    .custom-radio-input:checked ~ label .custom-label--radio:after {
      top: 9px; } }

.bp-custom-radio-button {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  font-weight: normal;
  margin-bottom: 1.3em; }

.bp-custom-radio-button input[type="radio"] {
  position: absolute;
  width: 22px;
  height: 22px;
  opacity: 0.00001;
  margin-top: 0; }

.bp-custom-radio-button svg {
  width: 22px;
  height: 22px;
  margin-right: 0.5em;
  flex-shrink: 0; }

.bp-custom-radio-button input[type="radio"] + svg .rb-bg {
  fill: #fff;
  stroke: rgba(0, 0, 0, 0.4); }

.bp-custom-radio-button input[type="radio"]:checked + svg .rb-bg {
  fill: #356c25; }

.bp-custom-radio-button input[type="radio"]:focus + svg .rb-bg {
  stroke-width: 10;
  stroke: #FEA435; }

.bp-custom-radio-button input[type="radio"]:checked + svg .rb-dot {
  fill: #fff; }

.fresh-badge {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #516f30;
  text-shadow: 1px 1px 2px rgba(81, 111, 48, 0.75);
  color: #fff;
  padding: 0.25em 5em;
  margin: 0;
  border-radius: 0 5px 0 0; }

.alert.alert-danger a {
  color: #a94442; }

.text-serif {
  font-family: "Merriweather", Georgia, "Times New Roman", Times, serif; }

.taller-input {
  height: 44px;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee; }

.larger-input {
  height: 44px; }

.right-border-md {
  padding-right: 30px; }
  @media screen and (min-width: 992px) {
    .right-border-md {
      border-right: 1px solid #ccc; } }

.black-header {
  color: #333;
  font-weight: bold; }

.line-height-14 {
  line-height: 14px; }

@media screen and (min-width: 992px) {
  .left-border-md {
    padding-left: 30px; } }

@media screen and (max-width: 991px) {
  .left-border-md {
    margin-top: 30px; } }

input.bold-rsp-button,
button.bold-rsp-button,
a.bold-rsp-button {
  padding-left: 2em;
  padding-right: 2em;
  font-weight: bold; }

input.bold-rsp-button:hover, input.bold-rsp-button:active, input.bold-rsp-button:focus,
button.bold-rsp-button:hover,
button.bold-rsp-button:active,
button.bold-rsp-button:focus,
a.bold-rsp-button:hover,
a.bold-rsp-button:active,
a.bold-rsp-button:focus {
  font-weight: bold; }

.max-width-500 {
  max-width: 500px; }

.grid-page-header {
  padding-bottom: 20px;
  margin: 30px 0 15px; }

h1.account-header {
  font-size: 3.5rem; }

.account-header-separator {
  padding: 0 10px; }

.grid-page-header h1 {
  margin-bottom: 0px;
  margin-top: 0px; }

@media screen and (max-width: 767px) {
  .grid-page-header {
    display: flex;
    flex-direction: column;
    row-gap: 10px; } }

@media screen and (min-width: 768px) {
  .grid-page-header {
    display: flex;
    align-items: center; }
    .grid-page-header span.email {
      margin-left: auto; } }

.inline-separator {
  display: inline-block;
  padding: 0 10px; }

ul.vertical-menu {
  list-style-type: none;
  padding-left: 0px; }

ul.vertical-menu > li {
  border-bottom: 1px solid #ccc;
  padding-top: 9px;
  padding-bottom: 9px; }
  ul.vertical-menu > li:last-child {
    border: none 0; }

.vertical-menu-item a {
  color: #333;
  font-weight: normal; }

.vertical-menu-item-active a {
  font-weight: bold; }

.account-form {
  margin-top: 30px; }

.account-form .control-label {
  margin-bottom: 15px; }

.account-form .form-group {
  margin-bottom: 25px;
  max-width: 600px; }

.edisplay,
.wdisplay {
  font-weight: bold;
  padding: 8px 15px;
  margin: 15px 0;
  border: 0 none;
  border-radius: 0; }

.wdisplay {
  background-color: #f4f7f0; }

.wishlist-items .item-actions {
  margin-top: 10px; }

.wishlist-items .cart-action-link {
  font-weight: 600;
  font-size: 14px; }

.wishlist-items .item-avail {
  font-weight: normal; }

.wishlist-items p {
  font-size: 14px; }

.wishlist-items .item-link {
  font-weight: 600;
  font-size: 18px; }

.wishlist-items .item-name {
  margin-bottom: 5px; }

h2.black-header {
  margin-top: 5px;
  font-size: 24px; }

h3.black-header {
  font-size: 20px; }

.address-compact {
  margin-top: 15px; }

.address-compact p {
  margin-bottom: 0; }

.account-address-edit-links {
  font-size: 1.3rem;
  margin-top: 15px; }

.shipping-equal-billing-link:hover,
.shipping-equal-billing-link:focus,
.shipping-equal-billing-link:active,
.shipping-equal-billing-link {
  background-color: #356c25;
  display: inline-flex;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #757575;
  align-self: baseline;
  position: relative; }

.shipping-equal-billing-link + span {
  vertical-align: super; }

.shipping-equal-billing-link::after {
  content: "";
  top: 2px;
  left: 6px;
  width: 6px;
  height: 11px;
  transform: rotate(45deg);
  border: 3px solid #fff;
  border-top: none;
  border-left: none;
  position: absolute; }

body {
  font-feature-settings: "liga", "kern"; }

.drawer-links > .segment, .footer-contact-info .segment {
  white-space: nowrap;
  float: left;
  position: relative;
  display: block;
  margin: 0 calc(0.15em + 1vw); }
  .drawer-links > .segment::before, .footer-contact-info .segment::before {
    content: '\007C';
    display: block;
    position: absolute;
    left: calc(-1 * (0.37em + 1vw));
    font-weight: bolder;
    opacity: 0.5; }

.separator::after {
  content: "\007C";
  font-weight: bolder;
  opacity: .2;
  margin-right: 10px;
  margin-left: 10px; }

.carousel-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  align-items: center; }

/* Mobile View */
@media screen and (max-width: 767px) {
  .carousel-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem; }
  .shop-all.body-txt-link {
    width: auto;
    margin: 0; } }

.shop-all:hover {
  text-decoration: underline;
  color: #255518; }

.carousel-heading > h2 {
  margin: 0; }

.carousel-container {
  display: grid;
  column-gap: 35px; }

/* Tablet View */
@media screen and (max-width: 991px) {
  .carousel-container {
    width: 100vh;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    padding-right: calc(100vh - 100%); }
  .carousel-container.six-columns.categories {
    grid-template-columns: repeat(12, 1fr); } }

.shop-all.body-txt-link {
  display: flex;
  align-items: center; }

.shop-all--arrow {
  width: 20px;
  height: 20px;
  margin-left: 1rem; }

.skeleton {
  margin-top: 20px; }

figure.product-tile-fig {
  transition: all 0.2s ease-in-out; }

figure.product-tile-fig:hover {
  transform: scale(1.05); }

.product-title-wrap {
  scroll-snap-align: start; }

.carousel-root {
  margin-top: 5em; }
  @media screen and (max-width: 991px) {
    .carousel-root {
      margin-top: 2.5em; } }

@media screen and (max-width: 767px) {
  .search-landing .carousel-img-responsive,
  .carousel-img-responsive.six-columns {
    max-width: 128px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .search-landing .carousel-img-responsive,
  .carousel-img-responsive.six-columns {
    max-width: 178px; } }

@media screen and (max-width: 767px) {
  .carousel-img-responsive.four-columns {
    max-width: 270px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .carousel-img-responsive.four-columns {
    max-width: 285px; } }

@media (min-width: 992px) {
  .carousel-img-responsive.four-columns {
    max-width: 300px; } }

.carousel-container.four-columns {
  grid-template-columns: repeat(4, 1fr); }

.carousel-container.six-columns {
  grid-template-columns: repeat(6, 1fr); }

/* Tablet View */
#maincontent {
  overflow-x: hidden; }

.product-tile--results > .body-txt-small, ul.vertical-menu.product-tile--results > li, .product-tile--results > .gift-info-detail,
.product-tile--results > h3.body-txt-small,
.product-tile--results > h3.gift-info-detail,
.product-tile--results > .product-tile-prices {
  margin-top: 10px;
  margin-bottom: 0; }

.body-text--saleprice {
  margin-left: 1rem; }

.link-wrapper {
  overflow: hidden;
  position: relative; }

.product-tile-fig {
  margin-bottom: 0; }

.solutions {
  text-align: center; }

.solutions > h3 {
  margin-top: 10px; }

.no-padding {
  padding-left: 0;
  padding-right: 0; }

body,
.virtual-tour__text,
.faq_list_a {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003; }
  @media screen and (max-width: 767px) {
    body,
    .virtual-tour__text,
    .faq_list_a {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    body,
    .virtual-tour__text,
    .faq_list_a {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    body,
    .virtual-tour__text,
    .faq_list_a {
      font-size: 22px;
      line-height: 34px; } }

.cs_content {
  margin: 0 auto;
  margin-bottom: 120px; }
  @media (max-width: 991px) {
    .cs_content {
      max-width: 100%; } }
  @media (min-width: 992px) {
    .cs_content {
      max-width: 80%; } }
  .cs_content ul {
    padding-left: 20px; }
    .cs_content ul li {
      margin-bottom: 20px; }
      .cs_content ul li::marker {
        color: #356c25; }

.cs-body {
  max-width: 860px;
  margin: 0 auto; }

.faq_general {
  margin: 0 auto;
  margin-bottom: 80px;
  max-width: 860px; }

.bs-faqs > .bs-faq:first-child {
  border-top: none; }

.faq_general_heading {
  margin-top: 70px; }

.no-case {
  text-transform: none !important; }

.page-link,
.faq_list a,
.item-name a {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003;
  text-transform: none !important;
  color: #042003 !important; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .page-link,
    .faq_list a,
    .item-name a {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    .page-link,
    .faq_list a,
    .item-name a {
      font-size: 22px;
      line-height: 30px; } }

.always-visible {
  display: block !important; }

.co .row {
  margin-left: 0;
  margin-right: 0; }

.topic-title {
  margin-top: 60px; }

@media screen and (max-width: 991px) {
  .community-article-wrapper {
    margin-bottom: 90px; } }

@media (min-width: 992px) {
  .community-article-wrapper {
    margin-bottom: 150px; } }

.community-article-wrapper__img {
  margin: 0 auto;
  width: 100%;
  max-width: 900px;
  margin-top: 40px !important; }

.tips-and-tricks,
.community-article,
.gift-cert-content {
  margin-bottom: 120px; }

@media screen and (max-width: 991px) {
  .community-tips-link {
    margin-top: 50px; } }

@media (min-width: 992px) {
  .community-tips-link {
    margin-top: 75px; } }

.community-article {
  margin: 0 auto;
  margin-top: 40px;
  max-width: 900px; }

.community-article h3 {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003;
  margin-top: 50px;
  margin-bottom: 10px; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .community-article h3 {
      font-size: 22px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    .community-article h3 {
      font-size: 26px;
      line-height: 30px; } }

@media screen and (max-width: 991px) {
  .tips-link.shop-all {
    margin: 10px 0 50px; } }

.row {
  margin-left: 0;
  margin-right: 0; }

.orders-table {
  margin-top: 40px; }

.account,
#orderprintable {
  margin-bottom: 70px; }

#orderprintable .cs_title {
  display: flex;
  align-items: center; }
  #orderprintable .cs_title a {
    margin-left: auto; }

@media print {
  #orderprintable {
    padding-left: 10px;
    padding-right: 10px; } }

.form-control.bs-input-static.bs-input {
  border-color: #e5e5e5;
  color: #757575;
  cursor: disabled; }

input[type=checkbox].account-edit-checkbox {
  height: 20px;
  width: 20px;
  margin-left: -25px; }

.flypage.bs-faqs--wrapper {
  margin-top: 45px; }

details.bs-faq > summary.bs-faq--question > h3,
details.bs-faq > summary.bs-faq--question h4 {
  position: relative;
  padding-right: 20px; }
  details.bs-faq > summary.bs-faq--question > h3::after,
  details.bs-faq > summary.bs-faq--question h4::after {
    content: url(/dist/rsp/svg/intermediate-site-icons/Icon_Plus.svg);
    position: absolute;
    right: 0; }

details.bs-faq[open] > summary > h3,
details.bs-faq[open] > summary h4 {
  position: relative; }
  details.bs-faq[open] > summary > h3::after,
  details.bs-faq[open] > summary h4::after {
    /* content: '—'; */
    content: url(/dist/rsp/svg/intermediate-site-icons/Icon_Minus.svg);
    position: absolute;
    position: absolute;
    right: 0; }

details.bs-faq {
  padding: 32px 0;
  border-top: 2px solid #e5e5e5;
  cursor: pointer; }

details.bs-faq:last-child {
  border-bottom: 2px solid #e5e5e5; }

.bs-faq--answer {
  margin: auto;
  margin-top: 30px; }

.bs-faq--question > h3 {
  margin: 0; }

summary::-webkit-details-marker {
  display: none; }

@media (max-width: 991px) {
  details.bs-faq.faq-page {
    padding: 9px 0; } }

@media (min-width: 992px) {
  details.bs-faq.faq-page {
    padding: 16px 0; } }

/* Gift for gardeners, community and community article four column items design */
.row {
  margin-left: 0;
  margin-right: 0; }

.community-title {
  margin-top: 20px !important; }

.shop-all--arrow {
  width: 18px;
  height: 18px; }

.img-responsive {
  margin: 0 auto; }

.cta-promo-body {
  border: none;
  padding: 0; }

.topics-view {
  display: flex;
  column-gap: 35px;
  flex-direction: column; }

@media screen and (max-width: 766px) {
  .fp_cat_row {
    display: grid;
    gap: 35px;
    grid-template-columns: repeat(2, 1fr); }
  .fp_cat > p {
    display: none; }
  .fp_cat h3 {
    text-align: center; }
  .gift-cert-content {
    margin-bottom: 60px; } }

@media screen and (min-width: 767px) {
  .gift-cert-content {
    margin-bottom: 60px; } }

@media screen and (max-width: 991px) {
  .fp_cat_row {
    display: grid;
    column-gap: 35px;
    grid-template-columns: repeat(2, 1fr); } }

@media screen and (min-width: 992px) {
  .fp_cat_row {
    display: grid;
    column-gap: 35px;
    grid-template-columns: repeat(4, 1fr); }
  .topics-view {
    flex-direction: row; }
  .fp_cat {
    width: 100%; } }

@media screen and (max-width: 991px) {
  .fp_cat_row {
    margin-bottom: 90px; } }

@media (min-width: 992px) {
  .fp_cat_row {
    margin-bottom: 120px; } }

.topics-view-item:first-child {
  display: none; }

@media screen and (max-width: 991px) {
  .third-promo--wrapper.article {
    margin-top: 0px; } }

.js-tips-zip-finder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer; }

.article-separator {
  margin-bottom: 50px; }

h2.explore-more {
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    h2.explore-more {
      font-size: 30px; } }

.new-account-form {
  margin-bottom: 175px; }

.pl0 {
  padding-left: 0; }

.pr0 {
  padding-right: 0; }

.no-orders {
  padding: 15px;
  border: 1px solid #e5e5e5; }

.catalog-request-response-title {
  margin-top: 60px; }

.catalog-request-response {
  margin-bottom: 90px; }

.carousel-container.four-columns.catalog-request-container {
  display: flex; }
  @media screen and (max-width: 991px) {
    .carousel-container.four-columns.catalog-request-container {
      justify-content: flex-start; } }
  @media screen and (min-width: 992px) {
    .carousel-container.four-columns.catalog-request-container {
      justify-content: center; } }

.catalog-request-img-responsive {
  max-width: 190px; }

.link-as-btn {
  font-size: 16px !important;
  font-weight: 900 !important;
  letter-spacing: 1.5px !important;
  margin-left: 25px !important; }

.account-msg {
  margin-bottom: 30px; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor; }

.icon--feature-BEES {
  fill: #fff;
  stroke: #000; }

.icon--cart {
  width: 35px;
  height: 35px; }

.icon--search {
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  margin-top: -14px; }

.icon--feature {
  width: 1.6em;
  height: 1.6em;
  top: -0.1em; }

.icon--bbb-logo {
  width: 52px;
  height: 52px;
  fill: #1353b6;
  margin-right: 5px; }

.icon--guarantee {
  width: 125px;
  height: 53px; }

a > .icon {
  pointer-events: none; }

input {
  padding: 0; }

.input-group-addon {
  outline: 1px solid #757575; }

.error-icon {
  display: none; }

.error-icon-svg {
  height: 17px;
  width: 5px; }

.bs-input,
.bs-input.form-control {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  border: none;
  border: 1px solid #757575;
  padding: 12px 16px;
  height: 55px;
  color: #042003;
  font-size: 20px; }
  .bs-input:focus,
  .bs-input.form-control:focus {
    border: 3px solid #004b80; }
  .bs-input.error,
  .bs-input.form-control.error {
    border: 3px solid #da4e2b;
    display: inline-block;
    color: #da4e2b; }
    .bs-input.error + span.error-icon,
    .bs-input.form-control.error + span.error-icon {
      display: inline-block;
      position: absolute;
      right: 10px;
      margin-top: 12px; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .bs-input,
    .bs-input.form-control {
      height: 50px; } }

.bs-input-small {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  border: none;
  border: 1px solid #757575;
  padding: 12px 16px;
  height: 55px;
  color: #042003;
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal;
  height: 50px;
  border: 1px solid #757575; }
  .bs-input-small:focus {
    border: 3px solid #004b80; }
  .bs-input-small.error {
    border: 3px solid #da4e2b;
    display: inline-block;
    color: #da4e2b; }
    .bs-input-small.error + span.error-icon {
      display: inline-block;
      position: absolute;
      right: 10px;
      margin-top: 12px; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .bs-input-small {
      height: 50px; } }
  @media screen and (max-width: 767px) {
    .bs-input-small {
      font-size: 18px;
      line-height: calc(26 / 18); } }
  @media (min-width: 768px) and (max-width: 991px) {
    .bs-input-small {
      font-size: 20px;
      line-height: calc(28 / 20); } }
  @media (min-width: 992px) {
    .bs-input-small {
      font-size: 20px;
      line-height: 1; } }

textarea.bs-input.form-control {
  height: auto;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  border: none;
  border: 1px solid #757575;
  padding: 12px 16px;
  height: 55px;
  color: #042003; }
  textarea.bs-input.form-control:focus {
    border: 3px solid #004b80; }
  textarea.bs-input.form-control.error {
    border: 3px solid #da4e2b;
    display: inline-block;
    color: #da4e2b; }
    textarea.bs-input.form-control.error + span.error-icon {
      display: inline-block;
      position: absolute;
      right: 10px;
      margin-top: 12px; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    textarea.bs-input.form-control {
      height: 50px; } }

select.bs-input {
  padding-right: 25px !important; }
  select.bs-input.error + span.error-icon {
    right: 20px !important; }

.bs-tag {
  border-radius: 3px;
  border: none;
  outline: 2px solid #e5e5e5;
  padding: 11px 16px;
  height: 40px; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .bs-tag {
      height: 35px;
      padding: 8px 16px; } }
  .bs-tag::after {
    content: "\00d7";
    display: inline-block;
    margin-left: 10px;
    transform: scale(1.25);
    line-height: 1;
    cursor: pointer; }

.message {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold; }
  @media screen and (max-width: 767px) {
    .message {
      font-size: 20px;
      line-height: calc(28 / 20); } }
  @media (min-width: 768px) and (max-width: 991px) {
    .message {
      font-size: 22px;
      line-height: calc(32 / 22); } }
  @media (min-width: 992px) {
    .message {
      font-size: 22px;
      line-height: calc(32 / 22); } }
  .message.error {
    color: #da4e2b; }
  .message.success {
    color: #356c25; }
    .message.success::before {
      content: url(/dist/rsp/svg/intermediate-site-icons/Checkmark.svg);
      margin-right: 7px; }

input::-webkit-input-placeholder {
  /* Edge */
  color: #757575 !important; }

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #757575 !important; }

input::placeholder {
  color: #757575 !important; }

input:-moz-placeholder {
  color: #757575 !important; }

textarea::-webkit-input-placeholder {
  /* Edge */
  color: #757575 !important; }

textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #757575 !important; }

textarea::placeholder {
  color: #757575 !important; }

textarea:-moz-placeholder {
  color: #757575 !important; }

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-300-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-300-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-300-subset.ttf") format("truetype");
  font-display: swap; }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-regular-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-regular-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-regular-subset.ttf") format("truetype");
  font-display: swap; }

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-600-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-600-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-600-subset.ttf") format("truetype");
  font-display: swap; }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-700-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-700-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-700-subset.ttf") format("truetype");
  font-display: swap; }

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url("/fonts/open-sans-v15-latin/open-sans-v15-latin-800-subset.woff2") format("woff2"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-800-subset.zopfli.woff") format("woff"), url("/fonts/open-sans-v15-latin/open-sans-v15-latin-800-subset.ttf") format("truetype");
  font-display: swap; }

/* merriweather-300 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/merriweather-v19-latin/merriweather-v19-latin-300-subset.woff2") format("woff2"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-300-subset.zopfli.woff") format("woff"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-300-subset.ttf") format("truetype");
  font-display: swap; }

/* merriweather-regular - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/merriweather-v19-latin/merriweather-v19-latin-regular-subset.woff2") format("woff2"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-regular-subset.zopfli.woff") format("woff"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-regular-subset.ttf") format("truetype");
  font-display: swap; }

/* merriweather-italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/merriweather-v19-latin/merriweather-v19-latin-italic-subset.woff2") format("woff2"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-italic-subset.zopfli.woff") format("woff"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-italic-subset.ttf") format("truetype");
  font-display: swap; }

/* merriweather-700 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/merriweather-v19-latin/merriweather-v19-latin-700-subset.woff2") format("woff2"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-700-subset.zopfli.woff") format("woff"), url("/fonts/merriweather-v19-latin/merriweather-v19-latin-700-subset.ttf") format("truetype");
  font-display: swap; }

/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2022 Schwartzco Inc.
        License: 2208-FHHPES     
*/
@font-face {
  font-family: 'Lyon Text Web';
  src: url("/fonts/Lyon-Commercial-Type/LyonText-BlackItalic-Web.woff2") format("woff2"), url("/fonts/Lyon-Commercial-Type/LyonText-BlackItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal; }

.LyonText-BlackItalic-Web {
  font-family: 'Lyon Text Web';
  font-weight: 900;
  font-style: italic;
  font-stretch: normal; }

@font-face {
  font-family: 'Lyon Text Web';
  src: url("/fonts/Lyon-Commercial-Type/LyonText-Black-Web.woff2") format("woff2"), url("/fonts/Lyon-Commercial-Type/LyonText-Black-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap; }

.LyonText-Black-Web {
  font-family: 'Lyon Text Web';
  font-weight: 900;
  font-style: normal;
  font-stretch: normal; }

/* Google Lato - 400 - regular */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato-Google-Fonts/Lato-Regular.ttf") format("truetype");
  font-display: swap; }

/* Google Lato - 400 - regular italic */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/Lato-Google-Fonts/Lato-Italic.ttf") format("truetype");
  font-display: swap; }

/* Google Lato - 700 - bold */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato-Google-Fonts/Lato-Bold.ttf") format("truetype");
  font-display: swap; }

/* Google Lato - 900 - black */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato-Google-Fonts/Lato-Black.ttf") format("truetype");
  font-display: swap; }

a {
  color: #356c25;
  text-decoration: none; }

h1.headline-secondary {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  color: #042003; }
  @media screen and (max-width: 767px) {
    h1.headline-secondary {
      font-size: 34px;
      line-height: 42px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    h1.headline-secondary {
      font-size: 48px;
      line-height: 56px; } }
  @media (min-width: 992px) {
    h1.headline-secondary {
      font-size: 54px;
      line-height: 60px; } }

h1, .headline-large {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  color: #042003;
  font-weight: 900; }
  @media screen and (max-width: 767px) {
    h1, .headline-large {
      font-size: 30px;
      line-height: 42px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    h1, .headline-large {
      font-size: 40px;
      line-height: 56px; } }
  @media (min-width: 992px) {
    h1, .headline-large {
      font-size: 54px;
      line-height: 60px; } }

h2, .headline-medium, .h2 {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px) {
    h2, .headline-medium, .h2 {
      font-size: 30px;
      line-height: calc(38 / 30); } }
  @media (min-width: 768px) and (max-width: 991px) {
    h2, .headline-medium, .h2 {
      font-size: 40px;
      line-height: calc(50 / 40); } }
  @media (min-width: 992px) {
    h2, .headline-medium, .h2 {
      font-size: 44px;
      line-height: calc(54 / 44); } }

.headline-medium-modified, .h2-modified {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .headline-medium-modified, .h2-modified {
      font-size: 24px;
      line-height: 32px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .headline-medium-modified, .h2-modified {
      font-size: 30px;
      line-height: 44px; } }
  @media (min-width: 992px) {
    .headline-medium-modified, .h2-modified {
      font-size: 30px;
      line-height: 44px; } }

h3, .headline-small, .h3 {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    h3, .headline-small, .h3 {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    h3, .headline-small, .h3 {
      font-size: 22px;
      line-height: 30px; } }

h4, .headline-tiny, .h4 {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    h4, .headline-tiny, .h4 {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    h4, .headline-tiny, .h4 {
      font-size: 20px; } }

h5, .headline-h5, .gift-info-detail {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    h5, .headline-h5, .gift-info-detail {
      font-size: 16px; } }
  @media screen and (min-width: 992px) {
    h5, .headline-h5, .gift-info-detail {
      font-size: 18px; } }

.body-txt-link {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 900;
  color: #356c25;
  text-transform: uppercase;
  letter-spacing: 1px; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .body-txt-link {
      font-size: 15px; } }
  @media screen and (min-width: 992px) {
    .body-txt-link {
      font-size: 16px; } }
  .body-txt-link:hover {
    color: #356c25; }

.body-txt-link.normal {
  letter-spacing: normal;
  text-transform: none; }

.normal {
  letter-spacing: normal;
  text-transform: none; }

.body-txt-link.bold, .faq_list li a.body-txt-link, .faq_list_a li a.body-txt-link, .body-txt-medium a.body-txt-link, .community-article p a.body-txt-link, .bs-faq ul > li a.body-txt-link {
  font-weight: 900; }

.body-txt-medium, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b, .faq_list_a li p, .community-article p, .bs-faq ul > li {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .body-txt-medium, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b, .faq_list_a li p, .community-article p, .bs-faq ul > li {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt-medium, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b, .faq_list_a li p, .community-article p, .bs-faq ul > li {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt-medium, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b, .faq_list_a li p, .community-article p, .bs-faq ul > li {
      font-size: 22px;
      line-height: 34px; } }

.body-txt-medium.bold, .faq_list li.bold, .faq_list li a, .faq_list_a li a, .faq_list_a li b.bold, .faq_list_a li p.bold, .community-article p.bold, .body-txt-medium.secondary-header--description .faq_list li a, .faq_list li .body-txt-medium.secondary-header--description a, .faq_list li.secondary-header--description a, .faq_list li a.secondary-header--description a, .community-article p.secondary-header--description .faq_list li a, .faq_list li .community-article p.secondary-header--description a, .faq_list_a li a.secondary-header--description a, .faq_list_a li b.secondary-header--description a, .faq_list_a li p.secondary-header--description a, .body-txt-medium a.body-txt-medium, .community-article p a.body-txt-medium, .bs-faq ul > li a.body-txt-medium, .bs-faq ul > li.bold {
  font-weight: bold; }

.body-txt-medium.link, .faq_list li, .faq_list li a, .faq_list_a li a, .faq_list_a li b.link, .faq_list_a li p.link, .community-article p.link, .body-txt-medium.secondary-header--description .faq_list li a, .faq_list li .body-txt-medium.secondary-header--description a, .faq_list li.secondary-header--description a, .faq_list li a.secondary-header--description a, .community-article p.secondary-header--description .faq_list li a, .faq_list li .community-article p.secondary-header--description a, .faq_list_a li a.secondary-header--description a, .faq_list_a li b.secondary-header--description a, .faq_list_a li p.secondary-header--description a, .body-txt-medium a.body-txt-medium, .community-article p a.body-txt-medium, .bs-faq ul > li a.body-txt-medium, .bs-faq ul > li.link, .bs-faq .faq_list ul > li, .faq_list .bs-faq ul > li {
  color: #356c25; }

.body-txt-small, ul.vertical-menu > li, .gift-info-detail {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .body-txt-small, ul.vertical-menu > li, .gift-info-detail {
      font-size: 18px;
      line-height: 26px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt-small, ul.vertical-menu > li, .gift-info-detail {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 992px) {
    .body-txt-small, ul.vertical-menu > li, .gift-info-detail {
      font-size: 20px;
      line-height: 28px; } }

.body-txt-input-small {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .body-txt-input-small {
      font-size: 18px;
      line-height: calc(26 / 18); } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt-input-small {
      font-size: 20px;
      line-height: calc(28 / 20); } }
  @media (min-width: 992px) {
    .body-txt-input-small {
      font-size: 20px;
      line-height: 1; } }

.body-txt--price {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .body-txt--price {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt--price {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt--price {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .body-txt--price {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    .body-txt--price {
      font-size: 20px; } }

.price--large {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .price--large {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .price--large {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .price--large {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .price--large {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    .price--large {
      font-size: 20px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .price--large {
      font-size: 24px; } }
  @media screen and (min-width: 992px) {
    .price--large {
      font-size: 28px; } }
  .price--large .sale {
    color: #da4e2b; }

.body-txt--saleprice {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #042003;
  color: #da4e2b; }
  @media screen and (max-width: 767px) {
    .body-txt--saleprice {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt--saleprice {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt--saleprice {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .body-txt--saleprice {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    .body-txt--saleprice {
      font-size: 20px; } }

.body-txt--oldprice {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #757575;
  text-decoration: line-through; }
  @media screen and (max-width: 767px) {
    .body-txt--oldprice {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt--oldprice {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt--oldprice {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .body-txt--oldprice {
      font-size: 16px; } }
  @media screen and (min-width: 992px) {
    .body-txt--oldprice {
      font-size: 18px; } }

.body-txt--copyright {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  height: 35px;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .body-txt--copyright {
      font-size: 15px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt--copyright {
      font-size: 16px; } }
  @media (min-width: 992px) {
    .body-txt--copyright {
      font-size: 16px; } }

.success-txt {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #356c25; }
  @media screen and (max-width: 767px) {
    .success-txt {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .success-txt {
      font-size: 22px;
      line-height: 32px; } }
  @media (min-width: 992px) {
    .success-txt {
      font-size: 22px;
      line-height: 32px; } }

.error-txt, .co-invalid-group p.co-invalid.error,
.invalid-group p.error {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #da4e2b; }
  @media screen and (max-width: 767px) {
    .error-txt, .co-invalid-group p.co-invalid.error,
    .invalid-group p.error {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .error-txt, .co-invalid-group p.co-invalid.error,
    .invalid-group p.error {
      font-size: 22px;
      line-height: 32px; } }
  @media (min-width: 992px) {
    .error-txt, .co-invalid-group p.co-invalid.error,
    .invalid-group p.error {
      font-size: 22px;
      line-height: 32px; } }

.msg-txt {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .msg-txt {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .msg-txt {
      font-size: 22px;
      line-height: 32px; } }
  @media (min-width: 992px) {
    .msg-txt {
      font-size: 22px;
      line-height: 32px; } }

.BodSmall, .wl-action, .muted, .mb--catalog .mb-body,
.mb--pots .mb-body, .quick-links > li > a, .footer-colophon, .product-tile-availability {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 20px;
  line-height: calc(2/1.3); }

.body-txt-large, .cta-promo-body,
.callout,
.intro, .pd-desc, .cart-help, .gallery p {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .body-txt-large, .cta-promo-body,
    .callout,
    .intro, .pd-desc, .cart-help, .gallery p {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .body-txt-large, .cta-promo-body,
    .callout,
    .intro, .pd-desc, .cart-help, .gallery p {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .body-txt-large, .cta-promo-body,
    .callout,
    .intro, .pd-desc, .cart-help, .gallery p {
      font-size: 22px;
      line-height: 34px; } }

.cart-alert {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  line-height: 34px !important; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .cart-alert {
      font-size: 16px !important; } }
  @media screen and (min-width: 992px) {
    .cart-alert {
      font-size: 20px !important; } }
  .cart-alert--success {
    background: #eef7eb; }
  .cart-alert--error {
    background: #FEF3F0;
    color: #da4e2b; }

.cta-promo-header,
.cs_title, .product-tile-head, .cart-heading > h1,
.cart-heading > h2 {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  color: #042003;
  font-weight: 900; }
  @media screen and (max-width: 767px) {
    .cta-promo-header,
    .cs_title, .product-tile-head, .cart-heading > h1,
    .cart-heading > h2 {
      font-size: 30px;
      line-height: 42px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .cta-promo-header,
    .cs_title, .product-tile-head, .cart-heading > h1,
    .cart-heading > h2 {
      font-size: 40px;
      line-height: 56px; } }
  @media (min-width: 992px) {
    .cta-promo-header,
    .cs_title, .product-tile-head, .cart-heading > h1,
    .cart-heading > h2 {
      font-size: 54px;
      line-height: 60px; } }

.hp-tile-title--single, .hp-tile-subhead {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 2.0rem;
  line-height: 26px;
  line-height: calc(2.6/2);
  color: #fff; }

.mb--catalog .mb-head,
.mb--pots .mb-head, .acct-signin label, .category-promo-fig, .footer-toll-free, .nav-tabs > li > a.pd-meta-nav-link, .pd-specs-head, .product-tile-link, .notice__heading, .gallery-tile-link {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .mb--catalog .mb-head,
    .mb--pots .mb-head, .acct-signin label, .category-promo-fig, .footer-toll-free, .nav-tabs > li > a.pd-meta-nav-link, .pd-specs-head, .product-tile-link, .notice__heading, .gallery-tile-link {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    .mb--catalog .mb-head,
    .mb--pots .mb-head, .acct-signin label, .category-promo-fig, .footer-toll-free, .nav-tabs > li > a.pd-meta-nav-link, .pd-specs-head, .product-tile-link, .notice__heading, .gallery-tile-link {
      font-size: 22px;
      line-height: 30px; } }

.sidebar-heading, .pd-qty-price, .pd-bulk-discount-price, .product-tile-price, .garden-solutions .toggle-group-heading {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 22px;
  line-height: calc(2.2/1.5); }

.footer-signup-msg a, .footer-toll-free, .footer-toll-free .footer-tf-number a, .footer-toll-free .footer-mailto a {
  color: #fff; }

.pd-head-species {
  font-family: "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-style: italic; }

.footer-toll-free .footer-tf-number a, .footer-toll-free .footer-mailto a, .footer-mailto, .footer-colophon a {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

.bold, .faq_list li a, .faq_list_a li a, .body-txt-medium.secondary-header--description a, .faq_list li.secondary-header--description a, .faq_list li a.secondary-header--description a, .faq_list_a li a.secondary-header--description a, .faq_list_a li b.secondary-header--description a, .faq_list_a li p.secondary-header--description a, .community-article p.secondary-header--description a, .bs-faq ul > li.secondary-header--description a, .body-txt-medium a, .community-article p a, .bs-faq ul > li a {
  font-weight: bold; }

.font-weight-normal {
  font-weight: normal; }

.link, .faq_list li, .faq_list li a, .faq_list_a li a, .body-txt-medium.secondary-header--description a, .faq_list li.secondary-header--description a, .faq_list li a.secondary-header--description a, .faq_list_a li a.secondary-header--description a, .faq_list_a li b.secondary-header--description a, .faq_list_a li p.secondary-header--description a, .community-article p.secondary-header--description a, .bs-faq ul > li.secondary-header--description a, .body-txt-medium a, .community-article p a, .bs-faq ul > li a {
  color: #356c25; }

.disabled-section {
  opacity: 0.5;
  cursor: auto !important; }

.promo-header {
  background-color: #cc3921;
  display: grid;
  place-items: center;
  width: 100%;
  max-width: none !important;
  min-height: 45px; }
  .promo-header a {
    font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px; }
    @media screen and (max-width: 767px) {
      .promo-header a {
        font-size: 12px;
        line-height: calc(19 / 12); } }
    @media (min-width: 768px) and (max-width: 991px) {
      .promo-header a {
        font-size: 15px;
        line-height: calc(21 / 15); } }
    @media (min-width: 992px) {
      .promo-header a {
        font-size: 16px;
        line-height: calc(24 / 16); } }
  @media screen and (max-width: 1200px) {
    .promo-header {
      padding: 0.5em 0; } }
  .promo-header strong {
    font-weight: 900; }
  .mnav-active .promo-header {
    display: none; }

.masthead {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr; }

.menuline-nav a.masthead-tf-link {
  color: #333;
  font-size: 1.1em;
  font-weight: 700;
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .menuline-nav a.masthead-tf-link {
      font-size: 18px;
      line-height: 26px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .menuline-nav a.masthead-tf-link {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 992px) {
    .menuline-nav a.masthead-tf-link {
      font-size: 20px;
      line-height: 28px; } }

a.masthead-tf-link:hover {
  text-decoration: none; }

.menuline-nav li,
.menuline-nav a {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .menuline-nav li,
    .menuline-nav a {
      font-size: 18px;
      line-height: 26px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .menuline-nav li,
    .menuline-nav a {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 992px) {
    .menuline-nav li,
    .menuline-nav a {
      font-size: 20px;
      line-height: 28px; } }

.menuline-nav a {
  color: #000;
  display: flex; }

.top-search-input {
  border: 1px solid black;
  border-right: none;
  height: auto;
  padding-top: 0.88rem;
  padding-bottom: 0.92rem;
  height: 40px;
  padding-right: 40px;
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal;
  color: #454545; }
  @media screen and (max-width: 767px) {
    .top-search-input {
      font-size: 18px;
      line-height: 26px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .top-search-input {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 992px) {
    .top-search-input {
      font-size: 20px;
      line-height: 28px; } }

.top-search-input::placeholder {
  color: #757575; }

.suggestionsBox-rsp {
  display: none;
  z-index: 10; }

.suggestionsBox {
  left: 0;
  top: 40px;
  border-radius: 0;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-top: 1px solid #d9d9d9;
  padding-left: 5px; }

.suggestionList {
  margin: 0px;
  padding: 0px;
  margin-top: 5px; }

.suggestionList li {
  margin: 0px 0px 3px 0px;
  padding: 3px;
  cursor: pointer;
  list-style-type: none; }

.suggestionList li:hover {
  background-color: #659CD8; }

.bp-logo {
  width: 190px;
  height: 88px;
  pointer-events: none; }

.masthead-tf-link .zipcode {
  text-decoration: underline; }

a > .icon {
  pointer-events: all; }

@media screen and (min-width: 767px) {
  .masthead {
    padding: 0.5em 0;
    width: 100%; }
  .bp-logo-link {
    margin-bottom: -50px;
    padding: .5em;
    margin-top: -.5em;
    display: inline-block; }
  .bp-logo {
    width: 190px;
    height: 88px;
    pointer-events: none;
    vertical-align: unset; }
  .menuline-nav {
    margin-top: 1em; }
  .menuline-nav li {
    position: relative; }
  .menuline-nav li {
    position: relative; }
    .menuline-nav li::after {
      content: '';
      position: absolute;
      right: -3px;
      top: 0;
      bottom: 0;
      width: 2px;
      margin: 0.36em 0; }
    .menuline-nav li:last-of-type::after {
      display: none; }
    .menuline-nav li.last-item:after {
      display: none; }
  .rsp-search {
    margin: 1em 0; } }

.search-input-group {
  display: flex;
  position: relative; }

@media screen and (max-width: 1200px) {
  .search-logo {
    border: none;
    border-left: none;
    width: 40px;
    height: 50px;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    outline: none;
    padding: 0 10px; } }

@media (min-width: 1201px) {
  .search-logo {
    border: none;
    border-left: none;
    width: 40px;
    height: 55px;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    outline: none;
    padding: 0 10px; } }

@media (min-width: 992px) {
  .container--header {
    max-width: 1500px;
    margin: 0 auto; }
  .rsp-search {
    margin: 1em 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center; }
    .rsp-search > .header-right {
      justify-self: flex-end; }
  .menuline-nav ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 25px; }
  .menuline-nav li {
    position: relative; }
  .menuline-nav a {
    color: #000;
    display: flex;
    justify-content: right; } }

@media (min-width: 1200px) {
  .menuline-nav li {
    position: relative; } }

.top-banner {
  background-color: #064f06;
  height: 50px;
  margin-left: -15px;
  width: 1570px; }

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .rsp-search {
    display: none !important; } }

.js-item-count.item-count {
  position: absolute;
  right: 0;
  margin: 0 auto;
  color: #1d1c1a;
  font-size: 13px;
  font-weight: bold; }
  @media screen and (min-width: 1201px) {
    .js-item-count.item-count {
      left: 5px;
      top: -7px; } }
  @media screen and (max-width: 1200px) {
    .js-item-count.item-count {
      left: 0px;
      top: 2px; } }
  .js-item-count.item-count:hover, .js-item-count.item-count:active {
    text-decoration-skip: initial;
    text-decoration-skip-ink: none; }

.menuline-cart-link {
  position: relative;
  text-align: center; }
  .menuline-cart-link:hover, .menuline-cart-link:active {
    text-decoration-skip: initial;
    text-decoration-skip-ink: none; }

.m-icon.account {
  margin-right: 10px;
  margin-bottom: 0px; }

.m-icon.m-cart-icon,
.m-icon.mobile-menu-icon,
.m-icon.account {
  fill: #042003; }

a {
  text-decoration-skip-ink: auto; }

.larger-input {
  border-radius: 3px; }

hr {
  border-color: #bfbfbf; }

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 0; }

.pagination > li.active a, .pagination > li.active span {
  background-color: #fff;
  text-decoration: underline;
  color: #333;
  border-color: #ddd; }
  .pagination > li.active a:hover, .pagination > li.active a:active, .pagination > li.active a:focus, .pagination > li.active span:hover, .pagination > li.active span:active, .pagination > li.active span:focus {
    background-color: #fff;
    border-color: #ddd;
    color: #333; }

.pagination > li a:active, .pagination > li a:focus, .pagination > li span:active, .pagination > li span:focus {
  outline-color: #ddd; }

@media (prefers-reduced-motion) {
  .img-thumbnail, .fade {
    transition: none; } }

.btn-lg {
  padding: 12px 32px; }

@media (max-width: 420px) {
  .container {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px; } }

@media (min-width: 421px) and (max-width: 767px) {
  .container {
    width: 100%;
    padding-right: 55px;
    padding-left: 55px; } }

@media (min-width: 768px) and (max-width: 1540px) {
  .container {
    width: 100%;
    padding-right: 120px;
    padding-left: 120px; } }

@media (min-width: 1541px) {
  .container {
    max-width: 1300px;
    padding-right: 0;
    padding-left: 0; } }

@media (max-width: 420px) {
  .container-header {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 421px) and (max-width: 767px) {
  .container-header {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px; } }

@media (min-width: 768px) and (max-width: 1540px) {
  .container-header {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px; } }

@media (min-width: 1541px) {
  .container-header {
    max-width: 1460px;
    padding-right: 0;
    padding-left: 0; } }

.container-fluid {
  padding-left: 0px;
  padding-right: 0px; }

a:hover, a:focus {
  text-decoration: none; }

.menu-toggle, .search-toggle {
  position: absolute;
  z-index: 1;
  opacity: 0; }

.main-menu ul {
  list-style: none;
  padding: 0;
  margin: 0; }

@media (min-width: 1201px) and (max-width: 1350px) {
  ul.category-links, div.category-promos {
    margin-right: 35px;
    min-width: 190px; } }

@media (min-width: 1356px) {
  ul.category-links, div.category-promos {
    margin-right: 35px;
    min-width: 230px; } }

div.category-promos:last-child {
  margin-right: 0; }

.main-menu .drawer-inner {
  display: flex;
  flex-direction: column; }

@media screen and (max-width: 1200px) and (max-width: 420px) {
  .drawer-inner {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px; } }

@media screen and (max-width: 1200px) and (min-width: 421px) and (max-width: 767px) {
  .drawer-inner {
    width: 100%;
    padding-right: 55px;
    padding-left: 55px; } }

@media screen and (max-width: 1200px) and (min-width: 768px) and (max-width: 1540px) {
  .drawer-inner {
    width: 100%;
    padding-right: 120px;
    padding-left: 120px; } }

@media screen and (max-width: 1200px) and (min-width: 1541px) {
  .drawer-inner {
    max-width: 1300px;
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .hr {
    border-color: #bfbfbf;
    border-width: 1px;
    width: 100%;
    margin: 0;
    margin-bottom: 15px; } }

.top-level-link {
  display: block;
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003;
  text-decoration: none; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .top-level-link {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    .top-level-link {
      font-size: 22px;
      line-height: 30px; } }
  .top-level-link:hover, .top-level-link:focus {
    text-decoration: none;
    color: #fff; }

.top-level-link[href="#"]:hover, .top-level-link[href="#"]:active {
  cursor: default; }

.subnav .subnav-link--sale {
  color: #da4e2b;
  font-weight: 700; }

.subnav,
.subnav-heading,
.top-solutions-links,
.subnav .category-promos {
  display: none; }

.subnav-heading,
.top-solutions-links {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .subnav-heading,
    .top-solutions-links {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    .subnav-heading,
    .top-solutions-links {
      font-size: 22px;
      line-height: 30px; } }

.subnav-link {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal;
  display: block;
  text-decoration: none;
  line-height: 22px !important; }
  @media screen and (max-width: 767px) {
    .subnav-link {
      font-size: 18px;
      line-height: 26px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .subnav-link {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 992px) {
    .subnav-link {
      font-size: 20px;
      line-height: 28px; } }
  .subnav-link:hover, .subnav-link:active, .subnav-link:focus {
    text-decoration: none;
    color: #333333; }

.has-subnav--active .subnav {
  display: block;
  opacity: 1; }

.subnav {
  background-color: #fff;
  transition: opacity 1s linear;
  transition: visibility 1s linear;
  opacity: 0; }
  @media (prefers-reduced-motion) {
    .subnav {
      transition: none; } }

.quick-links > li > a {
  color: #333;
  text-decoration: none; }

.quick-links li {
  padding: 0 0.8em;
  position: relative; }
  .quick-links li::after {
    content: '';
    position: absolute;
    right: -3px;
    top: 0;
    bottom: 0;
    width: 2px;
    margin: 0.36em 0; }
  .quick-links li:last-of-type::after {
    display: none; }

@media screen and (max-width: 1200px) {
  .mnav-active,
  .msr-panel-active {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden; }
  .m-search {
    display: none; }
  .menu-toggle:checked ~ .drawer {
    transform: translateX(0); }
  .drawer {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    margin-bottom: 1em;
    font-size: 1.6rem;
    text-align: left;
    background: white;
    transform: translateX(120%);
    transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1);
    box-shadow: 0 5px 5px rgba(50, 50, 50, 0.2); }
  .main-menu {
    width: 100%;
    height: 100%;
    transform: translateX(-120%);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    box-shadow: none;
    background-color: #042003;
    padding-bottom: 3em; }
  .menu-close {
    text-align: right;
    margin-top: 1em; }
  .m-icon.mobile-close-icon {
    width: 20px;
    margin-right: 1em; }
  .m-search {
    transform: translateX(120%);
    padding: 1em 15px 1.5em 15px;
    font-size: 1.4em;
    border-bottom: 0.8em solid #e1eef4; }
  .m-suggestions-wrap {
    display: none;
    margin-top: 0.5em; }
  .m-suggestions > li {
    color: #333;
    padding: 0.5em 0; }
  .acct-menu {
    border-bottom: 0.56em solid #e1eef4; }
  .acct-signin {
    padding: 10px 15px 1.5em 15px;
    color: #000; }
  .acct-links li:first-child .top-level-link {
    border-top: none 0; }
  .acct-signin label {
    margin-bottom: 0.6em; }
  .acct-signin .m-input {
    font-size: 1em;
    padding: 0.4em 0.4em; }
  .m-btn--signin {
    font-size: 0.85em;
    margin-top: 0; }
  .acct-signin fieldset {
    margin-bottom: 1.3em; }
  .m-input,
  input[type=search].m-search-input {
    display: block;
    width: 100%;
    border: 1px solid #ccc;
    appearance: none;
    border-radius: 0; }
  .m-search-input, .m-input {
    font-size: 1.4em;
    color: #000;
    padding: 0.22em 0.2em; }
    .m-search-input:focus, .m-input:focus {
      outline: 0.16em solid #3a7c9c; }
  .m-btn {
    margin-top: 0.5em;
    width: 100%; }
  .main-nav-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    margin: 0 0 0 0; }
  .m-icon {
    width: 30px;
    height: 30px;
    fill: currentColor; }
  .m-bp-logo {
    width: 154px;
    height: 55px; }
  .mnav-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    color: #58595b; }
    .mnav-section > label {
      margin: 0; }
  .main-menu .quick-links {
    padding: 1.7em 0; }
  .quick-links {
    order: 8; }
  .mnav-section--active {
    color: #3a7c9c; }
  .mnav-section > a {
    position: relative;
    display: inline-table;
    color: #58595b; }
  .mnav-logo-section {
    text-align: center;
    flex-grow: 2; }
  .m-cart-icon {
    fill: #5d5d5d; }
  .top-level-link {
    padding: 1em 0;
    position: relative;
    color: #fff; }
  .top-level-link--perennials {
    border-top: none 0; }
  .has-subnav--active .top-level-link {
    border-bottom-color: #fff; }
    .has-subnav--active .top-level-link::after {
      content: '\2013'; }
  .subnav {
    padding: 0 15px 10px 15px;
    margin: -0.6em 0 0 0; }
  .subnav a {
    padding: 0.3em 0; }
  .quick-links {
    background-color: #f4f8fb;
    background-color: rgba(58, 124, 156, 0.056);
    padding: 2em 0 3em; }
  .quick-links li {
    display: block;
    padding: 0; }
  .quick-links a {
    display: block;
    padding: 0.3em 15px; }
  .menu-links {
    display: flex;
    flex-wrap: wrap; }
    .menu-links > li {
      width: 33.33%;
      aspect-ratio: 1;
      display: flex;
      flex-direction: column-reverse;
      padding: 0em 0.5em;
      margin-bottom: 1em;
      align-self: flex-start;
      word-break: break-word; } }

@media screen and (max-width: 499px) {
  .menu-links {
    display: flex;
    flex-wrap: wrap; }
    .menu-links > li {
      width: 50%; } }

@media (max-width: 420px) {
  .container.container--main-nav {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 421px) and (max-width: 767px) {
  .container.container--main-nav {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px; } }

@media (min-width: 768px) and (max-width: 1540px) {
  .container.container--main-nav {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px; } }

@media (min-width: 1541px) {
  .container.container--main-nav {
    max-width: 1460px;
    padding-right: 0;
    padding-left: 0; } }

@media screen and (min-width: 500px) and (max-width: 1200px) {
  .m-icon {
    width: 30px;
    height: 30px; }
  .mnav-section {
    text-align: center;
    height: 60px; }
  .mnav-logo-section {
    flex-grow: 10; }
  .m-bp-logo {
    width: 154px;
    height: 55px; }
  .drawer {
    top: 0; }
  .m-search > .m-search-form {
    width: 100%;
    display: flex;
    align-items: center; }
  .m-search-input {
    flex-grow: 1;
    width: auto; }
  .m-btn--search {
    margin: 0 0 0 1em;
    width: auto;
    flex-grow: 0.4; }
  .m-btn--signin {
    width: 10em; } }

@media screen and (max-width: 1200px) {
  .menu.hr {
    display: none;
    margin: 0;
    margin-bottom: 15px; }
  .mobile-search-section {
    margin-bottom: 4em; }
  .signin-link.body-txt-link {
    font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 900;
    color: #356c25;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: transparent;
    border: 3px solid #fff;
    border-radius: 4px;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    padding: 0.5em;
    display: inline-block;
    text-align: center;
    color: #fff; } }
  @media screen and (max-width: 1200px) and (max-width: 767px), screen and (max-width: 1200px) and (min-width: 768px) and (max-width: 991px) {
    .signin-link.body-txt-link {
      font-size: 15px; } }
  @media screen and (max-width: 1200px) and (min-width: 992px) {
    .signin-link.body-txt-link {
      font-size: 16px; } }

@media screen and (max-width: 1200px) {
    .signin-link.body-txt-link:hover {
      color: #356c25; }
    .signin-link.body-txt-link:hover {
      color: #fff; }
  .drawer-signin {
    margin-bottom: 2em; } }

.drawer-links {
  display: flex;
  justify-content: center; }
  .drawer-links > a {
    color: #fff; }
    .drawer-links > a:hover, .drawer-links > a:focus {
      text-decoration: none;
      color: #fff; }

.drawer-link:first-child {
  margin: 0 calc(0.15em + 2vw); }

.drawer-link + .segment > .text-white:first-child {
  margin-left: calc(0.15em + 1vw); }

a.mobile-menu-img {
  display: flex;
  flex-direction: column-reverse; }

a.mobile-menu-img > img {
  width: 100%;
  height: 100%; }

@media screen and (min-width: 1201px) {
  .mnav-section > .menu-toggle,
  .mnav-section > label,
  .mnav-section > a,
  .menu-close,
  .top-level-link + img,
  .top-level-link + a.mobile-menu-img,
  .mobile-search-section,
  .drawer-actions {
    display: none; }
  .mnav-section--menu {
    width: 100%; }
    .mnav-section--menu .menu-links {
      margin: auto;
      width: 100%; }
  .has-subnav {
    float: left; }
  .main-menu .quick-links {
    background: url(/rsp/css-images/shadow-horizontal.jpg) #fff bottom left no-repeat;
    padding-bottom: 2em;
    margin-bottom: 1em; }
    .main-menu .quick-links li:first-child {
      padding-left: 0; }
      .main-menu .quick-links li:first-child a {
        padding-left: 0; }
    .main-menu .quick-links li, .main-menu .quick-links a {
      float: left; }
    .main-menu .quick-links a {
      display: block;
      padding: 0.18em 0.2em 0.2em 0.2em; }
  .top-level-link {
    padding-bottom: 10px;
    border-bottom: 6px solid #fff; }
    .menu-links :first-child .top-level-link {
      margin-left: 0; }
    .menu-links :last-child .top-level-link {
      margin-right: 0; }
    .has-subnav--active .top-level-link {
      color: #356c25;
      border-color: #356c25; }
      .has-subnav--active .top-level-link:hover, .has-subnav--active .top-level-link:active {
        color: #356c25; }
    .top-level-link:hover, .top-level-link:active {
      color: initial; }
  .subnav-heading,
  .top-solutions-links {
    display: block;
    padding-bottom: 0.3em; }
  li.subnav-heading {
    padding-bottom: 0.3em;
    padding-top: 1.4em; }
  .subnav .category-promos {
    display: block;
    margin-top: calc(1.4em + 22px); }
  .main-menu .menu-links {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5em 0;
    gap: 40px;
    margin: 0 auto;
    width: auto; }
  .subnav {
    position: absolute;
    width: calc(100% + 15px);
    left: -15px;
    z-index: 4;
    padding: 1.5em 60px 6em;
    background: #fff;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.19); }
  .subnav-link {
    padding: 0.5em 0; }
  .top-level-link--sale:hover,
  .top-level-link--sale:active,
  .top-level-link--sale:focus,
  .subnav .subnav-link--sale:hover,
  .subnav .subnav-link--sale:active,
  .subnav .subnav-link--sale:focus {
    color: #e27256;
    text-decoration: none; } }

@media screen and (min-width: 1201px) and (min-width: 992px) {
  .category-promo-tile {
    position: relative;
    display: block;
    width: 231px;
    height: 231px;
    background-color: #3a7c9c;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover; } }

@media screen and (min-width: 1201px) and (min-width: 1201px) and (max-width: 1350px) {
  .category-promo-tile {
    position: relative;
    display: block;
    width: 200px;
    height: 200px;
    background-color: #3a7c9c;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover; } }

@media screen and (min-width: 1201px) {
  .category-promo-text {
    width: 100%;
    padding: 0.5em 0; } }

@media screen and (min-width: 1200px) {
  .main-nav-top {
    display: flex;
    justify-content: center;
    margin-bottom: 0; }
  .main-menu .quick-links {
    display: flex;
    justify-content: center;
    background-size: contain; } }

.category-promo-text {
  color: #042003; }

.navlink.bs-btn, .navlink.btn-link {
  margin-top: 1.5em;
  min-width: auto;
  width: 180px;
  display: grid;
  place-items: center; }

.menu-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0px !important; }

.no-scroll {
  overflow: hidden; }

.fp-viewer-thumbs > .fp-viewer-thumb-box.active > a.nav-link.thumbnail:hover, .fp-viewer-thumbs > .fp-viewer-thumb-box.active > a.nav-link.thumbnail:focus {
  color: black;
  background-color: red; }

.nav-pills > li {
  float: left; }
  .nav-pills > li > a {
    border-radius: 4px; }
  .nav-pills > li + li {
    margin-left: 2px; }
  .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    border: 3px solid #356c25 !important;
    padding: 2px;
    background-color: white; }

.alpha-links {
  width: 100%;
  float: left;
  border-bottom: 1px solid #ccc;
  margin-bottom: 1.5em; }
  @media screen and (max-width: 767px) {
    .alpha-links {
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
      display: flex; } }
  .alpha-links :first-child.alpha-link-wrap {
    margin-left: 0; }
  .alpha-links :last-child.alpha-link-wrap {
    margin-right: 0; }

.alpha-link-wrap {
  margin: 0 0.5em; }

.alpha-link {
  display: block;
  padding: 0.5em 0.5em 0.4em;
  float: left;
  border-bottom: 4px solid #fff; }
  .alpha-link:link, .alpha-link:hover, .alpha-link:active, .alpha-link:focus {
    text-decoration: none; }
  .alpha-link.active, .alpha-link:hover, .alpha-link:active {
    color: #356c25; }
  .alpha-link.active {
    border-color: #356c25; }
  @media screen and (min-width: 769px) {
    .alpha-link {
      padding: 0.5em 0.65em 0.4em; } }

.plants-alpha-section {
  display: none;
  min-height: 30em; }
  .plants-alpha-section.active {
    display: block; }

.plant-links {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: calc(100% - 30px); }

.a-z-plant-link-wrap {
  width: 100%; }
  @media screen and (min-width: 650px) {
    .a-z-plant-link-wrap {
      width: fit-content; } }

.a-z-plant-link {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  text-transform: none;
  color: #356c25;
  font-weight: bold; }
  @media screen and (max-width: 767px) {
    .a-z-plant-link {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .a-z-plant-link {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .a-z-plant-link {
      font-size: 22px;
      line-height: 34px; } }
  .a-z-plant-link:link, .a-z-plant-link:hover, .a-z-plant-link:active, .a-z-plant-link:focus {
    color: #356c25;
    text-decoration: none; }

.footer-rsp {
  background: #042003;
  color: #fff;
  padding: 2em 0 0 0; }

.footer-signup-form {
  margin-bottom: 0.5em; }

.footer-signup-input,
.footer-signup-btn {
  display: block;
  width: 100%; }

.footer-signup-input {
  border: 3px solid #fff;
  color: #333;
  padding: 0.3em 0.6em 0.2em;
  margin-bottom: 1em;
  appearance: none;
  border-radius: 0; }
  .footer-signup-input::placeholder {
    color: #595959;
    opacity: 1; }

.footer-signup-msg {
  margin-bottom: 0.5em; }
  .footer-signup-msg a {
    color: #fff;
    border-bottom: 2px solid #3a7c9c;
    text-shadow: 1px 1px 3px #11232c;
    text-decoration: none; }

.footer-signup-msg .icon--checkmark {
  fill: #fff;
  display: inline-block;
  vertical-align: middle;
  width: 1.2em;
  height: 1.2em; }

.footer-section-toggle {
  z-index: 1;
  position: absolute;
  opacity: 0; }

.footer-section-heading {
  color: #fff; }

.footer-links a {
  padding: 0.15em 0; }

.segment-split {
  margin-left: 0; }

.footer-nav-contact {
  display: flex;
  justify-content: center;
  column-gap: 1em;
  text-decoration: none;
  color: white;
  margin-top: 1em; }

.footer-contact-info {
  color: #fff; }
  .footer-contact-info a {
    text-decoration: none;
    color: white; }
  .footer-contact-info .seg-group {
    white-space: nowrap; }
    .footer-contact-info .seg-group a {
      font-weight: normal; }

.footer-colophon {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.footer-toll-free {
  margin-top: 2em;
  margin-bottom: 0.5em;
  letter-spacing: 0.03em;
  font-weight: 600; }
  .footer-toll-free .footer-tf-number, .footer-toll-free .footer-mailto {
    float: left; }
  .footer-toll-free .footer-tf-number {
    margin-right: calc(0.15em + 1vw);
    text-decoration: none; }

.footer-mailto {
  color: inherit; }
  .footer-mailto:hover, .footer-mailto:active, .footer-mailto:focus {
    color: #fff; }

.footer-colophon {
  width: 100%;
  margin: 1em 0 2em 0; }
  .footer-colophon a {
    color: inherit; }

.footer-address {
  display: inline; }

.veracity-links {
  display: flex;
  width: 100%;
  overflow: hidden; }

.footer-seals {
  width: auto;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap; }

.geotrust-seal {
  height: 55px;
  width: 80px;
  position: relative;
  margin-left: 10px; }
  .geotrust-seal::after {
    position: absolute;
    display: block;
    top: 5px;
    left: 0;
    width: 100%;
    content: '';
    font-size: 0.8em;
    text-align: center; }

.guarantee-logo-img {
  display: block;
  max-width: 151px; }

@media screen and (min-width: 500px) {
  .footer-signup-form {
    display: flex; }
  .footer-signup-input,
  .footer-signup-btn {
    width: auto; }
  .footer-signup-input {
    flex-grow: 1;
    margin-right: 0.75em;
    margin-bottom: 0; }
  .footer-signup-btn {
    flex-grow: 0.12; } }

@media screen and (max-width: 612px) {
  .footer-address > .segment:first-child::before,
  .footer-mailto.segment::before {
    display: none; } }

.footer-links .body-txt-small, .footer-links ul.vertical-menu > li, .footer-links .gift-info-detail {
  font-size: 18px; }

.footer-logo-section {
  text-align: center; }
  .footer-logo-section .bp-logo {
    margin-bottom: 1em; }
  .footer-logo-section > .social-icons {
    display: flex;
    justify-content: center;
    column-gap: 1em; }
    .footer-logo-section > .social-icons svg {
      width: 40px;
      height: 40px; }

.footer-nav {
  margin: 1em auto; }

@media screen and (max-width: 767px) {
  .mobile-footer-actions {
    display: flex;
    flex-direction: column;
    margin: 2em auto;
    align-items: center;
    row-gap: 2em; }
  .footer-links {
    transition: all 0.2s ease;
    height: 0;
    display: none; }
  .footer-links.expanded {
    height: auto;
    display: block; }
  .footer-links-section {
    font-size: 1.6rem;
    padding: 1em 0;
    border-bottom: 1px solid #425641;
    display: flex;
    flex-direction: column; }
  .footer-links-section > strong {
    margin: 0 25px;
    position: relative;
    cursor: pointer; }
  .footer-links-section:nth-child(2) {
    border-top: 1px solid #425641; }
  .footer-links {
    margin: 0 25px; }
    .footer-links li {
      margin: 1rem 0; }
    .footer-links a {
      padding: 0.3em 0; }
  .footer-nav {
    width: 100%;
    margin: 0 auto; }
  .footer-section-heading {
    display: block;
    padding: 1em 15px 0.8em 15px; }
    .footer-section-heading:after {
      content: '+';
      font-size: 34px;
      position: absolute;
      right: 0; }
  .footer-section-heading.expanded:after {
    content: '\2013'; }
  .footer-tf-number, .footer-mailto {
    display: block; }
  .footer-contact-info {
    text-align: center; }
  .footer-contact-info:first-child {
    margin-top: 2em; }
  .footer-contact-info:last-child {
    margin-top: 1em;
    margin-bottom: 1em; }
  .footer-nav-contact {
    flex-direction: column; }
  .footer-logo-section .bp-logo {
    margin-bottom: 3em; }
  .footer.container, .footer-cta.container {
    padding: 0; }
  .footer-cta.container > .row {
    margin: 0; }
  .footer-contact-info .segment {
    padding: 0 1em; }
  .footer-colophon {
    flex-direction: column;
    align-items: center; }
  .footer-contact-info .segment::before {
    display: none; }
  .footer-contact-info .segment:nth-child(2)::before {
    display: block; }
  .footer-contact-info .segment:nth-child(2)::after {
    content: "\007C";
    position: absolute;
    right: calc(-.37em + -1vw);
    font-weight: bolder;
    opacity: .5; }
  .footer-contact-info .seg-group {
    margin: 0.5em auto; }
    .footer-contact-info .seg-group .footer-address {
      display: block; }
  .footer-contact-info .seg-group:first-child {
    margin-bottom: 0; }
  .footer-contact-info .seg-group:nth-child(2) {
    margin-top: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .footer-links-section {
    padding-left: 7%;
    padding-right: 0; }
  .footer-links-section {
    border-bottom: 1px solid #042003; }
  .footer-links-section:first-child {
    border-top: 1px solid #042003; }
  .social-icons {
    margin: 2em; }
  .footer-colophon {
    flex-direction: column;
    align-items: center; }
  .footer-contact-info .segment::before {
    display: none; }
  .footer-contact-info .segment:nth-child(2)::before {
    display: block; }
  .footer-contact-info .segment:nth-child(2)::after {
    content: "\007C";
    position: absolute;
    right: calc(-.37em + -1vw);
    font-weight: bolder;
    opacity: .5; }
  .footer-contact-info .seg-group {
    margin: 0.5em auto; }
    .footer-contact-info .seg-group .footer-address {
      display: block; }
  .footer-contact-info .seg-group:first-child {
    margin-bottom: 0; }
  .footer-contact-info .seg-group:nth-child(2) {
    margin-top: 0; }
  .headline-small {
    line-height: 30px; }
  .footer.container {
    width: 100%;
    margin-bottom: 3.5em; }
  .footer-rsp > hr {
    margin-bottom: 3em; } }

.footer-contact-info {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif; }

@media (min-width: 992px) {
  .footer-contact-info {
    text-align: center; }
  .footer-colophon {
    flex-direction: row;
    justify-content: center; }
  .footer-contact-info .segment.first-segment::before {
    display: none; }
  .footer.container {
    width: 100%;
    margin-bottom: 2.5em; } }

@media screen and (min-width: 480px) {
  .footer-section-heading {
    color: #fff;
    margin-bottom: 0; }
  .footer-links {
    opacity: 1; }
    .footer-links li {
      margin: 1rem 0; }
  .footer-links-section:first-child {
    margin-top: 1.5em; }
  .veracity-links {
    flex-wrap: wrap; }
  .footer-seals {
    justify-content: flex-start;
    margin-top: 2em; }
  .footer-toll-free {
    display: flex;
    justify-content: center; }
    .footer-toll-free .footer-tf-number, .footer-toll-free .footer-mailto {
      display: inline; } }

.footer .headline-small {
  color: #fff;
  padding: 0.5em 0; }

.footer .body-txt-small, .footer ul.vertical-menu > li, .footer .gift-info-detail {
  color: #fff;
  padding: 0.5em 0; }

.footer li {
  padding: 0; }

.email-icon {
  height: 17px;
  width: 22px;
  vertical-align: middle;
  margin-right: 0.3em; }

.call-icon {
  height: 21px;
  width: 21px;
  vertical-align: middle;
  margin-right: 0.3em; }

hr.bs-line {
  border-color: #425641; }

.bs-btn--secondary.reversed {
  display: block;
  margin: 0 25px;
  display: flex;
  justify-content: center;
  align-items: center; }

.pd-meta-and-reviews {
  margin-top: 45px; }

.txt-care a, .txt-info a, .long-desc a {
  color: #356c25 !important;
  font-weight: bold;
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .txt-care a, .txt-info a, .long-desc a {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .txt-care a, .txt-info a, .long-desc a {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .txt-care a, .txt-info a, .long-desc a {
      font-size: 22px;
      line-height: 34px; } }

.pd-details-section {
  margin-bottom: 3em;
  display: flex;
  justify-content: space-between;
  column-gap: 75px; }
  @media screen and (max-width: 1150px) {
    .pd-details-section {
      flex-direction: column; } }
  @media screen and (min-width: 1151px) and (max-width: 1300px) {
    .pd-details-section {
      column-gap: 35px; } }

@media screen and (min-width: 1151px) and (max-width: 1300px) {
  .pd-thumbs {
    display: none; } }

.pd-thumb-wrap {
  margin-bottom: 7px;
  max-width: 85px; }
  .pd-thumb-wrap:focus {
    border-color: #999;
    border-width: 2px; }

.pd-detail-image {
  position: relative;
  width: 100%; }
  @media screen and (min-width: 993px) {
    .pd-detail-image {
      float: left; } }

.pd-img-wrap {
  display: block;
  position: relative; }

.pd-zoom-icon {
  position: absolute;
  right: 13px;
  bottom: 15px;
  fill: #fff;
  z-index: 1;
  transform: scaleX(-1);
  width: 40px;
  height: 40px; }
  .pd-zoom-wrap:hover .pd-zoom-icon {
    opacity: 1; }
  .zoom-ready .pd-zoom-icon {
    opacity: 1; }
  @media (prefers-reduced-motion) {
    .pd-zoom-icon {
      transition: none; } }

.pd-images-wrap {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 30px; }
  .pd-images-wrap .pd-large-images.slick-initialized .pd-wide-image.slick-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

.pd-images {
  display: grid; }
  @media screen and (min-width: 1151px) and (max-width: 1300px), (max-width: 767px) {
    .pd-images {
      grid-template-columns: minmax(0px, 100%); } }
  @media screen and (min-width: 1301px), (min-width: 768px) and (max-width: 1150px) {
    .pd-images {
      grid-template-columns: 85px minmax(0px, 100%);
      column-gap: 30px; } }

.pd-wide-image {
  background-color: #f0eeee; }

.pd-large-images li.pd-large-image:nth-child(1n+2) {
  display: none; }

.pd-large-images.slick-initialized .pd-large-image.slick-slide {
  display: block; }

.pd-large-images .pd-large-image .figure-caption {
  position: absolute;
  padding: 6px 6px;
  background-color: gray;
  opacity: 0.8;
  color: white;
  font-size: 1.5rem;
  width: 100%;
  bottom: 0;
  margin: 0; }

.pd-large-images .pd-large-image .caption-title {
  display: block;
  font-weight: bold;
  font-size: 1.75rem; }

.pd-large-images .pd-large-image .caption-descr {
  font-size: 1.5rem; }

.pd-detail-image-img {
  height: auto;
  width: 100%; }

@media screen and (max-width: 767px) {
  .pd-detail-image-img.desktop-view, .desktop-view {
    display: none; } }

@media screen and (min-width: 768px) {
  .pd-detail-image-img.desktop-view, .desktop-view {
    display: block; } }

@media screen and (max-width: 767px) {
  .pd-detail-image-img.mobile-view, .mobile-view {
    display: block; } }

@media screen and (min-width: 768px) {
  .pd-detail-image-img.mobile-view, .mobile-view {
    display: none; } }

.ribbon-edge, .ribbon-txt {
  display: block;
  float: left; }

.ribbon-edge {
  margin-right: -1px;
  fill: #777; }
  .product-badge--new .ribbon-edge {
    fill: #f1d311; }
  .product-badge--sale .ribbon-edge {
    fill: #da4e2b; }
  .product-badge--trial .ribbon-edge {
    fill: #ffcc00; }
  .product-badge--ondeck .ribbon-edge {
    fill: #ff0099; }
  .product-badge--droped .ribbon-edge {
    fill: #85261a; }
  .product-badge--dropna .ribbon-edge {
    fill: #ff9933; }
  .product-badge--dropdied .ribbon-edge {
    fill: #663300; }
  .product-badge--dropls .ribbon-edge {
    fill: #cc6600; }
  .product-badge--dropnl .ribbon-edge {
    fill: #993300; }
  .product-badge--dropbv .ribbon-edge {
    fill: #ffcc99; }
  .product-badge--stkhold .ribbon-edge {
    fill: #85261a; }
  .product-badge--futurenew .ribbon-edge {
    fill: #3a7c9c; }

.ribbon-txt {
  background: #777;
  padding: 0 15px 0 11px;
  height: 20px; }
  .product-badge--new .ribbon-txt {
    background-color: #f1d311;
    color: #000000; }
  .product-badge--sale .ribbon-txt {
    background-color: #da4e2b; }
  .product-badge--trial .ribbon-txt {
    background-color: #ffcc00;
    color: #fff; }
  .product-badge--ondeck .ribbon-txt {
    background-color: #ff0099;
    color: #fff; }
  .product-badge--dropna .ribbon-txt {
    background-color: #ff9933; }
  .product-badge--dropdied .ribbon-txt {
    background-color: #663300; }
  .product-badge--dropls .ribbon-txt {
    background-color: #cc6600; }
  .product-badge--dropnl .ribbon-txt {
    background-color: #993300; }
  .product-badge--dropbv .ribbon-txt {
    background-color: #ffcc99; }
  .product-badge--stkhold .ribbon-txt {
    background-color: #85261a; }
  .product-badge--futurenew .ribbon-txt {
    background-color: #3a7c9c;
    color: #fff; }

.product-badge {
  float: right;
  height: 20px;
  z-index: 1;
  line-height: 20px;
  color: #fff;
  text-transform: uppercase;
  margin: 0; }

.product-badge--new, .product-badge--sale, .product-badge--top, .product-badge--trial, .product-badge--ondeck, .product-badge--dropna, .product-badge--dropdied, .product-badge--dropls, .product-badge--dropnl, .product-badge--dropbv, .product-badge--stkhold {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 900; }

.product-badge--futurenew {
  margin-right: -13px;
  margin-left: -13px;
  margin-top: -26px; }

.pd-title-head {
  margin-top: 0;
  margin-bottom: 0; }

.pd-head-species {
  font-size: 0.64em; }

.series-and-name {
  margin-top: 10px; }

.pd-prices:has(> *) {
  margin: 20px 0 30px 0; }

.price--slashed {
  text-decoration: line-through; }

.pd-item-price {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .pd-item-price {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .pd-item-price {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .pd-item-price {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .pd-item-price {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    .pd-item-price {
      font-size: 20px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .pd-item-price {
      font-size: 24px; } }
  @media screen and (min-width: 992px) {
    .pd-item-price {
      font-size: 28px; } }
  .pd-item-price .sale {
    color: #da4e2b; }
  .pd-item-price.price--slashed {
    font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
    color: #042003;
    color: #757575;
    text-decoration: line-through; }
    @media screen and (max-width: 767px) {
      .pd-item-price.price--slashed {
        font-size: 20px;
        line-height: 30px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .pd-item-price.price--slashed {
        font-size: 22px;
        line-height: 34px; } }
    @media (min-width: 992px) {
      .pd-item-price.price--slashed {
        font-size: 22px;
        line-height: 34px; } }
    @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
      .pd-item-price.price--slashed {
        font-size: 16px; } }
    @media screen and (min-width: 992px) {
      .pd-item-price.price--slashed {
        font-size: 18px; } }
  .pd-item-price.price--sale {
    color: #da4e2b; }

.pd-qty-price.price--sale {
  color: #cc3921; }

.pd-three-plus {
  color: #356c25;
  margin-top: 5px;
  margin-bottom: 30px; }

@media screen and (max-width: 425px) {
  #mv_order_quantity {
    padding: 12px 0px; } }

.pd-add-to-cart {
  margin: 1em 0;
  display: flex; }
  @media screen and (max-width: 425px) {
    .pd-add-to-cart {
      column-gap: 10px; } }
  @media screen and (min-width: 426px) {
    .pd-add-to-cart {
      column-gap: 20px; } }
  .pd-add-to-cart span.input-container {
    display: inline-flex;
    align-items: center;
    outline: 1px solid #757575;
    border-radius: 3px; }
    @media screen and (max-width: 767px) {
      .pd-add-to-cart span.input-container {
        height: 50px; } }
    @media screen and (min-width: 768px) {
      .pd-add-to-cart span.input-container {
        height: 55px; } }
    .pd-add-to-cart span.input-container .icon {
      width: 15px;
      height: 15px; }
    .pd-add-to-cart span.input-container > .left, .pd-add-to-cart span.input-container > .right {
      text-align: center;
      cursor: pointer; }
      @media screen and (max-width: 767px) {
        .pd-add-to-cart span.input-container > .left, .pd-add-to-cart span.input-container > .right {
          width: 50px;
          height: 50px;
          line-height: 50px; } }
      @media screen and (min-width: 768px) {
        .pd-add-to-cart span.input-container > .left, .pd-add-to-cart span.input-container > .right {
          width: 48px;
          height: 55px;
          line-height: 55px; } }
    .pd-add-to-cart span.input-container > input {
      width: 55px;
      text-align: center;
      border: none; }

.archive-message {
  margin-top: 40px;
  margin-bottom: 40px;
  color: #da4e2b; }

a.pop-cart-btn {
  padding-right: 2.5em;
  padding-left: 2.5em; }

.pd-cart-btn {
  width: max-content; }
  @media screen and (max-width: 767px) {
    .pd-cart-btn {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      min-width: auto;
      padding: 0; } }
  @media screen and (min-width: 768px) {
    .pd-cart-btn {
      height: 55px; } }

.pd-guarantee {
  font-family: "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 700;
  font-size: 1.35em; }

.pd-meta-nav,
.pd-meta-sections {
  margin-bottom: 2em; }

.pd-meta-nav > li:first-child > a.pd-meta-nav-link {
  margin-left: 0; }

.pd-save-notes-header {
  display: flex;
  justify-content: space-between; }

.save-notes-msg {
  font-size: 0.9em; }

.nav-tabs > li > a.pd-meta-nav-link {
  border: none 0;
  border-bottom: 4px solid transparent;
  border-radius: 0;
  color: #333;
  padding-left: 0;
  padding-right: 0;
  margin: 0 15px; }
  .nav-tabs > li > a.pd-meta-nav-link:hover, .nav-tabs > li > a.pd-meta-nav-link:active, .nav-tabs > li > a.pd-meta-nav-link:focus {
    color: #3a7c9c;
    background-color: transparent;
    border: none 0;
    border-bottom: 4px solid transparent; }

.nav-tabs > li.active > .pd-meta-nav-link {
  border-bottom-color: #3a7c9c;
  color: #3a7c9c; }
  .nav-tabs > li.active > .pd-meta-nav-link:hover, .nav-tabs > li.active > .pd-meta-nav-link:focus {
    border: none 0;
    border-bottom: 4px solid #3a7c9c;
    color: #3a7c9c; }

.pd-desc {
  margin-bottom: 1em; }

.pd-logo {
  margin-right: 1.5em; }

@media screen and (min-width: 992px) {
  .pd-specs-head {
    margin-bottom: 20px; } }

@media screen and (max-width: 991px) {
  .pd-specs-head {
    margin-top: 13px; } }

.pd-specs-head.right {
  margin-bottom: 5px; }
  @media screen and (max-width: 991px) {
    .pd-specs-head.right {
      margin-top: 45px; } }

.pd-specs-h {
  font-weight: 600;
  padding-left: 0; }

.pd-specs-h,
.pd-specs-d {
  padding: 0.5em;
  background: url(/rsp/css-images/dotted-rule.png) #fff repeat-x bottom left; }

.pd-specs-table tr:last-child .pd-specs-h, .pd-specs-table tr:last-child .pd-specs-d {
  background-image: none; }

.product-tiles {
  border-top: 1px solid #ccc;
  padding-top: 2em;
  margin-bottom: 2em; }

@media screen and (min-width: 769px) and (max-width: 992px) {
  .product-tiles--recently-viewed .product-tile-wrap:nth-child(1n+4),
  .product-tiles--recommended .product-tile-wrap:nth-child(1n+4) {
    display: none; } }

.product-tile-head {
  text-align: center;
  margin-bottom: 1em; }

.product-tile {
  margin-bottom: 30px; }

.product-tile-link {
  display: block;
  color: #3a7c9c; }

.slick-dots {
  bottom: -34px;
  display: none; }

.slick-dots li button:before {
  font-size: 43px; }

.slick-dots li.slick-active button:before,
.slick-dots li button:hover:before {
  color: #333; }

.pd-item-out-of-stock {
  color: #cc3921;
  font-weight: 700; }

@media screen and (min-width: 768px) and (max-width: 992px) {
  .planting-sites {
    margin-bottom: 50px;
    margin-top: 50px; } }

@media screen and (max-width: 767px) {
  .planting-sites {
    margin-bottom: 50px;
    margin-top: 50px; } }

@media screen and (min-width: 992px) {
  .planting-sites {
    margin-top: 45px; } }

.features {
  margin-left: 18px; }

.icon-feature {
  width: 35px;
  height: 35px; }

.pd-feature {
  margin-bottom: 15px;
  margin-top: 0; }

.txt-info, .txt-care {
  margin-top: 0;
  margin-bottom: 0; }

@media screen and (min-width: 992px) {
  .pstn-info {
    height: auto; } }

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .pstn-care {
    position: relative;
    grid-row: 3;
    grid-column: 2;
    grid-row-end: 2;
    margin-top: 40px; } }

@media screen and (min-width: 1200px) {
  .pstn-care {
    position: relative;
    grid-row: 3;
    grid-column: 2;
    margin-top: 40px; } }

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .secondary-section {
    display: grid;
    grid-template-columns: 0.9fr 2fr;
    grid-column-gap: 67px; } }

@media screen and (min-width: 1200px) {
  .secondary-section {
    display: grid;
    grid-template-columns: 0.9fr 2fr; } }

.pd-details-section .pd-item-in-cart {
  margin-bottom: 1.4em; }
  .pd-details-section .pd-item-in-cart a {
    color: #516f30;
    font-weight: 700; }

.pd-display-options legend {
  font-weight: 700;
  margin-bottom: 0.3em;
  border: none 0;
  font-size: 1.6rem; }

.plants-included-table th, .plants-included-table td {
  padding: 0 5px 10px 0; }

.pd-details .body-txt-small, .pd-details ul.vertical-menu > li, .pd-details .gift-info-detail {
  font-weight: bold !important;
  margin-top: 35px;
  margin-bottom: 40px; }

.addtolist-wrapper {
  margin-top: 35px; }

.js-addtolist {
  font-weight: bold; }
  .js-addtolist svg {
    width: 28px;
    height: 28px;
    vertical-align: sub; }

div.slick-active {
  border: 3px solid #fff !important;
  padding: 2px; }

div.slick-current {
  border: 3px solid #356c25 !important;
  padding: 2px; }

.pd-thumb-img {
  width: 100%; }

.pd-thumb-wrap.thumbnail-more.slick-slide {
  max-width: 85px;
  height: 100px;
  border: 1px solid #757575 !important; }

.pd-thumb-img.thumbnail-more {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  font-size: 20px; }

.pd-images-wrap:has(.slick-dots > li:only-child) {
  margin-bottom: 0px; }

.long-desc {
  letter-spacing: 0.2px; }

.icon.help-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: auto 10px; }

.h3.short-description, .pd-desc-logo-pair {
  margin-top: 40px; }

.pd-details--wrapper {
  flex-grow: 1; }
  @media screen and (min-width: 1151px) {
    .pd-details--wrapper {
      max-width: 510px; } }
  @media screen and (max-width: 767px) {
    .pd-details--wrapper {
      margin-top: 30px; } }

.plant-details {
  background: #e1f7f7; }
  @media screen and (max-width: 991px) {
    .plant-details.holiday {
      margin-top: 0px; } }
  @media screen and (min-width: 992px) {
    .plant-details.holiday {
      margin-top: 100px; } }
  .plant-details.full-bleed {
    box-shadow: 0 0 0 100vmax #e1f7f7;
    clip-path: inset(0 -100vmax); }
  .plant-details > .container > .wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-top: 45px;
    padding-bottom: 60px; }
    @media screen and (min-width: 992px) and (max-width: 1399px) {
      .plant-details > .container > .wrapper {
        height: 480px; } }
    @media screen and (min-width: 1200px) {
      .plant-details > .container > .wrapper {
        height: 480px; } }
    .plant-details > .container > .wrapper > .detail-row {
      border-bottom: 3px solid white;
      display: flex;
      align-items: center;
      flex: 1 1 60px;
      margin: 0;
      max-height: 60px; }
      @media screen and (min-width: 992px) and (max-width: 1399px) {
        .plant-details > .container > .wrapper > .detail-row {
          width: calc(50% - 9px); } }
      @media screen and (min-width: 1200px) {
        .plant-details > .container > .wrapper > .detail-row {
          width: calc(50% - 15px); } }
      .plant-details > .container > .wrapper > .detail-row > .property {
        font-weight: bold; }
        .plant-details > .container > .wrapper > .detail-row > .property .icon.help-icon {
          margin-left: 5px;
          margin-right: 0; }
      .plant-details > .container > .wrapper > .detail-row > .detail {
        margin-left: auto;
        width: 60%; }
    @media screen and (min-width: 992px) {
      .plant-details > .container > .wrapper > .detail-row:nth-child(-n+8) {
        margin-right: 30px; }
      .plant-details > .container > .wrapper > .detail-row:nth-child(6) {
        border-bottom: none; } }
    .plant-details > .container > .wrapper > .detail-row:nth-child(12) {
      border-bottom: none; }

.pd-details-section {
  margin-top: 55px; }
  @media screen and (max-width: 1200px) {
    .pd-details-section {
      margin-top: 0px; } }

#flypage-timeframe {
  color: #042003; }

.viewer-back-link {
  color: white; }

a:focus, a:hover {
  color: inherit;
  text-decoration: none; }

li.fp-viewer-thumb-box > a:focus, li.fp-viewer-thumb-box > a:hover {
  border: 3px solid #356c25;
  padding: 2px !important;
  background-color: white !important;
  margin-bottom: 5px; }

.pre-planned {
  grid-column-start: 2;
  margin-top: 40px; }
  .pre-planned .body-txt-link {
    text-transform: none; }
  .pre-planned .body-txt-input-small {
    font-weight: bold; }

.marketing-promo {
  background: #fff;
  border: 10px solid #e1f7f7;
  margin: 70px 0 35px 0;
  display: flex;
  flex-direction: row;
  padding: 30px; }
  @media screen and (max-width: 767px) {
    .marketing-promo {
      flex-direction: column;
      align-items: center; } }

.pd-logo-fig {
  max-width: 164px;
  margin-right: 25px; }

.marketing-promo-text h3 {
  margin-bottom: 5px; }

.bulb-package-item {
  padding-top: 10px;
  display: flex;
  align-items: baseline; }

.bulb-package-item:not(:last-child) {
  border-bottom: 1px solid #bfbfbf; }

.bulb-package-label {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between; }

.bulb-package-title {
  flex: auto; }
  @media screen and (min-width: 1301px) {
    .bulb-package-title {
      max-width: 69%;
      font-size: 20px; } }
  @media screen and (max-width: 1300px) {
    .bulb-package-title {
      max-width: 60%; } }

.bulb-package-label::before {
  align-self: auto; }

@media screen and (min-width: 769px) {
  .bulb-package-label::after {
    top: 14px !important; } }

@media screen and (max-width: 768px) {
  .bulb-package-label::after {
    top: 12px !important; } }

.bulb-package-label .bulb-price .pd-item-price {
  font-size: 22px !important;
  line-height: 34px !important; }

.bulb-package-label .bulb-price .pd-item-price.price--slashed {
  font-size: 18px !important;
  line-height: 34px !important;
  font-weight: normal; }

.bulb-package-label .bulb-price .pd-item-price.item-price--sale {
  font-size: 22px !important;
  line-height: 34px !important;
  color: #da4e2b;
  font-weight: normal; }

.bulb-package-label .bulb-price .muted.pd-three-plus {
  display: none; }

@media screen and (min-width: 1200px) {
  .js-cart-form.holiday-bulbs .plant-details .wrapper.holiday {
    height: 300px !important; } }

@media screen and (min-width: 1450px) {
  .js-cart-form.holiday-bulbs .plant-details .wrapper.holiday > .detail-row:nth-child(n+4) {
    border-bottom: none; } }

@media screen and (min-width: 992px) and (max-width: 1399px) {
  .js-cart-form.holiday-bulbs .plant-details .wrapper.holiday {
    height: 300px; } }

@media screen and (min-width: 1400px) {
  .js-cart-form.holiday-bulbs .plant-details .wrapper.holiday {
    flex-direction: row;
    height: 225px !important; } }

@media screen and (min-width: 992px) and (max-width: 1399px) {
  .js-cart-form.holiday-bulbs .detail-row {
    width: calc(50% - 15px) !important; } }

@media screen and (min-width: 1200px) {
  .js-cart-form.holiday-bulbs .detail-row {
    flex: 1 1 60px !important; } }

@media screen and (min-width: 1400px) {
  .js-cart-form.holiday-bulbs .detail-row {
    min-width: calc(33% - 26px) !important;
    flex: 0 1 47% !important; } }

@media screen and (min-width: 1450px) {
  .js-cart-form.holiday-bulbs .detail-row {
    min-width: calc(33% - 26px) !important;
    flex: 0 1 60px !important; } }

.js-cart-form.holiday-bulbs .detail-row .detail {
  width: 70% !important; }

.pd-meta-and-reviews.holiday-bulbs .secondary-section {
  grid-template-columns: 1fr !important; }

.buying-options {
  display: flex;
  flex-direction: column;
  gap: 20px; }

.buying-option {
  display: flex;
  gap: 30px; }
  .buying-option > .img {
    height: fit-content; }
    @media screen and (min-width: 768px) {
      .buying-option > .img {
        max-width: 150px; } }
    @media screen and (max-width: 767px) {
      .buying-option > .img {
        max-width: 130px; } }
  .buying-option .content {
    text-align: left; }

.content-container {
  max-width: 860px;
  margin: 0 auto; }

.homeoutside-embed-wrapper {
  margin-top: 3em;
  margin-bottom: 3em; }

.garden-3d {
  width: 100%; }
  @media screen and (max-width: 575px) {
    .garden-3d {
      max-height: 750px; } }
  @media screen and (min-width: 576px) and (max-width: 991px) {
    .garden-3d {
      max-height: 300px; } }
  @media screen and (min-width: 992px) {
    .garden-3d {
      max-height: 450px; } }

.container.recently-viewed {
  margin-bottom: 40px; }
  .container.recently-viewed > .carousel-root {
    margin-top: 15px; }

.container.carousel-might-like > .carousel-root {
  margin-top: 55px; }

.pd-sets-help.qty-discount {
  margin-left: 10px; }

@media screen and (max-width: 767px) {
  .qty-price-intro {
    display: block;
    margin-bottom: 5px; } }

@media screen and (min-width: 768px) {
  .qty-price-intro {
    margin-right: 5px; } }

.qty-price-separator {
  margin: 0 8px;
  color: #9E9E9E; }

.pd-pricing .address-selector .shipto {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .pd-pricing .address-selector .shipto {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    .pd-pricing .address-selector .shipto {
      font-size: 22px;
      line-height: 30px; } }

.group-discount-msg {
  margin-bottom: 30px; }
  .group-discount-msg div.msg-wrapper {
    background: #EEF7EB;
    border-radius: 4px;
    padding: 15px;
    padding-left: 20px;
    display: grid;
    grid-template-columns: 0fr 1fr;
    align-items: center; }
    .group-discount-msg div.msg-wrapper img {
      margin-right: 12px; }
    .group-discount-msg div.msg-wrapper strong {
      display: block;
      font-size: 20px;
      line-height: 26px;
      color: #356c25; }
      @media screen and (max-width: 767px) {
        .group-discount-msg div.msg-wrapper strong {
          font-size: 16px;
          line-height: 22px; } }

.cart-heading {
  position: relative;
  margin-bottom: 1.5em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid #ccc; }
  @media screen and (min-width: 767px) {
    .cart-heading .cart-head-checkout-btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 12em; } }

.cart-shiptime-heading,
.co-beta h3.cart-shiptime-heading {
  margin-top: 45px;
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003;
  padding-bottom: 0.5em;
  margin-bottom: 1em; }
  @media screen and (max-width: 767px) {
    .cart-shiptime-heading,
    .co-beta h3.cart-shiptime-heading {
      font-size: 24px;
      line-height: 32px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .cart-shiptime-heading,
    .co-beta h3.cart-shiptime-heading {
      font-size: 30px;
      line-height: 44px; } }
  @media (min-width: 992px) {
    .cart-shiptime-heading,
    .co-beta h3.cart-shiptime-heading {
      font-size: 30px;
      line-height: 44px; } }
  .co-beta .cart-shiptime-heading, .co-beta
  .co-beta h3.cart-shiptime-heading {
    margin-bottom: 0.5em; }
  .cart-body .cart-shiptime-heading:nth-child(1n+2), .cart-body
  .co-beta h3.cart-shiptime-heading:nth-child(1n+2) {
    border-top: 1px solid #ddd;
    padding-top: 1em; }

.cart-body .cart-item {
  border-bottom: none; }

.item-qty-input {
  width: 3.5em;
  border: 1px solid #ccc;
  display: inline-block; }

.item-figure {
  padding-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center; }

@media screen and (min-width: 767px) {
  .item-pricing {
    text-align: right; } }

@media screen and (max-width: 766px) {
  .item-pricing {
    margin-top: 10px; } }

.item-price {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #042003;
  font-weight: 700; }
  @media screen and (max-width: 767px) {
    .item-price {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .item-price {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .item-price {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .item-price {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    .item-price {
      font-size: 20px; } }

.item-price--sale {
  color: #cc3921;
  font-weight: 700; }

.item-price--qty-sale-price {
  display: inline-block;
  margin-left: 0.3em; }

.cart-item .pd-three-plus,
.cart-item .muted {
  padding-right: 0; }

.pd-three-plus--qty-sale.muted {
  padding-left: 0; }

.item-actions {
  margin-top: 10px; }
  .item-actions a, .item-actions button {
    font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 900;
    color: #356c25;
    text-transform: uppercase;
    letter-spacing: 1px; }
    @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
      .item-actions a, .item-actions button {
        font-size: 15px; } }
    @media screen and (min-width: 992px) {
      .item-actions a, .item-actions button {
        font-size: 16px; } }
    .item-actions a:hover, .item-actions button:hover {
      color: #356c25; }
  .item-actions > span {
    margin: 0 5px; }
    @media screen and (max-width: 767px) {
      .item-actions > span {
        display: block;
        visibility: hidden;
        line-height: 0;
        margin-bottom: 5px; } }

.item-details-wrap {
  padding-left: 35px; }
  @media screen and (max-width: 420px) {
    .item-details-wrap {
      padding-left: 20px; } }

.cart-totals {
  width: 100%; }

.cart-totals,
.cart-actions-form,
.checkout-and-links {
  margin-bottom: 1em; }

.cart-totals-label,
.cart-totals-value {
  padding: 0.1em; }

.cart-totals-label {
  padding-left: 0;
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal; }
  @media screen and (max-width: 767px) {
    .cart-totals-label {
      font-size: 18px;
      line-height: 26px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .cart-totals-label {
      font-size: 20px;
      line-height: 28px; } }
  @media (min-width: 992px) {
    .cart-totals-label {
      font-size: 20px;
      line-height: 28px; } }

.cart-e-zip-label {
  display: block; }

.cart-totals-value {
  text-align: right;
  padding-right: 0;
  font-weight: 700;
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  color: #042003; }
  @media screen and (max-width: 767px) {
    .cart-totals-value {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .cart-totals-value {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .cart-totals-value {
      font-size: 22px;
      line-height: 34px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .cart-totals-value {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    .cart-totals-value {
      font-size: 20px; } }
  .cart-totals-value.cart-totals-savings {
    font-weight: 900;
    color: #356c25;
    padding-left: 0;
    font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 15px;
    padding-top: 0; }

.cart-totals-shipping_x .cart-totals-label,
.cart-totals-shipping_x .cart-totals-value {
  padding-bottom: 1.0em; }

.cart-totals-grand-totals .cart-totals-label,
.cart-totals-grand-totals .cart-totals-value {
  padding-top: 15px;
  border-top: 1px solid #ccc; }

.cart-totals-grand-total-label {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .cart-totals-grand-total-label {
      font-size: 18px; } }
  @media screen and (min-width: 992px) {
    .cart-totals-grand-total-label {
      font-size: 20px; } }

.cart-totals-grand-total {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #042003;
  font-weight: bold; }
  @media screen and (max-width: 767px) {
    .cart-totals-grand-total {
      font-size: 20px;
      line-height: 30px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .cart-totals-grand-total {
      font-size: 22px;
      line-height: 34px; } }
  @media (min-width: 992px) {
    .cart-totals-grand-total {
      font-size: 22px;
      line-height: 34px; } }

h2.cart-totals-caption,
.cart-totals-caption,
.cart-coupon-form-heading {
  margin-bottom: 0px; }

.cart-coupon-form-heading {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .cart-coupon-form-heading {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    .cart-coupon-form-heading {
      font-size: 22px;
      line-height: 30px; } }

h2.cart-totals-caption {
  margin-bottom: 0px; }

.cart-totals-caption {
  padding-top: 0; }

.last-item-before-total > .cart-totals-label, .last-item-before-total .cart-totals-value {
  padding-bottom: 20px; }

.cta-checkout-btn,
.cart-coupon-form-btn {
  width: 100%;
  text-align: center;
  min-width: auto; }

.checkout-ctas--bottom .co__divider-wrap {
  margin: 0 1em 0 0; }

.co__divider-wrap {
  width: 7em;
  margin: 0 auto; }

.co__divider {
  margin: 0.5em 0 1em 0;
  display: flex; }
  .co__divider::before, .co__divider::after {
    content: '';
    flex: 1;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 45.5% #ccc 45.5%, #ccc 52.5%, rgba(255, 255, 255, 0) 52.5%); }
  .co__divider::after {
    margin-left: 0.2em; }
  .co__divider::before {
    margin-right: 0.2em; }

.btn--co-continue {
  display: block;
  min-width: auto;
  margin-top: 1em;
  margin-bottom: 3em;
  width: 100%; }

@media screen and (min-width: 992px) {
  button.bs-btn.btn--co-continue[form=order-info-form].left-btn, button.btn--co-continue.btn-link[form=order-info-form].left-btn {
    max-width: 220px; } }

@media screen and (max-width: 767px) {
  .cart-main .rsp-btn {
    width: 100%; } }

.secure-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px; }

.cart-footer-main,
.cart-footer-secondary {
  border: 4px solid #6d913a;
  padding: 25px; }

.cart-ezip-input {
  height: 40px; }

.ezip-input-group {
  display: flex;
  margin-bottom: 2em; }

.rsp-btn--ezip-submit {
  border-radius: 0 3px 3px 0; }

.cart-shipping-est-form-wrap {
  display: none;
  border-top: none 0; }
  @media screen and (min-width: 768px) {
    .cart-shipping-est-form-wrap .cart-ezip-input {
      margin-right: -5px; } }
  @media screen and (max-width: 767px) {
    .cart-shipping-est-form-wrap .cart-ezip-input {
      margin-bottom: 1em; } }

.cart-footer input[type="text"] {
  border: 1px solid #ccc;
  padding: 0.5em 0.35em;
  appearance: none;
  border-radius: 0;
  height: 40px; }
  @media screen and (max-width: 767px) {
    .cart-footer input[type="text"] {
      margin-bottom: 1em; } }

.cart-footer-main {
  display: flex; }
  @media screen and (max-width: 767px) {
    .cart-footer-main {
      flex-direction: column; }
      .cart-footer-main .cta-checkout-btn {
        margin-bottom: 1em;
        margin-right: 0.5em; }
      .cart-footer-main .cart-coupon-input {
        width: 100%; }
      .cart-footer-main .cart-checkout-links {
        text-align: center; }
        .cart-footer-main .cart-checkout-links a {
          display: block;
          margin: 0.5em 0; }
          .cart-footer-main .cart-checkout-links a.edit-zip-link {
            display: inline; }
      .cart-footer-main .cart-shipping-est-form-wrap {
        width: 100%;
        margin-bottom: 2em; }
        .cart-footer-main .cart-shipping-est-form-wrap:after {
          content: '';
          height: 4px;
          display: block;
          margin: 2em -25px 0 -25px;
          background-color: #516f30; } }
  .cart-footer-main .cart-coupon-form-wrap {
    order: 4; }
  .cart-footer-main .cart-shipping-est-form-wrap {
    order: 3; }
  @media screen and (min-width: 768px) {
    .cart-footer-main {
      flex-wrap: wrap; }
      .cart-footer-main .cart-totals {
        width: 41%; }
      .cart-footer-main .cart-coupon-form-wrap {
        width: 58%; }
        .cart-footer-main .cart-coupon-form-wrap .cart-coupon-input {
          width: 16em; }
      .cart-footer-main .cart-totals {
        order: 2;
        margin-left: 8px; }
      .cart-footer-main .checkout-and-links {
        order: 3; }
      .cart-footer-main .cart-coupon-form-wrap {
        order: 1; }
      .cart-footer-main .cart-coupon-form {
        order: 1;
        display: inline-flex; }
        .cart-footer-main .cart-coupon-form .cart-coupon-input {
          margin-right: 0.5em; }
      .cart-footer-main .checkout-and-links {
        width: 100%;
        display: flex;
        justify-content: flex-end; }
        .cart-footer-main .checkout-and-links .cta-checkout-btn {
          order: 1;
          width: 12em; }
      .cart-footer-main .cart-shipping-est-form-wrap {
        order: 4;
        width: 100%; }
        .cart-footer-main .cart-shipping-est-form-wrap:before {
          content: '';
          display: block;
          height: 4px;
          margin: 1.5em -25px;
          background-color: #6d913a; }
      .cart-footer-main .cart-checkout-links {
        margin-bottom: 0;
        display: inline-flex;
        align-items: center;
        margin-right: 1em; }
        .cart-footer-main .cart-checkout-links > li {
          margin: 0 0.5em; } }

.sidebar-quick-order {
  padding-bottom: 1.5em;
  margin-bottom: 1em;
  border-bottom: 1px solid #ccc; }

.secure-checkout {
  margin-bottom: 2em; }

.secure-checkout-tf-link,
a.secure-checkout-tf-link {
  display: block;
  padding: 0.5em;
  padding-bottom: 0;
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .secure-checkout-tf-link,
    a.secure-checkout-tf-link {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    .secure-checkout-tf-link,
    a.secure-checkout-tf-link {
      font-size: 22px;
      line-height: 30px; } }

@media screen and (min-width: 993px) {
  .sidebar-quick-order .quick-order-sku-input,
  .sidebar-quick-order .quick-order-add-to-cart-btn {
    width: 100%;
    margin-bottom: 10px; }
  .sidebar-quick-order .quick-order-sku-input {
    margin-bottom: 0.5em;
    padding: 0.5em 0.25em; } }

.sidebar-toll-free,
.guarantee-tagline,
.guarantee-tagline > a {
  margin: 0.4em 0 0.15em 0;
  color: #042003 !important; }

.quick-order-multi-form {
  margin-bottom: 5em; }

.cart-quick-order {
  background: #f5f8fb;
  padding: 10px 15px; }
  .cart-quick-order .quick-order-sku-input {
    height: 40px;
    border: 1px solid #ccc;
    width: 8em; }
  .cart-quick-order .cart-quick-order-input-group {
    display: flex;
    margin: 1em 0; }
    .cart-quick-order .cart-quick-order-input-group :first-child {
      margin-right: 10px; }
    .cart-quick-order .cart-quick-order-input-group .rsp-btn {
      width: auto;
      padding-left: 1em;
      padding-right: 1em; }
      @media screen and (min-width: 420px) {
        .cart-quick-order .cart-quick-order-input-group .rsp-btn {
          padding-left: 2em;
          padding-right: 2em; } }

.gift-info-detail {
  font-weight: normal;
  line-height: normal; }

@media screen and (max-width: 767px) {
  .spacing {
    margin-bottom: 20px !important; } }

@media screen and (min-width: 768px) {
  .spacing {
    margin-bottom: 35px !important; } }

.gift-info-detail:nth-child(4), .gift-info-detail:nth-child(5) {
  margin-bottom: 15px; }

.gift-info-detail:nth-child(5) {
  margin-bottom: 25px; }

.gift-info-label {
  font-weight: 400; }

.gift-amount-input {
  text-align: right;
  display: inline-block;
  width: auto; }

.cart-item-gift-edit {
  display: none; }
  .cart-item-gift-edit.cart-item-gift-edit--gbox {
    margin-bottom: 1em; }

.gift-edit-details {
  appearance: none;
  border: none 0;
  background-color: #fff;
  padding: 0.2em 0.5em;
  margin-left: -0.5em; }

.gift-info-form-legend {
  font-size: 1.6rem;
  font-weight: 700;
  border: none 0;
  margin: 0 0 0.25em 0; }

.btn--gc-edit-toggle {
  appearance: none;
  border: none 0;
  background: #fff; }

.pd-gift-checkbox {
  margin-top: 30px; }

.custom-checkbox-input:checked ~ .custom-label--checkbox[for="js-gift-checkbox"]:after {
  top: 4px; }

.gift-amount-cents {
  display: inline-block;
  margin-right: 0.5em; }

.rsp-btn--save-gift-info {
  padding-right: 2.5em;
  padding-left: 2.5em; }

@media screen and (max-width: 426px) {
  .row.edit-gift-form {
    margin-top: 1em; } }

.gift-table td > b {
  display: block;
  text-align: right;
  padding-top: 0.6em; }

.gift-table textarea {
  border-color: #ddd; }

.rsp-btn--calculate {
  padding-left: 3em;
  padding-right: 3em; }

.cart-wishlist {
  margin-top: 4em; }

.pagination--wl > li:first-child > a,
.pagination--wl > li:first-child > span {
  border-radius: 0; }

.pagination--wl > li:last-child > a,
.pagination--wl > li:last-child > span {
  border-radius: 0; }

.pagination--wl > li > a,
.pagination--wl > li > span {
  color: #3a7c9c;
  padding: 8px 16px; }

.pagination--wl > .active > a,
.pagination--wl > .active > a:hover,
.pagination--wl > .active > a:active,
.pagination--wl > .active > a:focus,
.pagination--wl > .active > span {
  color: #333;
  background-color: #fff;
  border-color: #ddd;
  text-decoration: underline; }

.pagination--wl > li > a:focus {
  outline: none; }

@media screen and (max-width: 650px) {
  .pagination--wl .disabled {
    display: none; } }

.wishlist-pagination {
  text-align: center; }
  @media screen and (min-width: 420px) {
    .wishlist-pagination {
      float: right; } }

#wl-app[v-cloak] {
  display: none; }

.co-box {
  padding: 35px 35px 50px;
  background: #fff;
  border: 10px solid #e1f7f7; }
  @media screen and (max-width: 420px) {
    .co-box {
      padding: 17px 17px 25px; }
      .co-box.mb2 {
        margin-bottom: 0; } }

.paypal-wordmark {
  width: 59px; }

.btn--paypal-checkout {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  height: 40px; }
  .checkout-ctas--top .btn--paypal-checkout {
    width: 100%; }
  .btn--paypal-checkout:focus {
    box-shadow: -1px -1px 18px 1px rgba(0, 0, 0, 0.25) inset;
    outline: none; }
  .btn--paypal-checkout:hover, .btn--paypal-checkout:active {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.05); }

.checkout-ctas--top .apple-cta-btn,
.checkout-ctas--top .paypal-button-container,
.checkout-ctas--top .cta-checkout-btn {
  margin-top: 20px; }

.paypal-button-container {
  position: relative;
  z-index: 0;
  height: 55px; }

.paypal-button-text,
a .paypal-button-text {
  color: #333;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
  display: inline-flex;
  margin-right: 0.35em; }

.checkout-ctas--bottom {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  align-items: normal;
  margin-bottom: 3em; }
  @media screen and (max-width: 991px) {
    .checkout-ctas--bottom {
      justify-content: center; } }
  @media screen and (max-width: 767px) {
    .checkout-ctas--bottom {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 20px; } }
  .checkout-ctas--bottom .btn--paypal-checkout,
  .checkout-ctas--bottom .cta-checkout-btn,
  .checkout-ctas--bottom .paypal-button-container {
    width: 100%; }

.rsp.checkout-tmpl .page-wrap {
  flex: 1 0 auto; }

@media screen and (max-width: 991px) {
  .page--payment .co-alpha {
    order: -1; } }

.page--payment .btn--co-continue {
  margin-bottom: 1em; }

.footer-rsp--checkout {
  padding: 1.5em 0; }

.co {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 55px; }
  @media screen and (max-width: 420px) {
    .co {
      margin-top: 15px; } }

.co-gamma {
  margin-bottom: 3em; }
  @media screen and (max-width: 991px) {
    .co-gamma {
      order: 4; } }

.vert-divider {
  display: inline-flex;
  width: 1px;
  background: #ccc;
  height: 0.8em;
  margin: 0 0.5em; }

.nav-tabs.cart-tabs {
  margin-bottom: 2em; }

.nav.nav-tabs.cart-tabs > li > a {
  color: #333;
  border: 4px solid transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  padding-right: 0;
  padding-left: 0;
  margin-right: 1.5em; }

.nav-tabs.cart-tabs > li.active > a {
  color: #356c25;
  border-color: #356c25; }
  .nav-tabs.cart-tabs > li.active > a:hover, .nav-tabs.cart-tabs > li.active > a:active, .nav-tabs.cart-tabs > li.active > a:focus {
    color: #356c25;
    border: 4px solid #356c25;
    border-top: none;
    border-right: none;
    border-left: none; }

.nav-tabs.cart-tabs > li > a:hover {
  border-color: #ccc;
  background-color: transparent; }

.container--main-checkout {
  margin-top: 0; }

.dev-site-banner {
  transform-origin: top left;
  transform: rotate(-90deg);
  text-transform: uppercase;
  background: #c00;
  color: #fff;
  padding: 0.3em 3em;
  position: fixed;
  top: 100%;
  left: 0; }

@media screen and (min-width: 768px) {
  .sign-in-promo .rsp-btn {
    margin-top: -0.5em;
    margin-left: 1em; } }

.sign-in-promo {
  border-bottom: 1px solid #ccc;
  padding-bottom: 80px;
  margin-bottom: 30px; }
  @media screen and (max-width: 420px) {
    .sign-in-promo h2 {
      margin-top: 0 !important; } }

.co-status-msg {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .co-status-msg.initially-hidden {
    display: none; }
  .co-status-msg.co-status-msg--modal-login {
    margin-bottom: -15px; }
  .co-status-msg.error {
    color: #da4e2b;
    font-weight: 600;
    font-style: italic; }

.co-form .form-control {
  height: 40px; }

.co-form textarea.form-control {
  height: auto; }

.co-form input[type="checkbox"] {
  margin-top: 5px; }

.co-form .form-group {
  margin-bottom: 20px; }

.co-apply-btn {
  min-width: 12em; }

.checkbox.co-bottom-field {
  margin-bottom: 2.5em; }

.co-bottom-field {
  margin-bottom: 2.5em; }

.co-invalid-group label,
.invalid-group label {
  color: #cc3921; }

.co-invalid.form-control,
.invalid-group .form-control {
  border-color: #cc3921; }

.co-invalid-group p.co-invalid.error,
.invalid-group p.error {
  color: #da4e2b;
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  font-style: italic;
  font-size: 16px;
  font-weight: bold; }

.co-header {
  display: flex;
  flex-wrap: wrap; }

.co-header .bp-logo-link--checkout {
  display: flex; }

.co-header .co-return-to-cart-link-wrapper {
  display: flex;
  flex: 1 0 auto;
  justify-content: end;
  align-items: center; }

.co-return-to-cart-link,
a.co-return-to-cart-link {
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 0.85em; }

.co-step {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  font-size: 0.8em;
  margin-right: 35px; }
  .co-step.active {
    color: #3a7c9c; }
  .co-step > svg {
    width: 30px;
    height: 30px;
    margin-right: 10px; }

.co-step-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8d8f92;
  color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 0.5em; }
  .active .co-step-number {
    background-color: #3a7c9c; }
  .completed .co-step-number {
    background-color: #6d913a;
    font-weight: 700; }

.co-cart-item-count {
  color: #333;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal; }

.co-steps {
  display: flex;
  justify-content: space-between;
  margin-left: 2em; }

.co-step-label-segment {
  display: block; }
  @media screen and (min-width: 768px) {
    .co-step-label-segment {
      display: inline; } }

.shiptime-select__muted {
  font-size: 0.9em;
  color: #666666; }

.shiptime-select__radio-label {
  font-weight: 600;
  margin-bottom: 0; }

.shiptime-select__shipdate-input,
.shiptime-select__shipdate-picker {
  margin-bottom: 2em; }

.shiptime-select__calendar .cell.day.selected {
  background: #3a7c9c;
  color: #fff; }

.shiptime-select__picker-input {
  width: auto;
  min-width: 18em;
  padding: 6px 12px; }
  .co-form .shiptime-select__picker-input.form-control {
    height: 50px;
    border-radius: 3px; }
  .shiptime-select__picker-input[readonly] {
    background: #fff; }

.shiptime-select__est {
  font-weight: 600;
  color: #3a7c9c;
  margin-bottom: 0; }
  .shiptime-select__est.shiptime-select__est--alert {
    color: #cc3921;
    font-weight: normal; }

input#special-circumstance {
  margin-left: 0; }

label[for="special-circumstance"] {
  margin-bottom: 1em; }
  input#special-circumstance:checked + label[for="special-circumstance"] {
    margin-bottom: 1em; }

.shiptime-select__special-instructions {
  margin-bottom: calc(1em + 20px); }

.receipt__account-benefits {
  padding-left: 1.3em;
  margin-bottom: 3em; }

.receipt__copy, .receipt__account-benefits {
  font-family: "Merriweather", Georgia, "Times New Roman", Times, serif; }

.receipt__conf-num {
  margin-top: 40px; }

.receipt__divider {
  margin: 3em 0; }

.co-create-acct-form-msg {
  margin-bottom: -1em;
  color: #516f30; }
  .co-create-acct-form-msg.error {
    color: #cc3921; }

@media screen and (min-width: 992px) {
  .co-steps {
    margin: 0;
    margin-left: 50px;
    justify-content: space-around;
    order: 2; }
  .co-return-to-cart-link-wrapper {
    order: 3; }
  .bp-logo-link--checkout {
    order: 1; } }

@media screen and (max-width: 991px) {
  .co-steps {
    order: 3;
    width: 100%;
    margin-top: 20px;
    margin-left: 0; }
  .co-return-to-cart-link-wrapper {
    order: 2; }
  .bp-logo-link--checkout {
    order: 1; } }

@media screen and (max-width: 420px) {
  .co-steps {
    display: none; } }

@media screen and (max-width: 767px) {
  .co-cc-logos {
    margin: -13px 0 20px 0; } }

@media screen and (min-width: 768px) {
  .co-cc-logos {
    transform: translateY(2.6em); } }

.co-beta h3 {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .co-beta h3 {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    .co-beta h3 {
      font-size: 22px;
      line-height: 30px; } }

.shipping-options-group h3 {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .shipping-options-group h3 {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    .shipping-options-group h3 {
      font-size: 22px;
      line-height: 30px; } }

.co-address-details {
  margin-bottom: 1em; }

.co-address-details p {
  margin: 0;
  line-height: 1.5; }

.gc-message-container {
  margin-top: -10px; }

.gc-message-container .alert {
  padding: 5px 10px; }

.cart-alert {
  min-height: 55px;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: bold; }
  .cart-alert > ul {
    margin-bottom: 0; }

@media screen and (min-width: 992px) {
  .co {
    display: grid;
    grid-template-columns: 4.5fr minmax(355px, 1.5fr); }
  .co-heading {
    grid-row: 1;
    grid-column: 1; }
  .co-alpha {
    grid-column: 2;
    grid-row: 1 / 3;
    position: sticky;
    top: 2em;
    z-index: 2; }
  .co-beta {
    grid-row: 2 / 5;
    grid-column: 1;
    margin-bottom: 40px; }
  .co-beta .row {
    margin-left: 0;
    margin-right: 0; }
  .co-gamma {
    grid-row: 3;
    grid-column: 2; }
  .co-delta {
    grid-row: 5;
    grid-column: 1 / 3; }
  .checkout-ctas--bottom .btn--paypal-checkout {
    margin-bottom: 5px; }
  .co-step {
    font-size: 0.9em; } }

.guarantee-xtagline {
  font-family: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
  font-weight: 900;
  color: #042003; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .guarantee-xtagline {
      font-size: 20px;
      line-height: 26px; } }
  @media screen and (min-width: 992px) {
    .guarantee-xtagline {
      font-size: 22px;
      line-height: 30px; } }

.checkout-header {
  padding: 20px 0; }

.line-after-header {
  margin-top: 0;
  margin-bottom: 20px; }
  @media screen and (max-width: 420px) {
    .line-after-header {
      margin-bottom: 15px; } }

.sign-in-promo-cta {
  margin-top: 5px; }

.item-pricing > .body-txt--oldprice {
  margin-right: 5px; }

.finalize-cart-quantity {
  vertical-align: top; }
  @media screen and (max-width: 420px) {
    .finalize-cart-quantity {
      text-align: left; } }

.col-sm-3.no-padding.text-center.quantity {
  text-align: left; }

.cart-gift-submit {
  min-width: 100%; }

@media screen and (max-width: 767px) {
  .form-group.col-sm-6.no-padding.item-details-wrap, .col-sm-6.no-padding.item-details-wrap {
    padding-left: 0; } }

@media screen and (max-width: 991px) {
  .co-alpha > .co-box.mb2 {
    margin: 25px 0; } }

@media (min-width: 992px) {
  .js-order-summary {
    display: block;
    height: 100% !important; } }

@media (max-width: 991px) {
  .js-order-summary {
    display: none; }
  .co-alpha > .co-box:has(> div > h3[aria-expanded=false]) {
    padding: 0.5em 1em; } }

.cart-totals-caption[aria-expanded=true]::after {
  content: '-';
  font-weight: bolder;
  font-size: 35px;
  float: right; }

.cart-totals-caption[aria-expanded=false]::after {
  content: '+';
  font-weight: bolder;
  font-size: 35px;
  float: right; }

.item-buying-options.h4 {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 5px; }

.group-separator {
  margin-bottom: 45px; }

.cart-body > hr:nth-child(2) {
  display: none; }

.corner_holder {
  z-index: 1; }

.sr_corner_new,
.sr_corner_disc {
  z-index: 2; }

a {
  text-decoration-skip-ink: auto; }

.larger-input {
  border-radius: 3px; }

hr {
  border-color: #bfbfbf; }

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 0; }

.pagination > li.active a, .pagination > li.active span {
  background-color: #fff;
  text-decoration: underline;
  color: #333;
  border-color: #ddd; }
  .pagination > li.active a:hover, .pagination > li.active a:active, .pagination > li.active a:focus, .pagination > li.active span:hover, .pagination > li.active span:active, .pagination > li.active span:focus {
    background-color: #fff;
    border-color: #ddd;
    color: #333; }

.pagination > li a:active, .pagination > li a:focus, .pagination > li span:active, .pagination > li span:focus {
  outline-color: #ddd; }

@media (prefers-reduced-motion) {
  .img-thumbnail, .fade {
    transition: none; } }

.btn-lg {
  padding: 12px 32px; }

@media (max-width: 420px) {
  .container {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px; } }

@media (min-width: 421px) and (max-width: 767px) {
  .container {
    width: 100%;
    padding-right: 55px;
    padding-left: 55px; } }

@media (min-width: 768px) and (max-width: 1540px) {
  .container {
    width: 100%;
    padding-right: 120px;
    padding-left: 120px; } }

@media (min-width: 1541px) {
  .container {
    max-width: 1300px;
    padding-right: 0;
    padding-left: 0; } }

@media (max-width: 420px) {
  .container-header {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 421px) and (max-width: 767px) {
  .container-header {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px; } }

@media (min-width: 768px) and (max-width: 1540px) {
  .container-header {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px; } }

@media (min-width: 1541px) {
  .container-header {
    max-width: 1460px;
    padding-right: 0;
    padding-left: 0; } }

.container-fluid {
  padding-left: 0px;
  padding-right: 0px; }

a:hover, a:focus {
  text-decoration: none; }

.cta-promo-body {
  position: relative; }
  @media screen and (max-width: 991px) {
    .cta-promo-body {
      border: 10px solid #e1f7f7;
      padding-bottom: 3em; }
      .cta-promo-body.third-promo {
        margin-bottom: 5em; } }

.plant-finder-btn-wrap {
  font-size: 15px; }
  @media screen and (max-width: 991px) {
    .plant-finder-btn-wrap {
      flex-direction: column;
      min-height: 18em;
      padding: 15px 30px 15px 20px; }
      .plant-finder-btn-wrap > p {
        margin-bottom: 1em; }
      .plant-finder-btn-wrap.third-promo {
        min-height: 20em;
        padding: 15px 30px 15px 20px; }
        .plant-finder-btn-wrap.third-promo > p {
          line-height: 1.5;
          margin-bottom: 1em; } }
  @media screen and (min-width: 992px) {
    .plant-finder-btn-wrap {
      position: absolute;
      background: #fff;
      border: 10px solid #e1f7f7;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 38em;
      height: 23em;
      flex-direction: column;
      align-items: flex-start;
      padding: 1em 2em; }
      .plant-finder-btn-wrap > p {
        margin-bottom: 1em; }
      .plant-finder-btn-wrap.third-promo {
        left: 2em;
        width: 38em;
        height: 23em;
        padding: 1em 2em; }
        .plant-finder-btn-wrap.third-promo > p {
          line-height: 1.5;
          margin-bottom: 1em; } }
  @media screen and (min-width: 1200px) and (max-width: 1799px) {
    .plant-finder-btn-wrap {
      position: absolute;
      background: white;
      border: 10px solid #e1f7f7;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 42em;
      flex-direction: column;
      padding: 1em 3em; }
      .plant-finder-btn-wrap.third-promo {
        left: 4em;
        width: 40em;
        padding: 1em 3em; } }
  @media screen and (min-width: 1800px) {
    .plant-finder-btn-wrap {
      position: absolute;
      background: white;
      border: 10px solid #e1f7f7;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 42em;
      height: 26em;
      flex-direction: column;
      padding: 1em 4em; }
      .plant-finder-btn-wrap.third-promo {
        left: 10em;
        width: 45em;
        padding: 1em 5em; } }

.plant-finder-btn,
a.plant-finder-btn {
  position: relative;
  z-index: 1;
  padding-right: 2em;
  padding-left: 2em;
  width: 225px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .cta-promo-body .plant-finder-btn, .cta-promo-body
  a.plant-finder-btn {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    position: relative; }
  @media screen and (min-width: 768px) {
    .plant-finder-btn,
    a.plant-finder-btn {
      z-index: 0; } }

@media screen and (max-width: 991px) {
  .plant-finder-img {
    display: none; } }

@media screen and (min-width: 992px) {
  .plant-finder-img {
    width: 80%;
    height: 100%; } }

@media screen and (min-width: 992px) {
  .plant-finder-img--mobile {
    display: none; } }

.plant-third-promo-img {
  width: 100%;
  height: 100%; }
  @media screen and (max-width: 767px) {
    .plant-third-promo-img {
      display: none !important; } }

.plant-third-promo-img-mobile {
  width: 100%;
  height: 100%; }
  @media screen and (min-width: 768px) {
    .plant-third-promo-img-mobile {
      display: none !important; } }

.plantable-promo {
  display: flex;
  background-color: #e1f7f7; }
  @media screen and (max-width: 767px) {
    .plantable-promo {
      margin: auto 0;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-end;
      padding: 2em 3em 4em 3em; }
      .plantable-promo--text {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly; }
        .plantable-promo--text > .body-txt-medium, .faq_list .plantable-promo--text > li, .faq_list li .plantable-promo--text > a, .faq_list_a li .plantable-promo--text > a, .faq_list_a li .plantable-promo--text > b, .faq_list_a li .plantable-promo--text > p, .community-article .plantable-promo--text > p, .bs-faq ul.plantable-promo--text > li {
          line-height: 1.5;
          margin-bottom: 1.3em; }
      .plantable-promo--image {
        width: 75%;
        height: 100%;
        scale: 1.7;
        margin-top: 5em;
        display: none; }
        .plantable-promo--image--mobile {
          display: block; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .plantable-promo {
      margin: auto 0;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-end;
      padding: 2em 3em; }
      .plantable-promo--text {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly; }
        .plantable-promo--text > .body-txt-medium, .faq_list .plantable-promo--text > li, .faq_list li .plantable-promo--text > a, .faq_list_a li .plantable-promo--text > a, .faq_list_a li .plantable-promo--text > b, .faq_list_a li .plantable-promo--text > p, .community-article .plantable-promo--text > p, .bs-faq ul.plantable-promo--text > li {
          line-height: 1.5;
          padding-right: 3em;
          margin-bottom: 1.3em;
          margin-top: 1em; }
      .plantable-promo--image {
        width: 50%;
        height: 100%;
        scale: 1.3; }
        .plantable-promo--image--mobile {
          display: none; } }
  @media screen and (min-width: 1200px) {
    .plantable-promo {
      margin: auto 0;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
      padding: 2em 3em 0 3em;
      min-height: 36em; }
      .plantable-promo--text {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        max-width: 45em;
        height: 26em; }
        .plantable-promo--text > .body-txt-medium, .faq_list .plantable-promo--text > li, .faq_list li .plantable-promo--text > a, .faq_list_a li .plantable-promo--text > a, .faq_list_a li .plantable-promo--text > b, .faq_list_a li .plantable-promo--text > p, .community-article .plantable-promo--text > p, .bs-faq ul.plantable-promo--text > li {
          line-height: 1.5;
          padding-right: 3em;
          margin-bottom: 1.3em; }
      .plantable-promo--image {
        width: 40%;
        height: 100%;
        scale: 1.25;
        translate: 0 -10px; }
        .plantable-promo--image--mobile {
          display: none; } }

.plantable-promo--wrapper {
  margin-top: 5em;
  margin-bottom: 3em; }
  @media screen and (min-width: 1200px) {
    .plantable-promo--wrapper {
      margin-right: auto;
      margin-left: auto; } }
  @media screen and (min-width: 1200px) and (max-width: 420px) {
    .plantable-promo--wrapper {
      width: 100%;
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (min-width: 1200px) and (min-width: 421px) and (max-width: 767px) {
    .plantable-promo--wrapper {
      width: 100%;
      padding-right: 55px;
      padding-left: 55px; } }
  @media screen and (min-width: 1200px) and (min-width: 768px) and (max-width: 1540px) {
    .plantable-promo--wrapper {
      width: 100%;
      padding-right: 120px;
      padding-left: 120px; } }
  @media screen and (min-width: 1200px) and (min-width: 1541px) {
    .plantable-promo--wrapper {
      max-width: 1300px;
      padding-right: 0;
      padding-left: 0; } }

@media screen and (max-width: 991px) {
  .third-promo--wrapper {
    margin-top: 5em;
    margin-bottom: 8em; } }
  @media screen and (max-width: 991px) and (max-width: 420px) {
    .third-promo--wrapper {
      width: 100%;
      padding-right: 25px;
      padding-left: 25px; } }
  @media screen and (max-width: 991px) and (min-width: 421px) and (max-width: 767px) {
    .third-promo--wrapper {
      width: 100%;
      padding-right: 55px;
      padding-left: 55px; } }
  @media screen and (max-width: 991px) and (min-width: 768px) and (max-width: 1540px) {
    .third-promo--wrapper {
      width: 100%;
      padding-right: 120px;
      padding-left: 120px; } }
  @media screen and (max-width: 991px) and (min-width: 1541px) {
    .third-promo--wrapper {
      max-width: 1300px;
      padding-right: 0;
      padding-left: 0; } }

.row--trustpilot {
  padding: 4em 0 7em; }
  @media screen and (max-width: 991px) {
    .row--trustpilot {
      display: none; } }

.hp-tile {
  background: #005387;
  text-align: center;
  margin-bottom: 30px;
  position: relative; }
  .hp-tile:after {
    content: '';
    display: block;
    padding-bottom: 90%; }
  .hp-tile.hp-tile--double .hp-tile-link::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%; }
  .hp-tile.hp-tile--single .hp-tile-link::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.45), transparent 50%); }
  .hp-tile.hp-tile--double .hp-tile-heading,
  .hp-tile.hp-tile--double .hp-tile-subhead {
    z-index: 1; }
  .hp-tile.hp-tile--double:after {
    padding-bottom: 50%; }
  @media screen and (min-width: 768px) {
    .hp-tile.hp-tile--double:after {
      padding-bottom: 42.8095%; } }

.hp-tile-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  text-shadow: 0 0.1em 0.1em rgba(0, 0, 0, 0.3), 0 0.1em 0.5em rgba(0, 0, 0, 0.2); }

.hp-tile-link {
  display: block;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff; }
  .hp-tile-link:hover, .hp-tile-link:active, .hp-tile-link:focus {
    text-decoration: none; }
  @media screen and (min-width: 768px) {
    .hp-tile-link.hp-tile-link--double {
      justify-content: center;
      align-items: center; } }

@media screen and (max-width: 767px) {
  .hp-tile--slim-padding:nth-child(2) {
    padding-right: 7px; }
  .hp-tile--slim-padding:last-child {
    padding-left: 7px; } }

.hp-tile-title--single {
  z-index: 1; }

.hp-tile-heading {
  display: none;
  font-family: "Merriweather", Georgia, "Times New Roman", Times, serif;
  color: #fff;
  font-size: 2.8rem;
  line-height: 34px;
  line-height: calc(3.4/2.8); }
  @media screen and (min-width: 769px) {
    .hp-tile-heading {
      font-size: 3.6rem;
      line-height: 42px;
      line-height: calc(4.2/3.6); } }
  @media screen and (min-width: 993px) {
    .hp-tile-heading {
      font-size: 4.4rem;
      line-height: 48px;
      line-height: calc(4.8/4.4); } }
  @media screen and (min-width: 768px) {
    .hp-tile-heading {
      display: block; } }

@media screen and (min-width: 768px) {
  .hp-tile-subhead {
    text-transform: uppercase; } }

.cta--garden-promo {
  margin-bottom: 1em; }

.garden-solutions {
  margin-bottom: 4em; }

.garden-solutions .toggle-group-heading:after {
  color: #ddd; }

@media screen and (min-width: 993px) {
  .garden-solutions .toggle-group:first-child div:after {
    border-right: 2px solid #ccc;
    content: '';
    position: absolute;
    right: 14px;
    top: 0;
    bottom: 0;
    width: 2px;
    margin: 1.5em 0 0 0; } }

.notice {
  display: flex;
  flex-direction: column;
  background-color: #faece3; }

.notice__heading {
  display: flex;
  justify-content: center;
  align-items: center; }
  .notice__heading.notice__heading--alert {
    color: #cc3921; }
  .notice__heading.notice__heading--alert::before {
    content: '';
    display: inline-flex;
    background: url(/rsp/css-images/Alert_Icon@1x.png) no-repeat 50% 50%;
    width: 1em;
    height: 1em;
    margin-right: 0.5em; }
    @media screen and (min-resolution: 192dpi) {
      .notice__heading.notice__heading--alert::before {
        background-image: url(/rsp/css-images/Alert_Icon@2x.png);
        background-size: contain; } }

.notice__body {
  margin-bottom: 1.5em; }

.mb--catalog .mb-head,
.mb--pots .mb-head {
  color: #377695;
  font-size: 1.4em;
  font-weight: normal; }

.hero-btn {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff;
  font-weight: 900;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  border-radius: 4px;
  height: 55px;
  background-color: #356c25;
  min-width: 180px;
  border: none;
  padding: 0 20px;
  padding: 0;
  display: flex;
  place-items: center;
  place-content: center;
  cursor: pointer;
  z-index: 1;
  padding: 0 20px !important; }
  @media screen and (max-width: 315px) {
    .hero-btn {
      min-width: 150px; } }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .hero-btn {
      font-size: 15px;
      height: 50px; } }
  @media screen and (min-width: 992px) {
    .hero-btn {
      font-size: 16px; } }
  .hero-btn:hover, .hero-btn:active, .hero-btn:focus {
    background: #255518;
    color: #fff;
    text-decoration: none; }

@media (min-width: 767px) {
  .hero-btn {
    margin: 30px 0px 55px 0; } }

@media screen and (max-width: 767px), (min-width: 768px) {
  .hero-btn {
    font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
    color: #fff;
    font-weight: 900;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    border-radius: 4px;
    height: 55px;
    background-color: #356c25;
    min-width: 180px;
    border: none;
    padding: 0 20px;
    margin: 30px 0px 55px 0; } }
  @media screen and (max-width: 767px) and (max-width: 315px), screen and (min-width: 768px) and (max-width: 315px) {
    .hero-btn {
      min-width: 150px; } }
  @media screen and (max-width: 767px) and (max-width: 767px), screen and (min-width: 768px) and (max-width: 767px), screen and (max-width: 767px) and (min-width: 768px) and (max-width: 991px), (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
    .hero-btn {
      font-size: 15px;
      height: 50px; } }
  @media screen and (max-width: 767px) and (min-width: 992px), screen and (min-width: 768px) and (min-width: 992px) {
    .hero-btn {
      font-size: 16px; } }

@media screen and (max-width: 767px), (min-width: 768px) {
    .hero-btn:hover, .hero-btn:active, .hero-btn:focus {
      background: #255518;
      color: #fff;
      text-decoration: none; } }

@media (min-width: 992px) {
  .hero-btn {
    margin: 30px 0px 55px 0; } }

.hero-caption-btn {
  cursor: pointer;
  color: white; }

.hero-caption-btn:hover {
  cursor: pointer;
  color: white; }

@media screen and (min-width: 768px) {
  .hero-caption-btn {
    position: absolute;
    bottom: 20px;
    right: 15px;
    border: none;
    z-index: 2;
    padding: 1px 6px; } }

@media screen and (min-width: 992px) {
  .hero-caption-btn {
    font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
    color: #fff;
    font-weight: 900;
    border-radius: 4px;
    height: 32px;
    min-width: 90px;
    background-color: rgba(0, 0, 0, 0.6);
    border: none;
    position: absolute;
    right: 15px;
    bottom: 20px;
    z-index: 2;
    padding: 1px 6px; } }
  @media screen and (min-width: 992px) and (max-width: 767px), screen and (min-width: 992px) and (min-width: 768px) and (max-width: 991px) {
    .hero-caption-btn {
      font-size: 15px; } }
  @media screen and (min-width: 992px) and (min-width: 992px) {
    .hero-caption-btn {
      font-size: 16px; } }

.secondary-banner.bs-btn, .secondary-banner.btn-link {
  display: flex;
  place-content: center;
  place-items: center;
  width: fit-content; }

.gallery .desc {
  margin-bottom: 60px; }

.cta--gallery,
.cta--holiday {
  margin-top: 0; }

.gallery-tiles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 25px;
  place-items: center;
  align-items: normal; }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .gallery-tiles {
      grid-template-columns: 1fr 1fr; } }
  @media screen and (max-width: 767px) {
    .gallery-tiles {
      grid-template-columns: 1fr 1fr; } }

@media screen and (max-width: 991px) {
  .gallery-tile-description {
    display: none; } }

@supports (-moz-appearance: none) {
  .gallery-tiles {
    align-items: normal; } }

.gallery-tile {
  display: flex;
  margin-bottom: 80px; }

.garden-img {
  height: 140px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .garden-img {
      height: 110px; } }

.gallery-tile--expanded {
  margin-bottom: 80px; }

.gallery-tile-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative; }

p {
  flex: 1 0 auto;
  font-size: 20px;
  line-height: 28px;
  color: #454545;
  margin-top: 5px; }

.gallery-tile-link {
  display: flex;
  margin-top: 15px;
  color: #042003;
  width: 100%;
  transition: 0.4s ease;
  overflow: hidden; }
  .gallery-tile-link:hover, .gallery-tile-link:active, .gallery-tile-link:focus {
    color: inherit;
    text-decoration: none; }

.gallery-tile-image {
  width: 100%;
  overflow: hidden;
  transition: transform 0.4s ease; }

.gallery-tile-image:hover {
  transform: scale(1.1);
  width: 100%; }

.rsp-btn--galleries {
  padding-left: 2em;
  padding-right: 2em; }
  @media screen and (max-width: 450px) {
    .rsp-btn--galleries {
      width: 100%; } }

.banner {
  position: relative; }
  .banner::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%; }

.banner-inner {
  text-align: center;
  padding: 1.8em 0 2.2em;
  overflow: hidden;
  z-index: 1;
  text-shadow: 0 0.1em 0.1em rgba(0, 0, 0, 0.3), 0 0.1em 0.5em rgba(0, 0, 0, 0.2);
  margin-top: -40px; }
  .banner-inner:hover, .banner-inner:active, .banner-inner:focus {
    text-decoration: none; }
  @media screen and (min-width: 768px) {
    .banner-inner.banner-inner--homepage {
      margin-bottom: 10px; } }
  @media screen and (min-width: 993px) {
    .banner-inner {
      padding: 2.5em 0; }
      .banner-inner.banner-inner--homepage {
        padding-bottom: 7em;
        padding: 30px; } }
  @media screen and (min-width: 1200px) {
    .banner-inner {
      padding: 3.5em 0 4.5em; }
      .banner-inner.banner-inner--homepage {
        padding: 2.5em 0; } }

.banner-inner--gallery,
.banner-inner--solutions {
  padding: 2em 0 2.3em; }
  @media screen and (min-width: 768px) {
    .banner-inner--gallery,
    .banner-inner--solutions {
      padding: 2.7em 0 2.9em; } }
  @media screen and (min-width: 992px) {
    .banner-inner--gallery,
    .banner-inner--solutions {
      padding: 3.8em 0 4.2em; } }

.banner-headline {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 2.4rem;
  line-height: 24px;
  line-height: calc(2.4/2);
  color: #fff;
  text-transform: uppercase;
  margin: 0; }
  @media screen and (min-width: 600px) {
    .banner-headline {
      font-size: 4.4rem;
      line-height: 52px;
      line-height: calc(4.4/5.2); } }
  @media screen and (min-width: 993px) {
    .banner-headline {
      font-size: 5.0rem;
      line-height: 50px;
      line-height: calc(5.0/5); } }
  .banner-headline.banner-headline--homepage {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 800;
    font-size: 4.8rem;
    line-height: 50px;
    line-height: calc(5/4.8);
    color: #fff;
    text-transform: uppercase; }
    @media screen and (min-width: 769px) {
      .banner-headline.banner-headline--homepage {
        font-size: 6.0rem;
        line-height: 62px;
        line-height: calc(6.2/6); } }
    @media screen and (min-width: 993px) {
      .banner-headline.banner-headline--homepage {
        font-size: 8.0rem;
        line-height: 82px;
        line-height: calc(8.2/8); } }

.banner-subhead.banner-subhead--homepage {
  font-family: "Merriweather", Georgia, "Times New Roman", Times, serif;
  color: #fff;
  font-size: 2.8rem;
  line-height: 34px;
  line-height: calc(3.4/2.8);
  margin-bottom: 0.5em; }
  @media screen and (min-width: 769px) {
    .banner-subhead.banner-subhead--homepage {
      font-size: 3.6rem;
      line-height: 42px;
      line-height: calc(4.2/3.6); } }
  @media screen and (min-width: 993px) {
    .banner-subhead.banner-subhead--homepage {
      font-size: 4.4rem;
      line-height: 48px;
      line-height: calc(4.8/4.4); } }

.banner-link {
  padding-left: 2em;
  padding-right: 2em;
  display: inline-block;
  text-decoration: none; }

.banner-link--homepage {
  text-shadow: none; }

@media screen and (max-width: 767px) {
  .banner--results {
    background-position: top left;
    background-size: auto; }
  .banner-inner--results {
    padding: 3em 0 2.73em 0; } }

.banner--results {
  background-position: top left;
  background-color: #fff; }

@media screen and (min-width: 768px) {
  .banner--results {
    background-size: contain; }
  .banner-inner--results {
    padding: 5em 0 0.65em 0; } }

@media screen and (min-width: 940px) {
  .banner--results {
    background-position: top center;
    background-size: auto;
    max-width: 940px;
    margin: 0 auto; }
  .banner-inner--results {
    padding-bottom: 2.2em; } }

.secondary-header .secondary-header--img {
  width: 100%; }
  @media screen and (max-width: 767px) {
    .secondary-header .secondary-header--img {
      height: 65px;
      object-fit: cover; } }

.secondary-header .headline-secondary {
  margin-bottom: 15px; }
  @media screen and (max-width: 767px) {
    .secondary-header .headline-secondary {
      margin-top: 30px;
      margin-bottom: 0px; } }
  @media (min-width: 768px) {
    .secondary-header .headline-secondary {
      margin-top: 55px; } }

.secondary-header .secondary-header--description {
  margin-top: 0;
  margin-bottom: 0; }

.pp-garden-pdfs {
  display: flex;
  flex-direction: column; }

.pd-meta-and-reviews .pp-garden-pdf-link {
  display: inline-block;
  margin-bottom: 20px;
  text-decoration: none; }

.pp-garden-pdf-label {
  font-weight: 700;
  margin-left: 0.5em;
  vertical-align: middle; }

.shipping {
  margin-left: auto;
  margin-right: auto;
  max-width: 860px;
  margin-bottom: 100px; }
  .shipping > .cs_title {
    margin-bottom: 40px; }
  .shipping h3 {
    margin-top: 50px; }
  .shipping h4 {
    margin-top: 40px; }
  .shipping td {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }

input {
  padding: 0; }

.input-group-addon {
  outline: 1px solid #757575; }

.error-icon {
  display: none; }

.error-icon-svg {
  height: 17px;
  width: 5px; }

.bs-input,
.bs-input.form-control {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  border: none;
  border: 1px solid #757575;
  padding: 12px 16px;
  height: 55px;
  color: #042003;
  font-size: 20px; }
  .bs-input:focus,
  .bs-input.form-control:focus {
    border: 3px solid #004b80; }
  .bs-input.error,
  .bs-input.form-control.error {
    border: 3px solid #da4e2b;
    display: inline-block;
    color: #da4e2b; }
    .bs-input.error + span.error-icon,
    .bs-input.form-control.error + span.error-icon {
      display: inline-block;
      position: absolute;
      right: 10px;
      margin-top: 12px; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .bs-input,
    .bs-input.form-control {
      height: 50px; } }

.bs-input-small {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  border: none;
  border: 1px solid #757575;
  padding: 12px 16px;
  height: 55px;
  color: #042003;
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  color: #454545;
  font-weight: normal;
  height: 50px;
  border: 1px solid #757575; }
  .bs-input-small:focus {
    border: 3px solid #004b80; }
  .bs-input-small.error {
    border: 3px solid #da4e2b;
    display: inline-block;
    color: #da4e2b; }
    .bs-input-small.error + span.error-icon {
      display: inline-block;
      position: absolute;
      right: 10px;
      margin-top: 12px; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .bs-input-small {
      height: 50px; } }
  @media screen and (max-width: 767px) {
    .bs-input-small {
      font-size: 18px;
      line-height: calc(26 / 18); } }
  @media (min-width: 768px) and (max-width: 991px) {
    .bs-input-small {
      font-size: 20px;
      line-height: calc(28 / 20); } }
  @media (min-width: 992px) {
    .bs-input-small {
      font-size: 20px;
      line-height: 1; } }

textarea.bs-input.form-control {
  height: auto;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  border: none;
  border: 1px solid #757575;
  padding: 12px 16px;
  height: 55px;
  color: #042003; }
  textarea.bs-input.form-control:focus {
    border: 3px solid #004b80; }
  textarea.bs-input.form-control.error {
    border: 3px solid #da4e2b;
    display: inline-block;
    color: #da4e2b; }
    textarea.bs-input.form-control.error + span.error-icon {
      display: inline-block;
      position: absolute;
      right: 10px;
      margin-top: 12px; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    textarea.bs-input.form-control {
      height: 50px; } }

select.bs-input {
  padding-right: 25px !important; }
  select.bs-input.error + span.error-icon {
    right: 20px !important; }

.bs-tag {
  border-radius: 3px;
  border: none;
  outline: 2px solid #e5e5e5;
  padding: 11px 16px;
  height: 40px; }
  @media screen and (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
    .bs-tag {
      height: 35px;
      padding: 8px 16px; } }
  .bs-tag::after {
    content: "\00d7";
    display: inline-block;
    margin-left: 10px;
    transform: scale(1.25);
    line-height: 1;
    cursor: pointer; }

.message {
  font-family: "Lato", "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold; }
  @media screen and (max-width: 767px) {
    .message {
      font-size: 20px;
      line-height: calc(28 / 20); } }
  @media (min-width: 768px) and (max-width: 991px) {
    .message {
      font-size: 22px;
      line-height: calc(32 / 22); } }
  @media (min-width: 992px) {
    .message {
      font-size: 22px;
      line-height: calc(32 / 22); } }
  .message.error {
    color: #da4e2b; }
  .message.success {
    color: #356c25; }
    .message.success::before {
      content: url(/dist/rsp/svg/intermediate-site-icons/Checkmark.svg);
      margin-right: 7px; }

input::-webkit-input-placeholder {
  /* Edge */
  color: #757575 !important; }

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #757575 !important; }

input::placeholder {
  color: #757575 !important; }

input:-moz-placeholder {
  color: #757575 !important; }

textarea::-webkit-input-placeholder {
  /* Edge */
  color: #757575 !important; }

textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #757575 !important; }

textarea::placeholder {
  color: #757575 !important; }

textarea:-moz-placeholder {
  color: #757575 !important; }

body.rsp {
  background-color: #005387;
  background-color: #042003;
  font-size: 1.4em;
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

::placeholder,
#ltkpopup-form input::placeholder,
.form-control::placeholder {
  color: #595959; }

.page-wrap {
  background-color: #fff;
  flex: 1 0 auto;
  position: relative; }

.modal.bp .modal-title {
  font-family: "Merriweather Regular", georgia, times, serif;
  color: #6d9213;
  width: 95%;
  font-size: 2em;
  margin-top: 0.5em; }

.modal.bp .modal-content {
  border-radius: 0; }

.modal.bp .modal-header,
.modal.bp .modal-footer {
  border-bottom: none 0;
  padding-left: 35px;
  padding-right: 35px; }

.modal.bp .modal-header,
.modal.bp .modal-body {
  padding-left: 35px;
  padding-right: 35px; }

.modal.bp .modal-header .close {
  width: 1.8em;
  height: 1.8em;
  opacity: 1;
  margin-top: -0.3em;
  margin-right: -1.34em; }

.modal.bp .form-control.snug {
  border-right: none 0;
  width: 100%; }

.newsletter-signup {
  display: flex;
  width: 95%;
  margin-bottom: 1em; }
  .newsletter-signup .form-group {
    flex-grow: 1; }

.modal.bp button.snug {
  margin-left: -5px; }

.modal.bp .form-control {
  border-radius: 0; }

.modal.bp .btn-default {
  text-transform: uppercase;
  background-image: #58753C;
  color: #fff;
  border-radius: 0 4px 4px 0;
  border-color: #58753C;
  font-weight: 600;
  letter-spacing: 0.05em; }

.modal.bp .modal-footer.modal-footer--newsletter {
  background: url(/rsp/images/Email_Modal.jpg) 0 0 no-repeat;
  background-size: cover;
  height: 130px; }

.modal.bp .modal-footer.no-top-border {
  border-top: none 0; }

.newsletter-status-msg .msg--success {
  color: #6d9213; }

.accessibility-title, .privacy-policy.cs_title {
  margin-top: 55px; }

.accessibility-content, .privacy-policy.cs_content {
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 150px; }

.retail-title {
  margin-top: 55px; }

.retail-direction--wrapper {
  margin: 0 auto;
  width: fit-content; }

.retail-direction {
  margin-top: 25px;
  margin-bottom: 50px;
  color: white !important;
  display: flex;
  place-content: center;
  place-items: center;
  align-self: center;
  width: fit-content; }

.retail-hours--img-responsive {
  margin-left: auto;
  margin-right: auto;
  width: 860px; }

.retail-hours {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 3em 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 860px; }
  .retail-hours h3 {
    margin-top: 0;
    margin-bottom: 0; }
  .retail-hours__season {
    margin-bottom: 2em; }
  @media screen and (min-width: 768px) {
    .retail-hours {
      align-items: flex-start; } }
  @media screen and (min-width: 992px) {
    .retail-hours {
      flex-direction: row-reverse;
      justify-content: flex-end; }
      .retail-hours__detail {
        margin-right: 2em; }
      .retail-hours__season:first-child > h2 {
        margin-top: 0; } }

/* ADA tab focus */
*:focus, *:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
  outline-offset: 1px; }

a:hover, a:active {
  outline: 0;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent; }

.dialog.scrollable {
  overflow-y: auto; }

.faq-contact {
  margin-top: 60px;
  margin-bottom: 180px;
  text-align: center;
  display: flex;
  width: fit-content;
  margin-left: auto;
  margin-right: auto; }

.btn-link {
  text-align: center;
  place-content: center;
  display: flex;
  place-items: center; }

.caption {
  font-style: italic; }

.cs-body.returns {
  margin-top: 25px; }

.btn-link.fit-center {
  width: fit-content;
  margin: 0 auto; }
