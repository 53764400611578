@import "bootstrap/mixins";


.cta-promo-body {
  position: relative;

  @media screen and (max-width: $screen-sm-max) {
    border: 10px solid #e1f7f7;
    padding-bottom: 3em;
    &.third-promo {
      margin-bottom: 5em;
    }
  }

}

// gonna need bg image for mobile (flower behind button)
// generated content for tablet and desktop (flower above button)
.plant-finder-btn-wrap {
  font-size: 15px; // override the old font size for em
  @media screen and (max-width: $screen-sm-max) {
    flex-direction: column;
    min-height: 18em;
    padding: 15px 30px 15px 20px;

    >p {
      margin-bottom: 1em;
    }

    &.third-promo {
      min-height: 20em;
      padding: 15px 30px 15px 20px;
      >p {
        line-height: 1.5;
        margin-bottom: 1em;
      }
    }
  }

    @media screen and (min-width: $screen-md-min) {
      position: absolute;
      background: #fff;
      border: 10px solid #e1f7f7;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 38em;
      height: 23em;
      flex-direction: column;
      align-items: flex-start;
      padding: 1em 2em;

      >p {
        margin-bottom: 1em;
      }

      &.third-promo {
        left: 2em;
        width: 38em;
        height: 23em;
        padding: 1em 2em;

        >p {
          line-height: 1.5;
          margin-bottom: 1em;
        }
      }
    }

    @media screen and (min-width: $screen-lg-min) and (max-width: 1799px) {
      position: absolute;
      background: white;
      border: 10px solid $bs-cyan;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 42em;
      flex-direction: column;
      padding: 1em 3em;

      &.third-promo {
        left: 4em;
        width: 40em;
        padding: 1em 3em;
      }
    }

    @media screen and (min-width: 1800px) {
      position: absolute;
      background: white;
      border: 10px solid $bs-cyan;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 42em;
      height: 26em;
      flex-direction: column;
      padding: 1em 4em;

      &.third-promo {
        left: 10em;
        width: 45em;
        padding: 1em 5em;
      }
    }
  }

.plant-finder-btn,
a.plant-finder-btn {
  position: relative;
  z-index: 1;
  padding-right: 2em;
  padding-left: 2em;
  width: 225px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .cta-promo-body & {
    font-family: $font-family-sans;
    position: relative;
  }

  @media screen and (min-width: 768px) {
    z-index: 0;
  }
}

.plant-finder-img {
  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }

  @media screen and (min-width: $screen-md-min) {
    width: 80%;
    height: 100%;
  }
}

.plant-finder-img--mobile {
  @media screen and (min-width: $screen-md-min) {
    display: none;
  }
}

.plant-third-promo-img {
  width: 100%;
  height: 100%;
  @media screen and (max-width: $screen-xs-max) {
    display: none !important;
  }
}

.plant-third-promo-img-mobile {
  width: 100%;
  height: 100%;
  @media screen and (min-width: $screen-sm-min) {
    display: none !important;
  }
}

// Plantable Promo with png image
.plantable-promo {
  display: flex;
  background-color: $bs-cyan;

  @media screen and (max-width: $screen-xs-max) {
    margin: auto 0;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    padding: 2em 3em 4em 3em;
    // box-shadow: 0 0 0 100vmax $bs-cyan;
    // clip-path: inset(0 -100vmax);

    &--text {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      >.body-txt-medium {
        line-height: 1.5;
        margin-bottom: 1.3em;
      }
    }

    &--image {
      width: 75%;
      height: 100%;
      scale: 1.7;
      margin-top: 5em;
      display: none;
      &--mobile {
        display: block;
      }
    }
  }

  @media screen and (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
    margin: auto 0;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    padding: 2em 3em;
    // box-shadow: 0 0 0 100vmax $bs-cyan;
    // clip-path: inset(0 -100vmax);

    &--text {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      >.body-txt-medium {
        line-height: 1.5;
        padding-right: 3em;
        margin-bottom: 1.3em;
        margin-top: 1em;
      }
    }

    &--image {
      width: 50%;
      height: 100%;
      scale: 1.3;
      &--mobile {
        display: none;
      }
    }
  }

  @media screen and (min-width: $screen-lg-min) {
    margin: auto 0;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding: 2em 3em 0 3em;
    min-height: 36em;
    &--text {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      max-width: 45em;
      height: 26em;

      >.body-txt-medium {
        line-height: 1.5;
        padding-right: 3em;
        margin-bottom: 1.3em;
      }
    }

    &--image {
      width: 40%;
      height: 100%;
      scale: 1.25;
      translate: 0 -10px;
      &--mobile {
        display: none;
      }
    }
  }
}

.plantable-promo--wrapper{
  @media screen and (min-width: $screen-lg-min) {
    @include container;
    margin-right: auto;
    margin-left: auto;
  }
  margin-top: 5em;
  margin-bottom: 3em;
}

@media screen and (max-width: $screen-sm-max) {
  .third-promo--wrapper{
    margin-top: 5em;
    margin-bottom: 8em;
    @include container;
  }
}

.row--trustpilot{
  padding: 4em 0 7em;
  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

// main#maincontent>div {
//   margin-top: 5em;
//   margin-bottom: 5em;
// }

// Tiles
// Using some css hackery (padding hack) so we can preserve the aspect ratio of
// the tiles as we move between breakpoints.

.hp-tile {
  // background: $bs-dark-green;
  background: $navy;
  text-align: center;
  margin-bottom: 30px; // to match 2x Bootstrap gutter width
  position: relative;

  &:after {
    content: '';
    display: block;
    padding-bottom: 90%;
  }

  &.hp-tile--double .hp-tile-link::after {
    @include radial-translucent-bg;
  }

  &.hp-tile--single .hp-tile-link::after {
    @include radial-translucent-bg;
    background-image: linear-gradient(to top, $translucent-underlay, transparent 50%);
  }

  &.hp-tile--double {

    .hp-tile-heading,
    .hp-tile-subhead {
      z-index: 1;
    }
  }

  &.hp-tile--double:after {
    padding-bottom: 50%;
  }

  @media screen and (min-width: 768px) {
    &.hp-tile--double:after {
      padding-bottom: 42.8095%;
      //padding-bottom: calc((100% - 30px) / 2);
    }
  }
}

.hp-tile-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  @include text-over-images-shadow;
}

.hp-tile-link {
  display: block;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;

  color: #fff;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  @media screen and (min-width: 768px) {
    &.hp-tile-link--double {
      justify-content: center;
      align-items: center;
    }
  }
}

.hp-tile--slim-padding {
  @media screen and (max-width: 767px) {
    &:nth-child(2) {
      padding-right: 7px;
    }

    &:last-child {
      padding-left: 7px;
    }
  }
}

.hp-tile-title--single {
  @extend %headline-medium;
  z-index: 1;
}

.hp-tile-heading {
  display: none;
  @include slideshow-subhead;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.hp-tile-subhead {
  @extend %headline-medium;

  @media screen and (min-width: 768px) {
    text-transform: uppercase;
  }
}

.cta--garden-promo {
  margin-bottom: 1em;
}

.garden-solutions {
  margin-bottom: 4em;
}

.garden-solutions .toggle-group-link {
  @include body-txt-link;
}

.garden-solutions .toggle-group-heading {
  @extend %headline-x-small;
}

.garden-solutions .toggle-group-heading:after {
  color: #ddd;
}


@media screen and (min-width: 993px) {
  .garden-solutions .toggle-group:first-child div:after {
    border-right: 2px solid #ccc;
    content: '';
    position: absolute;
    right: 14px;
    top: 0;
    bottom: 0;
    width: 2px;
    margin: 1.5em 0 0 0;
  }
}

// COVID-19 notice
.notice {
  display: flex;
  flex-direction: column;
  background-color: #faece3;
}

.notice__heading {
  @extend %headline-small;
  display: flex;
  justify-content: center;
  align-items: center;

  &.notice__heading--alert {
    color: $red;
  }

  &.notice__heading--alert::before {
    content: '';
    display: inline-flex;
    background: url(/rsp/css-images/Alert_Icon@1x.png) no-repeat 50% 50%;
    width: 1em;
    height: 1em;
    margin-right: 0.5em;

    @media screen and (min-resolution: 192dpi) {
      background-image: url(/rsp/css-images/Alert_Icon@2x.png);
      background-size: contain;
    }
  }
}

.notice__body {
  margin-bottom: 1.5em;
}

.mb--catalog .mb-head,
.mb--pots .mb-head {
  color: darken($blue, 2%);
  font-size: 1.4em;
  font-weight: normal;
}

.hero-btn {
  @include bs-btn;
  padding: 0;
  display: flex;
  place-items: center;
  place-content: center;
  cursor: pointer;
  z-index: 1;
  padding: 0 20px!important;
}

// mobile view
@media (min-width: $screen-xs-max) {
  .hero-btn {
    margin: 30px 0px 55px 0;

  }
}

// tablet view
@media screen and (max-width: $screen-xs-max),
(min-width: $screen-sm-min) {
  .hero-btn {
    @include bs-btn;
    margin: 30px 0px 55px 0;
  }
}

// desktop view
@media (min-width: $screen-md-min) {
  .hero-btn {
    margin: 30px 0px 55px 0;
  }
}

.hero-caption-btn {
  cursor: pointer;
  color: white;
}
.hero-caption-btn:hover {
  cursor: pointer;
  color: white;
}


//tablet view
@media screen and (min-width: $screen-sm-min) {
  .hero-caption-btn {
    position: absolute;
    bottom: 20px;
    right: 15px;
    border: none;
    z-index: 2;
    padding: 1px 6px;
  }
}

// Desktop view
@media screen and (min-width: $screen-md-min) {
  .hero-caption-btn {
    @include bs-btn-caption;
    border: none;
    position: absolute;
    right: 15px;
    bottom: 20px;
    z-index: 2;
    padding: 1px 6px;
  }
}

.secondary-banner.bs-btn{
  display: flex;
  place-content: center;
  place-items: center;
  width: fit-content;
}