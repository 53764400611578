@import "variables.scss";
@import "bootstrap_variables";
@import "forms.scss";
@import "type.scss";

.promo-header {

  background-color: $red;
  display: grid;
  place-items: center;
  width: 100%;
  max-width: none !important;
  min-height: 45px;

  a {
    @include bs-body-txt--global-alert;
  }

  @media screen and (max-width: 1200px) {
    padding: 0.5em 0;
  }

  strong {
    font-weight: 900;
  }

  .mnav-active & {
    display: none;
  }
}

.masthead {
  //font-size: 1.2rem;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.menuline-nav a.masthead-tf-link {
  color: $body-text;
  font-size: 1.1em;
  font-weight: 700;
  @include bs-body-txt-small
}

a.masthead-tf-link:hover {
  text-decoration: none;
}

.menuline-nav li,
.menuline-nav a {
  @include bs-body-txt-small;
}

.menuline-nav a {
  color: #000;
  display: flex;
}

.top-search-input {
  border: 1px solid black;
  border-right: none;
  height: auto;
  padding-top: 0.88rem;
  padding-bottom: 0.92rem;
  height: 40px;
  padding-right: 40px;
  @include bs-body-txt-small;
  color: $bs-dark-grey;
}

.top-search-input::placeholder {
  color: $bs-light-grey;
}


.suggestionsBox-rsp {
  display: none;
  z-index: $z-index-suggestions-box;
}

.suggestionsBox {
  left: 0;
  top: 40px;
  border-radius: 0;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-top: 1px solid #d9d9d9;
  padding-left: 5px;
}

.suggestionList {
  margin: 0px;
  padding: 0px;
  margin-top: 5px;
}

.suggestionList li {
  margin: 0px 0px 3px 0px;
  padding: 3px;
  cursor: pointer;
  list-style-type: none;
}

.suggestionList li:hover {
  background-color: #659CD8;
}

.bp-logo {
  width: 190px;
  height: 88px;

  // this style is needed because of a bug in Microsoft Edge
  // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/10583086/
  pointer-events: none;
}

.masthead-tf-link .zipcode {
  text-decoration: underline;
}

a>.icon {
  pointer-events: all;
}

@media screen and (min-width: $screen-xs-max) {
  .masthead {
    padding: 0.5em 0;
    width: 100%;
  }

  .bp-logo-link {
    margin-bottom: -50px;
    padding: .5em;
    margin-top: -.5em;
    display: inline-block;
  }

  .bp-logo {
    width: 190px;
    height: 88px;

    // this style is needed because of a bug in Microsoft Edge
    // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/10583086/
    pointer-events: none;
    vertical-align: unset;
  }

  .menuline-nav {
    margin-top: 1em;
  }

  .menuline-nav li {
    position: relative;
  }

  // vertical dividers between links
  .menuline-nav li {
    @include vert-dividers;

    &.last-item:after {
      display: none;
    }
  }

  .rsp-search {
    margin: 1em 0;
  }
}

.search-input-group {
  display: flex;
  position: relative;
}


// Mobile view
@media screen and (max-width: 1200px) {
  .search-logo {
    border: none;
    border-left: none;
    width: 40px;
    height: 50px;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    outline: none;
    // margin-right: 5px;
    padding: 0 10px;
  }
}

// Desktop view
@media (min-width: 1201px) {
  .search-logo {
    border: none;
    border-left: none;
    width: 40px;
    height: 55px;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    outline: none;
    // margin-right: 5px;
    padding: 0 10px;
  }
}

// Desktop view
@media (min-width: $screen-md-min) {

  .container--header {
    max-width: 1500px;
    margin: 0 auto;
  }

  .rsp-search {
    margin: 1em 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

    >.header-right {
      justify-self: flex-end;
    }
  }

  .menuline-nav ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 25px;
  }

  .menuline-nav li {
    position: relative;
  }

  .menuline-nav a {
    color: #000;
    display: flex;
    justify-content: right;
  }
}

// Wide screen
@media (min-width: $screen-lg-min) {
  .menuline-nav li {
    position: relative;
  }
}

.top-banner {
  background-color: rgb(6, 79, 6);
  height: 50px;
  margin-left: -15px;
  width: 1570px;
}

// Early break menu to show mobile/tablet menu
// because menu and order items strar wrapping
// below 1200px
@media screen and (min-width: $screen-sm-min) and (max-width: 1200px) {
  .rsp-search {
    display: none !important;
  }
}


.js-item-count.item-count {
  position: absolute;
  right: 0;
  margin: 0 auto;
  color: #1d1c1a;
  font-size: 13px;
  font-weight: bold;

  @media screen and (min-width: 1201px) {
    left: 5px;
    top: -7px;
  }

  @media screen and (max-width: 1200px) {
    left: 0px;
    top: 2px;
  }

  &:hover,
  &:active {
    text-decoration-skip: initial;
    text-decoration-skip-ink: none;
  }
}

.menuline-cart-link {
  position: relative;
  text-align: center;

  &:hover,
  &:active {
    text-decoration-skip: initial;
    text-decoration-skip-ink: none;
  }
}

.m-icon.account {
  margin-right: 10px;
  margin-bottom: 0px;
}
                .m-icon.m-cart-icon,
                .m-icon.mobile-menu-icon,
                .m-icon.account {
                  fill: $bs-dark-green;
                }