// common styles for our svg icons

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  // position: relative;
  // top: -0.0625em;
  fill: currentColor;
}

.icon--feature-BEES {
  fill: #fff;
  stroke: #000;
}

.icon--cart {
  width: 35px;
  height: 35px;
  // position: absolute;
  // margin-top: -14px;
}
.icon--search {
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  margin-top: -14px;
}
.icon--feature {
  width: 1.6em;
  height: 1.6em;
  top: -0.1em;
}

.icon--bbb-logo {
  width: 52px;
  height: 52px;
  fill: #1353b6;
  margin-right: 5px;
}

.icon--guarantee {
  width: 125px;
  height: 53px;
}

a > .icon {
  // this style is needed because of a bug in Microsoft Edge
  // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/10583086/
  pointer-events: none;
}
