@import "bootstrap_variables";
@import "bootstrap-overrides";
.menu-toggle, .search-toggle {
  position: absolute;
  z-index: $z-index-mobile-menu-toggle;
  opacity: 0;
}

.main-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
@media (min-width: 1201px) and (max-width: 1350px) {
  ul.category-links, div.category-promos{
    margin-right: 35px;
    min-width: 190px;
  }
}
@media (min-width: 1356px) {
  ul.category-links, div.category-promos{
    margin-right: 35px;
    min-width: 230px;
  }
}

div.category-promos:last-child{
  margin-right: 0;
}

.main-menu .drawer-inner {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1201px)
  {
  }
}

.drawer-inner{
  @media screen and (max-width: 1200px) {
      @include container;
  }
}

@media (min-width: 1200px) {
   .hr {
    border-color: #bfbfbf;
    border-width: 1px;
    width: 100%;
    margin:0;
    margin-bottom: 15px;
  }
}


.top-level-link {
  display: block;
  // @extend %headline-small;
  @include bs-headline-small;
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: none;
    color: #fff;
  }
}

// when there is no useful href attr, do not show the
// pointer cursor
.top-level-link[href="#"] {
  &:hover, &:active {
    cursor: default;
  }
}

.acct-signin label {
  @extend %headline-small;
}

// .top-level-link--sale,
.subnav .subnav-link--sale {
  color: $bs-red;
  font-weight: 700;
}

.subnav,
.subnav-heading,
.top-solutions-links,
.subnav .category-promos {
  display: none;
}

.subnav-heading,
.top-solutions-links {
  @include bs-headline-small;
  color: $bs-dark-green;
}

.subnav-link {
  @include bs-body-txt-small;
  display: block;
  text-decoration: none;
  line-height: 22px !important;

  &:hover, &:active, &:focus {
    text-decoration: none;
    color: lighten(#000, 20%);
  }
}

.has-subnav--active .subnav {
  display: block;
  opacity: 1;
}

.subnav {
  background-color: #fff;
  transition: opacity 1s linear;
  transition: visibility 1s linear;
  opacity: 0;

  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.quick-links > li > a {
  color: #333;
  text-decoration: none;
  @extend %body-txt-small;
}

.quick-links li {
  padding: 0 0.8em;
  @include vert-dividers;
}


.category-promo-fig {
  @extend %headline-small;
}

// tab and mobile, flip to mobile menu
@media screen and (max-width: 1200px) {
  // .main-nav {
  //   padding: 0;
  // }

  .mnav-active,
  .msr-panel-active  {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  .m-search {
    display: none;
  }

  .menu-toggle:checked~.drawer {
    transform: translateX(0);
  }

  .drawer {
    display: block;
    position: fixed;
    top: 0; // to be below nav icons bar
    left: 0;
    z-index: $z-index-mobile-nav;
    width: 100%;
    margin-bottom: 1em;
    font-size: 1.6rem;
    text-align: left;
    background: rgba(255,255,255,1);
    transform: translateX(120%);
    transition: transform .3s cubic-bezier(0,0,.3,1);
    box-shadow: 0 5px 5px rgba(50,50,50,0.2);
  }

  .main-menu {
    width: 100%;
    height: 100%;
    transform: translateX(-120%);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    box-shadow: none;
    background-color: $bs-dark-green;
    padding-bottom: 3em;
  }

  .menu-close{
    text-align: right;
    margin-top: 1em;
  }

  .m-icon.mobile-close-icon{
    width: 20px;
    margin-right: 1em;
  }

  .m-search {
    transform: translateX(120%);
    padding: 1em 15px 1.5em 15px;
    //top: 6.25em;
    font-size: 1.4em;
    border-bottom: 0.8em solid lighten($blue, 50%);
  }

  .m-suggestions-wrap {
    display: none;
    margin-top: 0.5em;
  }

  .m-suggestions > li {
    color: $body-text;
    padding: 0.5em 0;
  }

  .acct-menu {
    border-bottom: 0.56em solid lighten($blue, 50%);
  }

  .acct-signin {
    padding: 10px 15px 1.5em 15px;
    color: #000;
  }

  .acct-links li:first-child .top-level-link {
    border-top: none 0;
  }

  .acct-signin label {
    margin-bottom: 0.6em;
  }

  .acct-signin .m-input {
    font-size: 1em;
    padding: 0.4em 0.4em;
  }

  .m-btn--signin {
    font-size: 0.85em;
    margin-top: 0;
  }

  .acct-signin fieldset {
    margin-bottom: 1.3em;
  }

  .m-input,
  input[type=search].m-search-input {
    display: block;
    width: 100%;
    border: 1px solid #ccc;
    @include plain-text-input;
  }

  .m-search-input, .m-input {
    font-size: 1.4em;
    color: #000;
    padding: 0.22em 0.2em;
    &:focus {
      outline: 0.16em solid $blue;
    }
  }

  .m-btn {
    margin-top: 0.5em;
    width: 100%;
  }

  .main-nav-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    margin: 0 0 0 0;
  }

  .m-icon {
    width: 30px;
    height: 30px;
    fill: currentColor;
  }

  .m-bp-logo {
    width: 154px;
    height: 55px;
  }

  .mnav-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    // border-bottom: 0.8em solid lighten($blue, 50%);
    color: $nav-icon-default;
    > label {
      margin: 0;
    }
  }

  .main-menu .quick-links {
    padding: 1.7em 0;
  }

  .quick-links {
    order: 8;

  }

  .mnav-section--active {
    // border-bottom-color: $blue;
    color: $blue;
  }

  .mnav-section > a {
    position: relative;
    display: inline-table;
    color: $nav-icon-default;
  }

  .mnav-logo-section {
    text-align: center;
    flex-grow: 2;
  }

  .m-cart-icon {
    fill: #5d5d5d;
  }

  .top-level-link {
    padding: 1em 0;
    // border-top: 1px solid #ccc;
    position: relative;
    color: $white;

    // .has-subnav &::after {
    //   content: '+';
    //   position: absolute;
    //   top: 4px;
    //   right: 15px;
    //   color: #999;
    //   font-size: 2em;
    // }
  }

  .top-level-link--perennials {
    border-top: none 0;
  }

  // .top-level-link--sale {
  //   color: $bs-red;
  // }

  .has-subnav--active .top-level-link {
    border-bottom-color: #fff;
    &::after {
      content: '\2013'; // unicode for en-dash
    }
  }

  .subnav {
    padding: 0 15px 10px 15px;
    margin: -0.6em 0 0 0;
  }

  .subnav a {
    padding: 0.3em 0;
  }

  .quick-links {
    background-color: lighten($blue, 55%);
    background-color: rgba(red($blue), green($blue), blue($blue), 0.056);
    padding: 2em 0 3em;
  }

  .quick-links li  {
    display: block;
    padding: 0;
  }

  .quick-links a {
    display: block;
    padding: 0.3em 15px;
  }

  .menu-links{
    display: flex;
    flex-wrap: wrap;
    >li {
      width: 33.33%;
      aspect-ratio: 1;
      display: flex;
      flex-direction: column-reverse;
      padding: 0em 0.5em;
      margin-bottom: 1em;
      align-self: flex-start;
      word-break: break-word;
    }
  }
}

@media screen and (max-width: 499px){
  .menu-links{
    display: flex;
    flex-wrap: wrap;
    >li {
      width: 50%;
    }
  }
}

.container.container--main-nav{
  // @media screen and (max-width: 1200px) {
    @include container-header;
  // }
}
// @media screen and (max-width: 1200px) {
//   .main-nav{
//     @include container;
//   }
// }

@media screen and (min-width: 500px) and (max-width: 1200px) {

  .m-icon {
    width: 30px;
    height: 30px;
  }

  .mnav-section {
    text-align: center;
    height: 60px;
  }

  .mnav-logo-section {
    flex-grow: 10;
  }

  .m-bp-logo {
    width: 154px;
    height: 55px;
  }

  .drawer {
    top: 0;
  }

  .m-search > .m-search-form {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .m-search-input {
    flex-grow: 1;
    width: auto;
  }

  .m-btn--search {
    margin: 0 0 0 1em;
    width: auto;
    flex-grow: 0.4;
  }

  .m-btn--signin {
    width: 10em;
  }
}

@media screen and (max-width: 1200px) {
  .menu.hr{
    display: none;
    margin:0;
    margin-bottom: 15px;
  }
  .mobile-search-section{
    margin-bottom: 4em;
  }
  .signin-link.body-txt-link{
    @include bs-body-txt-link;
    background-color: transparent;
    border: 3px solid $white;
    border-radius: 4px;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    padding: 0.5em;
    display: inline-block;
    text-align: center;
    color: $white;
    &:hover{
      color: $white;
    }
  }
  .drawer-signin{
    margin-bottom: 2em;
  }
}

.drawer-links{
  display: flex;
  justify-content: center;
  >.segment{
    @extend %segment
  }
  >a{
    color: $white;
    &:hover, &:focus {
      text-decoration: none;
      color: #fff;
    }
  }
}

.drawer-link:first-child{
  margin: 0 calc(0.15em + 2vw);
}

.drawer-link + .segment>.text-white:first-child {
  margin-left: calc(0.15em + 1vw);
}

a.mobile-menu-img{
	display: flex;
	flex-direction: column-reverse;
}

a.mobile-menu-img > img {
  width: 100%;
  height: 100%;
}


@media screen and (min-width: 1201px) {
  .mnav-section > .menu-toggle,
  .mnav-section > label,
  .mnav-section > a,
  .menu-close,
  .top-level-link + img,
  .top-level-link + a.mobile-menu-img,
  .mobile-search-section,
  .drawer-actions {
    display: none;
  }

  .mnav-section--menu {
    width: 100%;

    .menu-links {
      margin: auto;
      width: 100%;
    }
  }

  .has-subnav {
    float: left;
  }

  .main-menu .quick-links {
    background: url(/rsp/css-images/shadow-horizontal.jpg) #fff bottom left no-repeat;
    padding-bottom: 2em;
    margin-bottom: 1em;

    li:first-child {
      padding-left: 0;
      a {
        padding-left: 0;
      }
    }

    li, a {
      float: left;
    }

    a {
      display: block;
      padding: 0.18em 0.2em 0.2em 0.2em;
    }
  }

  .top-level-link {
    .menu-links :first-child & { margin-left: 0; }
    .menu-links :last-child & { margin-right: 0; }
    padding-bottom: 10px;
    border-bottom: 6px solid #fff;

    .has-subnav--active & {
      color: $bs-green;
      border-color: $bs-green;
      &:hover, &:active {
        color: $bs-green;
      }
    }

    &:hover, &:active {
      color: initial;
    }
  }

  .subnav-heading, .top-solutions-links {
    display: block;
    padding-bottom: 0.3em;
  }

  li.subnav-heading{
    padding-bottom: 0.3em;
    padding-top: 1.4em;
  }

  .subnav .category-promos {
    display: block;
    margin-top: calc(1.4em + 22px);
  }

  .main-menu .menu-links {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5em 0;
    gap: 40px;
    margin: 0 auto;
    width: auto;
  }

  .subnav {
    position: absolute;
    width: calc(100% + 15px);
    left: -15px;
    z-index: $z-index-subnav;
    padding: 1.5em 60px 6em;
    background: $white;
    box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.19);
  }


  .subnav-link {
    padding: 0.5em 0;
  }

  .top-level-link--sale:hover,
  .top-level-link--sale:active,
  .top-level-link--sale:focus,
  .subnav .subnav-link--sale:hover,
  .subnav .subnav-link--sale:active,
  .subnav .subnav-link--sale:focus {
    color: lighten($bs-red, 10%);
    text-decoration: none;
  }

  .category-promo-tile {
    @media screen and (min-width: $screen-md-min) {
      position: relative;
      display: block;
      width: 231px;
      height: 231px;
      background-color: $blue;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: cover;
    }
    @media screen and (min-width: 1201px) and (max-width: 1350px) {
      position: relative;
      display: block;
      width: 200px;
      height: 200px;
      background-color: $blue;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .category-promo-text {
    width: 100%;
    padding: 0.5em 0;
  }
}

@media screen and (min-width: $screen-lg-min) {
  .main-nav-top {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }


  .main-menu .quick-links {
    display: flex;
    justify-content: center;
    background-size: contain;
  }
}

.category-promo-text {
  color: $bs-dark-green
}

.navlink.bs-btn{
  margin-top: 1.5em;
  min-width: auto;
  width: 180px;
  display: grid;
  place-items: center;
}

.menu-container{
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0px!important;
}

.no-scroll{
  overflow: hidden;
}

.fp-viewer-thumbs{
  >.fp-viewer-thumb-box.active {
    >a.nav-link.thumbnail{
      &:hover,
        &:focus {
          color: black;
          background-color: red;
        }
    }
  }
}

.nav-pills {
  > li {
    float: left;

    // Links rendered as pills
    > a {
      border-radius: $nav-pills-border-radius;
    }
    + li {
      margin-left: 2px;
    }

    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        border: 3px solid #356c25!important;
        padding: 2px;
        background-color: white;
      }
    }
  }
}
