// BP web fonts
/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/open-sans-v15-latin/open-sans-v15-latin-300-subset.woff2') format('woff2'),
    url('/fonts/open-sans-v15-latin/open-sans-v15-latin-300-subset.zopfli.woff') format('woff'),
    url('/fonts/open-sans-v15-latin/open-sans-v15-latin-300-subset.ttf') format('truetype');
  font-display: swap;
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/open-sans-v15-latin/open-sans-v15-latin-regular-subset.woff2') format('woff2'),
    url('/fonts/open-sans-v15-latin/open-sans-v15-latin-regular-subset.zopfli.woff') format('woff'),
    url('/fonts/open-sans-v15-latin/open-sans-v15-latin-regular-subset.ttf') format('truetype');
  font-display: swap;
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/open-sans-v15-latin/open-sans-v15-latin-600-subset.woff2') format('woff2'),
    url('/fonts/open-sans-v15-latin/open-sans-v15-latin-600-subset.zopfli.woff') format('woff'),
    url('/fonts/open-sans-v15-latin/open-sans-v15-latin-600-subset.ttf') format('truetype');
  font-display: swap;
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/open-sans-v15-latin/open-sans-v15-latin-700-subset.woff2') format('woff2'),
    url('/fonts/open-sans-v15-latin/open-sans-v15-latin-700-subset.zopfli.woff') format('woff'),
    url('/fonts/open-sans-v15-latin/open-sans-v15-latin-700-subset.ttf') format('truetype');
  font-display: swap;
}

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/open-sans-v15-latin/open-sans-v15-latin-800-subset.woff2') format('woff2'),
    url('/fonts/open-sans-v15-latin/open-sans-v15-latin-800-subset.zopfli.woff') format('woff'),
    url('/fonts/open-sans-v15-latin/open-sans-v15-latin-800-subset.ttf') format('truetype');
  font-display: swap;
}

/* merriweather-300 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/merriweather-v19-latin/merriweather-v19-latin-300-subset.woff2') format('woff2'),
    url('/fonts/merriweather-v19-latin/merriweather-v19-latin-300-subset.zopfli.woff') format('woff'),
    url('/fonts/merriweather-v19-latin/merriweather-v19-latin-300-subset.ttf') format('truetype');
  font-display: swap;
}

/* merriweather-regular - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/merriweather-v19-latin/merriweather-v19-latin-regular-subset.woff2') format('woff2'),
    url('/fonts/merriweather-v19-latin/merriweather-v19-latin-regular-subset.zopfli.woff') format('woff'),
    url('/fonts/merriweather-v19-latin/merriweather-v19-latin-regular-subset.ttf') format('truetype');
  font-display: swap;
}

/* merriweather-italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/merriweather-v19-latin/merriweather-v19-latin-italic-subset.woff2') format('woff2'),
    url('/fonts/merriweather-v19-latin/merriweather-v19-latin-italic-subset.zopfli.woff') format('woff'),
    url('/fonts/merriweather-v19-latin/merriweather-v19-latin-italic-subset.ttf') format('truetype');
  font-display: swap;
}

/* merriweather-700 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/merriweather-v19-latin/merriweather-v19-latin-700-subset.woff2') format('woff2'),
    url('/fonts/merriweather-v19-latin/merriweather-v19-latin-700-subset.zopfli.woff') format('woff'),
    url('/fonts/merriweather-v19-latin/merriweather-v19-latin-700-subset.ttf') format('truetype');
  font-display: swap;
}


// New Fonts from new design 2022
/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2022 Schwartzco Inc.
        License: 2208-FHHPES     
*/


@font-face {
  font-family: 'Lyon Text Web';
  src: url('/fonts/Lyon-Commercial-Type/LyonText-BlackItalic-Web.woff2') format('woff2'),
       url('/fonts/Lyon-Commercial-Type/LyonText-BlackItalic-Web.woff') format('woff');
  font-weight:  900;
  font-style:   italic;
  font-stretch: normal;
}

.LyonText-BlackItalic-Web {
  font-family: 'Lyon Text Web';
  font-weight:  900;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Lyon Text Web';
  src: url('/fonts/Lyon-Commercial-Type/LyonText-Black-Web.woff2') format('woff2'),
       url('/fonts/Lyon-Commercial-Type/LyonText-Black-Web.woff') format('woff');
  font-weight:  900;
  font-style:   normal;
  font-stretch: normal;
  font-display: swap;
}

.LyonText-Black-Web {
  font-family: 'Lyon Text Web';
  font-weight:  900;
  font-style:   normal;
  font-stretch: normal;
}

/* Google Lato - 400 - regular */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Lato-Google-Fonts/Lato-Regular.ttf') format('truetype');
  font-display: swap;
}
/* Google Lato - 400 - regular italic */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/Lato-Google-Fonts/Lato-Italic.ttf') format('truetype');
  font-display: swap;
}
/* Google Lato - 700 - bold */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/Lato-Google-Fonts/Lato-Bold.ttf') format('truetype');
  font-display: swap;
}
/* Google Lato - 900 - black */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/Lato-Google-Fonts/Lato-Black.ttf') format('truetype');
  font-display: swap;
}

a {
  color: $bs-green;
  text-decoration: none;
}

// with the current base font size 1em = 10px
// So if you want 16 px, you set a font-size of 1.6em

@mixin slideshow-header {
  font-family: $font-family-sans;
  font-weight: 800;
  font-size: 4.8rem;
  // font-family: $font-family-serif;
  // font-weight: 900;
  // font-size: 90px;
  line-height: 50px;
  line-height: calc(5/4.8);
  color: #fff;
  text-transform: uppercase;
  // filter: drop-shadow(0 2px 13px rgba(0,0,0,0.4));

  @media screen and (min-width: 769px) {
    font-size: 6.0rem;
    line-height: 62px;
    line-height: calc(6.2/6);
  }

  @media screen and (min-width: 993px) {
    font-size: 8.0rem;
    line-height: 82px;
    line-height: calc(8.2/8);
  }
}

@mixin slideshow-subhead {
  font-family: $font-family-serif;
  color: #fff;
  font-size: 2.8rem;
  line-height: 34px;
  line-height: calc(3.4/2.8);

  @media screen and (min-width: 769px) {
    font-size: 3.6rem;
    line-height: 42px;
    line-height: calc(4.2/3.6);
  }

  @media screen and (min-width: 993px) {
    font-size: 4.4rem;
    line-height: 48px;
    line-height: calc(4.8/4.4);
  }
}

@mixin headline-x-large {
  font-family: $font-family-sans;
  font-weight: 800;
  font-size: 2.4rem;
  line-height: 24px;
  line-height: calc(2.4/2);
  color: #fff;
  text-transform: uppercase;

  @media screen and (min-width: 600px) {
    font-size: 4.4rem;
    line-height: 52px;
    line-height: calc(4.4/5.2);
  }

  @media screen and (min-width: 993px) {
    font-size: 5.0rem;
    line-height: 50px;
    line-height: calc(5.0/5);
  }
}



// @mixin bs-headline-hero {
//   font-family: $bs-font-family-serif;
//   color: $white;
//   text-shadow: 0 2px 13px rgba(0, 0, 0, 0.4);
//   @media screen and (max-width: $screen-xs-max) {
//     font-size: 48px;
//     line-height: 54px;
//   }

//   @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
//     font-size: 72px;
//     line-height: 80px;
//   }

//   @media (min-width: $screen-md-min){
//     font-size: 90px;
//     line-height: 100px;
//   }
// }

// h1.headline-hero{
//   @include bs-headline-hero;
// }

@mixin bs-headline-secondary {
  font-family: $bs-font-family-serif;
  color: $bs-dark-green;
  @media screen and (max-width: $screen-xs-max) {
    font-size: 34px;
    line-height: 42px;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
    font-size: 48px;
    line-height: 56px;
  }

  @media (min-width: $screen-md-min){
    font-size: 54px;
    line-height: 60px;
  }
}

h1.headline-secondary{
  @include bs-headline-secondary;
}

@mixin bs-headline-large {
  font-family: $bs-font-family-serif;
  color: $bs-dark-green;
  font-weight: 900;


  @media screen and (max-width: $screen-xs-max) {
    font-size: 30px;
    line-height: 42px;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
    font-size: 40px;
    line-height: 56px;
  }

  @media (min-width: $screen-md-min){
    font-size: 54px;
    line-height: 60px;
  }
}

h1, .headline-large{
  @include bs-headline-large;
}

@mixin headline-large {
  @include bs-headline-large;
}

@mixin bs-headline-medium {
  font-family: $bs-font-family-serif;
  font-weight: 900;
  color: $bs-dark-green;

  @media screen and (max-width: $screen-xs-max) {
    font-size: 30px;
    line-height: calc(38 / 30);
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
    font-size: 40px;
    line-height: calc(50 / 40);
  }

  @media (min-width: $screen-md-min){
    font-size: 44px;
    line-height: calc(54 / 44);
  }
}

h2, .headline-medium, .h2{
  @include bs-headline-medium;
}

@mixin bs-headline-medium-modified {
  font-family: $bs-font-family-serif;
  font-weight: 900;
  color: $bs-dark-green;

  @media screen and (max-width: $screen-xs-max) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
    font-size: 30px;
    line-height: 44px;
  }

  @media (min-width: $screen-md-min){
    font-size: 30px;
    line-height: 44px;
  }
}

.headline-medium-modified, .h2-modified{
  @include bs-headline-medium-modified;
}

@mixin headline-small {
  font-family: $font-family-sans;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 24px;
  line-height: calc(2.4/1.7);
  color: $body-text;
}

@mixin bs-headline-small {
  font-family: $bs-font-family-serif;
  font-weight: 900;
  color: $bs-dark-green;

  @media screen and (max-width: $screen-xs-max), (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    font-size: 20px;
    line-height: 26px;
  }

  @media screen and (min-width: $screen-md-min){
    font-size: 22px;
    line-height: 30px;
  }
}

h3, .headline-small, .h3{
  @include bs-headline-small;
}

@mixin headline-tiny {
  // defining this one as a Sass mixin so it can be included directly
  // e.g. inside media queries in addition to using it via @extend.
  // may end up doing this for others too but so far it's only the
  // section heading in the footer that change typefaces between breakpoints
  font-family: $font-family-serif;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 20px;
  line-height: calc(2/1.2);
  text-transform: uppercase;
}

@mixin bs-headline-tiny {
  font-family: $bs-font-family-sans-serif;
  font-weight: bold;
  color: $bs-dark-green;

  @media screen and (max-width: $screen-xs-max), (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    font-size: 18px;
  }

  @media screen and (min-width: $screen-md-min){
    font-size: 20px;
  }
}

h4, .headline-tiny, .h4{
  @include bs-headline-tiny;
}

@mixin bs-headline-h5 {
  font-family: $bs-font-family-sans-serif;
  font-weight: bold;
  color: $bs-dark-green;

  @media screen and (max-width: $screen-xs-max), (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    font-size: 16px;
  }

  @media screen and (min-width: $screen-md-min){
    font-size: 18px;
  }
}

h5, .headline-h5{
  @include bs-headline-h5;
}

@mixin body-txt-link {
}

@mixin bs-body-txt-link {
  font-family: $bs-font-family-sans-serif;
  font-weight: 900;
  color: $bs-green;
  text-transform: uppercase;
  letter-spacing: 1px;
  @media screen and (max-width: $screen-xs-max), (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    font-size: 15px;
  }
  @media screen and (min-width: $screen-md-min){
    font-size: 16px;
  }
  &:hover{
    color: $bs-green;
  }
}

.body-txt-link{
  @include bs-body-txt-link;
}

.body-txt-link.normal{
  letter-spacing: normal;
  text-transform: none;
}
.normal{
  letter-spacing: normal;
  text-transform: none;
}

.body-txt-link.bold{
  font-weight: 900;
}

// @mixin body-txt-medium {
//   font-family: $font-family-sans;
//   font-size: 1.4rem;
//   line-height: 24px;
//   line-height: calc(2.4/1.4); // using calc for a unitless line-height
//   color: $body-text;
// }

@mixin bs-body-txt-medium {
  font-family: $bs-font-family-sans-serif;
  color: $bs-dark-green;
  @media screen and (max-width: $screen-xs-max) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
    font-size: 22px;
    line-height: 34px;
  }

  @media (min-width: $screen-md-min){
    font-size: 22px;
    line-height: 34px;
  }
}

.body-txt-medium{
  @include bs-body-txt-medium;
}

.body-txt-medium.bold{
  font-weight: bold;
}
.body-txt-medium.link{
  color: $bs-green
}


@mixin bs-body-txt-small {
  font-family: $bs-font-family-sans-serif;
  color: $bs-dark-grey;
  font-weight: normal; 
  @media screen and (max-width: $screen-xs-max) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
    font-size: 20px;
    line-height: 28px;
  }

  @media (min-width: $screen-md-min){
    font-size: 20px;
    line-height: 28px;
  }
}

.body-txt-small{
  @include bs-body-txt-small;
}
@mixin body-txt-small {
  font-family: $font-family-sans;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 20px;
  line-height: calc(2/1.3);
}

@mixin bs-body-txt-input-small {
  font-family: $bs-font-family-sans-serif;
  color: $bs-dark-grey;
  font-weight: normal; 
  @media screen and (max-width: $screen-xs-max) {
    font-size: 18px;
    line-height: calc(26 / 18);
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
    font-size: 20px;
    line-height: calc(28 / 20);
  }

  @media (min-width: $screen-md-min){
    font-size: 20px;
    line-height: 1;
  }
}

.body-txt-input-small{
  @include bs-body-txt-input-small;
}

@mixin bs-body-txt-price {
  @include bs-body-txt-medium;
  color: $bs-dark-green;
  @media screen and (max-width: $screen-xs-max), (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    font-size: 18px;
  }
  @media screen and (min-width: $screen-md-min){
    font-size: 20px;
  }
}

.body-txt--price{
  @include bs-body-txt-price;
}

@mixin bs-body-txt-price--large {
  @include bs-body-txt-price;
  @media screen and (max-width: $screen-xs-max), (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    font-size: 24px;
  }
  @media screen and (min-width: $screen-md-min){
    font-size: 28px;
  }

  .sale{
    color: $bs-red;
  }
}

.price--large{
  @include bs-body-txt-price--large;
}

@mixin bs-body-txt-sale-price {
  @include bs-body-txt-price;
  color: $bs-red;
}

.body-txt--saleprice{
  @include bs-body-txt-sale-price;
}

@mixin bs-body-txt-old-price {
  @include bs-body-txt-medium;
  color: $bs-light-grey;
  text-decoration: line-through;
  @media screen and (max-width: $screen-xs-max), (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    font-size: 16px;
  }
  @media screen and (min-width: $screen-md-min){
    font-size: 18px;
  }
}

.body-txt--oldprice{
  @include bs-body-txt-old-price;
}

@mixin bs-body-txt-copyright{
  font-family: $font-family-sans-serif;
  color: $white;
  height: 35px;
  font-weight: normal;
  // background-color: $bs-red;
  @media screen and (max-width: $screen-xs-max){
    font-size: 15px;
  }  
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    font-size: 16px;
  } 
  @media (min-width: $screen-md-min){
    font-size: 16px;
  }
}

.body-txt--copyright{
  @include bs-body-txt-copyright;
}

@mixin bs-body-txt--global-alert {
  font-family: $bs-font-family-sans-serif;
  color: $white;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  @media screen and (max-width: $screen-xs-max) {
    font-size: 12px;
    line-height: calc(19 / 12);
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
    font-size: 15px;
    line-height: calc(21 / 15);
  }

  @media (min-width: $screen-md-min){
    font-size: 16px;
    line-height: calc(24 / 16);
  }
}

@mixin bs-body-txt--dropdown {
  font-family: $bs-font-family-sans-serif;
  color: $white;
  font-weight: bold; 
  @media screen and (max-width: $screen-xs-max) {
    font-size: 15px;
    line-height: calc(19 / 15);
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
    font-size: 16px;
    line-height: calc(21 / 16);
  }

  @media (min-width: $screen-md-min){
    font-size: 18px;
    line-height: calc(24 / 18);
  }
}


@mixin bs-msg-txt {
  font-family: $bs-font-family-sans-serif;
  font-weight: bold; 
  @media screen and (max-width: $screen-xs-max) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
    font-size: 22px;
    line-height: 32px;
  }

  @media (min-width: $screen-md-min){
    font-size: 22px;
    line-height: 32px;
  }
}

.success-txt{
  @include bs-msg-txt();
  color: $bs-green;
}

.error-txt{
  @include bs-msg-txt();
  color: $bs-red;
}

.msg-txt{
  @include bs-msg-txt();
  color: $bs-dark-green;
}

%body-txt-small {
  @include body-txt-small;
}

%body-txt-large {
  @include bs-body-txt-medium;

}

.body-txt-large {
  @extend %body-txt-large;

}

@mixin alert-text {
  font-family: $bs-font-family-sans-serif;
  line-height: 34px!important;
  @media screen and (max-width: $screen-xs-max), (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    font-size: 16px!important;
  }
  @media screen and (min-width: $screen-md-min){
    font-size: 20px!important;
  }
}


.cart-alert{
  @include alert-text();

  &--success{
    background: $bs-bg-green;
  }
  &--error{
    background: $bs-bg-red;
    color: $bs-red;
  }
}



%headline-large {
  @include bs-headline-large;
}

%headline-medium {
  font-family: $font-family-sans;
  font-weight: 700;
  font-size: 2.0rem;
  line-height: 26px;
  line-height: calc(2.6/2);
  color: #fff;
}

%headline-small {
  @include bs-headline-small;
}

%headline-x-small {
  font-family: $font-family-sans;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 22px;
  line-height: calc(2.2/1.5);
}

%headline-tiny {
  @include headline-tiny;
}

%headline-white {
  color: #fff;
}

%serif-italic {
  font-family: $font-family-serif;
  font-style: italic;
}

%inherit-type {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.bold{
  font-weight: bold;
}
.font-weight-normal{
  font-weight: normal;
}
.link{
  color: $bs-green
}
.disabled-section{
  opacity: 0.5;
  cursor: auto!important;
}