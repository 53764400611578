.shipping{
    margin-left: auto;
    margin-right: auto;
    max-width: 860px;
    margin-bottom: 100px;
    >.cs_title{
        margin-bottom: 40px;
    }
    h3{
        margin-top: 50px;
    }
    h4{
        margin-top: 40px;
    }

    td{
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
}