.footer-rsp {
  background: $bs-dark-green;
  color: #fff;
  padding: 2em 0 0 0;
}

.footer-signup-form {
  margin-bottom: 0.5em;
}

.footer-signup-input,
.footer-signup-btn {
  display: block;
  width: 100%;
}

.footer-signup-input {
  border: 3px solid #fff;
  color: $body-text;
  padding: 0.3em 0.6em 0.2em;
  margin-bottom: 1em;
  &::placeholder {
      color: $input-color-placeholder;
      opacity: 1;
  }
  @include plain-text-input;
}

.footer-signup-msg {
  margin-bottom: 0.5em;

  a {
    @extend %headline-white;
    color: #fff;
    border-bottom: 2px solid $blue;
    text-shadow: 1px 1px 3px darken($blue, 30%);
    text-decoration: none;
  }
}

.footer-signup-msg .icon--checkmark {
  // default color from Jess: #6e933d
  fill: #fff;
  display: inline-block;
  vertical-align: middle;
  width: 1.2em;
  height: 1.2em;
}

.footer-links {
}

.footer-section-toggle {
  z-index: 1;
  position: absolute;
  opacity: 0;
}
.footer-section-heading {
  color: #fff;
}

.footer-links a {
  padding: 0.15em 0;
}

.segment-split {
  margin-left: 0;
}

.footer-nav-contact{
  display: flex;
  justify-content: center;
  column-gap: 1em;
  text-decoration: none;
  color: white;
  margin-top: 1em;
}


.footer-contact-info {
  color: #fff;

  a{
    text-decoration: none;
    color: white;
  }

  .segment {
    @extend %segment;
  }

  .seg-group {
    white-space: nowrap;

    a {
      font-weight: normal;
    }
  }
}

.footer-colophon {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-toll-free {
  @extend %headline-white;
  margin-top: 2em;
  margin-bottom: 0.5em;
  letter-spacing: 0.03em;
  @extend %headline-small;
  font-weight: 600;

  .footer-tf-number, .footer-mailto {
    float: left;
    a {
      @extend %inherit-type;
      @extend %headline-white;
    }
  }

  .footer-tf-number {
    margin-right: calc(0.15em + 1vw);
    text-decoration: none;
  }
}

.footer-mailto {
  color: inherit;
  @extend %inherit-type;
  &:hover, &:active, &:focus {
    color: #fff;
  }
}

.footer-colophon {
  width: 100%;
  @extend %body-txt-small;
  // font-size: calc(0.6em + 1vw);
  margin: 1em 0 2em 0;
  a {
    color: inherit;
    @extend %inherit-type;
  }
}

.footer-address {
  display: inline;
}

.veracity-links {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.footer-seals {
  width: auto;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.geotrust-seal {
  height: 55px;
  width: 80px;
  // I have placeholder styles here for now b/c the siteseal doesn't
  // work in camps. We'll also need feedback from Jess on designs for
  // small screen b/c it's larger than the Comodo logo and does not
  // fit on the same row as the 100% Guaranteed image --MJD
  position: relative;
  margin-left: 10px;
  &::after {
    position: absolute;
    display: block;
    top: 5px;
    left: 0;
    width: 100%;
    content: '';
    font-size: 0.8em;
    text-align: center;
  }
}

.guarantee-logo-img {
  display: block;
  max-width: 151px;
}

// .footer-colophon .seg-group > .segment:first-child,
// .footer-toll-free > .segment:first-child {
//   margin-left: 0;
//   &::before {
//     display: none;
//   }
// }

@media screen and (min-width: 500px) {
  .footer-signup-form {
    display: flex;
  }

  .footer-signup-input,
  .footer-signup-btn {
    width: auto;
  }

  .footer-signup-input {
    flex-grow: 1;
    margin-right: 0.75em;
    margin-bottom: 0;
  }

  .footer-signup-btn {
    flex-grow: 0.12;
  }

  // .footer-colophon {
  //   font-size: 0.8em;
  // }

}

@media screen and (max-width: 350px) {
  // .footer-colophon {
  //   font-size: 0.72em;
  // }

  .footer-toll-free {
    // font-size: calc(0.95em + 1vw);
  }
}

@media screen and (max-width: 612px) {
  .footer-address > .segment:first-child,
  .footer-mailto.segment {
    &::before {
      display: none;
    }
  }
}

// all screens
.footer-links .body-txt-small{
  font-size: 18px;
}
.footer-logo-section {
  text-align: center;

   .bp-logo {
    margin-bottom: 1em;
  }

  > .social-icons{
    display: flex;
    justify-content: center;
    column-gap: 1em;

    svg{
      width: 40px;
      height: 40px;
    }
  }
}

.footer-nav{
  margin: 1em auto;
}

@mixin tablet-mobile-footer{
  .footer-colophon{
    flex-direction: column;
    align-items: center;
  }
  .footer-contact-info{
  .segment {
    &::before {
      display: none;
    }
  }
  .segment:nth-child(2) {
    &::before {
      display: block;
    }

    &::after{
      content: "\007C";
      position: absolute;
      right: calc(-.37em + -1vw);
      font-weight: bolder;
      opacity: .5;
    }
  }

  .seg-group{
    margin: 0.5em auto;
    .footer-address{
      display: block;
    }
  }
  .seg-group:first-child{
    margin-bottom: 0;
  }
  .seg-group:nth-child(2){
    margin-top: 0;
  }
}
}

// Mobile view
@media screen and (max-width: $screen-xs-max) {
  .mobile-footer-actions{
    display: flex;
    flex-direction: column;
    margin: 2em auto;
    align-items: center;
    row-gap: 2em;
  }
  
  .footer-links {
    transition: all 0.2s ease;
    height: 0;
    display: none;
  }
  .footer-links.expanded {
    height: auto;
    display: block;
  }
  .footer-links-section {
    // width: 100%;
    font-size: 1.6rem;
    padding: 1em 0;
    border-bottom: 1px solid $bs-green-accent;
    display: flex;
    flex-direction: column;
  }

  .footer-links-section > strong {
    margin: 0 25px;
    position: relative;
    cursor: pointer;
  }

  .footer-links-section:nth-child(2) {
    border-top: 1px solid $bs-green-accent;
  }

  // .footer-links-section:nth-child(0n+3) {
  //   margin-bottom: 2em;
  // }

  .footer-links {
    margin: 0 25px;
    li{
      margin: 1rem 0;
    }
    a {
      padding: 0.3em 0;
    }
  }

  .footer-nav{
    width: 100%;
    margin: 0 auto;
  }

  .footer-section-heading {
    display: block;
    padding: 1em 15px 0.8em 15px;
    &:after {
      content: '+';
      font-size: 34px;
      position: absolute;
      right: 0;
    }
  }

  .footer-section-heading.expanded:after {
    content: '\2013'; // unicode for en-dash
  }

  .footer-tf-number, .footer-mailto {
    display: block;
  }

  .footer-contact-info {
    text-align: center;
  }
  .footer-contact-info:first-child {
    margin-top: 2em;
  }
  .footer-contact-info:last-child {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .footer-nav-contact{
    flex-direction: column;
  }

  // .body-txt-link{
  //   background-color: transparent;
  //   border: 3px solid $white;
  //   border-radius: 4px;
  //   text-transform: uppercase;
  //   width: -webkit-fill-available;
  //   height: 50px;
  //   padding: 0.5em;
  //   display: inline-block;
  //   margin: 0;
  // }
  .footer-logo-section {
    .bp-logo {
      margin-bottom: 3em;
    }

  }
  .footer.container, .footer-cta.container{
    padding: 0;
  }

  .footer-cta.container > .row{
    margin: 0;
  }

  .footer-contact-info {
    .segment {
      padding: 0 1em;
    }
  }

  @include tablet-mobile-footer
}

// Tablet view
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
  .footer-links-section{
    padding-left: 7%;
    padding-right: 0;
  }
  .footer-links-section {
    // font-size: 1.6rem;
    border-bottom: 1px solid $bs-dark-green,;
  }

  .footer-links-section:first-child {
    border-top: 1px solid $bs-dark-green;
  }
  .social-icons{
    margin: 2em;
  }
  @include tablet-mobile-footer;

  .headline-small{
    line-height: 30px;
  }
  .footer.container{
    width: 100%;
    margin-bottom: 3.5em;
  }
  .footer-rsp{
    > hr{
      margin-bottom: 3em;
    }
  }
}

.footer-contact-info{
  font-family: $bs-font-family-sans;
}

// Desktop view
@media (min-width: $screen-md-min)
{
  .footer-contact-info {
    text-align: center;
  }
  .footer-colophon{
    flex-direction: row;
    justify-content: center;
  }
  .footer-contact-info{
      .segment.first-segment {
        &::before {
          display: none;
        }
      }
  }
  .footer.container{
    width: 100%;
    margin-bottom: 2.5em;
  }
}


@media screen and (min-width: $screen-xs) {
  
  .footer-section-heading {
    // text-transform: uppercase;
    color: #fff;
    margin-bottom: 0;
  }

  .footer-links {
    opacity: 1;
    li{
      margin: 1rem 0;
    }
    a {
    }
  }

  .footer-links-section:first-child {
    margin-top: 1.5em;
  }

  .veracity-links {
    flex-wrap: wrap;
  }

  .footer-seals {
    justify-content: flex-start;
    margin-top: 2em;
  }

  .footer-toll-free {
    display: flex;
    justify-content: center;
    .footer-tf-number, .footer-mailto {
      display: inline;
    }
  }
}


.footer
{
  .headline-small{
    color: $white;
    padding: 0.5em 0;
  }
  .body-txt-small{
    color: $white;
    padding: 0.5em 0;
  }
  li{
    padding: 0;
  }
}

.email-icon
{
  height: 17px;
  width: 22px;
  vertical-align: middle;
  margin-right: 0.3em;
}
.call-icon
{
  height: 21px;
  width: 21px;
  vertical-align: middle;
  margin-right: 0.3em;
}

hr.bs-line{
  border-color: $bs-green-accent;
}

.bs-btn--secondary.reversed{
  display: block;
  margin: 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
