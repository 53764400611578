// Sans-serif family variables:
$font-family-sans: "Open Sans", Helvetica, Arial, sans-serif;
$bs-font-family-sans: "Lato", "Open Sans", Helvetica, Arial, sans-serif;

// Serif font family variables:
$font-family-serif: "Merriweather", Georgia, "Times New Roman", Times, serif;
$bs-font-family-serif: "Lyon Text Web", "Merriweather", Georgia, "Times New Roman", Times, serif;
// $font-family-serif-regular: "Merriweather Regular", Georgia, Times, serif;
// $font-family-serif-regular-italic: "Merriweather Italic", Georgia, Times, serif;
// $font-family-serif-bold: "Merriweather Bold", Georgia, Times, serif;


// z-indexes
$z-index-mobile-filters: 10;
$z-index-mobile-menu-toggle: 1;
$z-index-mobile-nav: 4;
$z-index-pop-cart-container: 2;
$z-index-subnav: 4;
$z-index-suggestions-box: 10;

$menu-background-color: #f0f3f8;
