/*---
what's left of nvend.csss
- in future our hope is to get all of the pages cleaned up and using html and classes etc
  from the ca. 2017 responsive redesign (e.g. results page, about us, faq, checkout pages etc)
- once all the pages are fully integrated into their new home we should be able to refactor and
  clean out these styles and those _nvend_results.scss
---*/

@import "bootstrap_variables";
@import "type";

a {
  // font-weight: 600;

  &.Bod {
    // color: $blue;
  }
}

.h4, .faq_general h2 {
  // font-weight: 700;
  // font-size:14px;
  // line-height:18px;
  // color:#333;
}

.sidebar-bg {
  ul.BodSmall {
    list-style: none;
    margin: 0 0 1em 0;
    padding: 0;
  }

  .h6 {
    font-weight: 700;
    font-size: 0.93em;
    color: darken($blue, 3%);
  }
}

// .sidebar-bg__heading,
// .h3.sidebar-bg__heading {
//   color: darken($blue, 3%);
// }

.textlinki,
a.textlinki {
  font-family: $font-family-serif;
  font-style: italic;
  font-size: 13px;
  line-height: 16px;
  color: $dark-green;

  &:hover,
  &:active,
  &:focus {
    color:#52732d;
  }
}

// filters on the results page
.ListRadio{
  font-size:13px;
  line-height:20px;
  color:#333333;
}

// these styles are from about us, cocopot and retail outlet
// e.g. the pages that have not had their html specifically updated to
// work with the new design and style guide
.alignLeft {
  float:left;
  margin-right:5px;
}

.alignRight {
  float:right;
  margin-left:5px;
}
.callout{
  margin-bottom:10px;
}


/**************PRINT ONLY print only **************/
.printonly {
  display:none;
}

/**************2012 other lists **************/

.faq-callout{
  // margin-bottom: 55px;
}
// .faq_general > ol.faq_list,.faq_general > ol.faq_list_a,.faq_general > h3{
//   max-width: 860px;
//   margin-left: auto;
//   margin-right: auto;
// }
.faq_general > ol.faq_list_a{
  padding-left: 20px;
}
.faq_list{
  list-style-position: inside;
  padding-left: 0;
}

.faq_list li{
  margin-top: 3px;
  @extend .body-txt-medium;
  @extend .link;
}
.faq_list li a{
  @extend .body-txt-medium;
  @extend .link;
  @extend .bold;
}

.faq_list_a li{
  border-bottom: 1px solid #CCC;
  padding-top: 35px;
  padding-bottom: 50px;
}

.faq_list_a li a{
  font-weight: 600;
  
  @extend .body-txt-medium;
  @extend .link;
  @extend .bold;
}

.faq_list_a li b{
  font-weight: 600;
  @extend .body-txt-medium;
}

.faq_list_a li p{
  @extend .body-txt-medium;
}

// used only on cocopot
.bluedot {
  list-style-type:disc;
}

.bluedot li{
  color:#3399cc;
  margin-bottom: 5px;
}

.bluedot li span {
  color:#000;
}

/* General Typography adds Bluestone */
// TODO: these should be reviewed and potentially moved to _shared.scss

.txtl {
  text-align:left;
}
.txtr {
  text-align:right;
}
.txtc {
  text-align:center;
}
.vat {
  vertical-align:top;
}
.vam {
  vertical-align:middle;
}
.vab {
  vertical-align:bottom;
}
.pb3 {
  padding-bottom:3px;
}
.pb5 {
  padding-bottom:5px;
}
.pb10 {
  padding-bottom:10px;
}
.pb15 {
  padding-bottom:15px;
}
.pb20 {
  padding-bottom:20px;
}

.pt3 {
  padding-top:3px;
}
.pt5 {
  padding-top:5px;
}
.pt10 {
  padding-top:10px;
}
.pt15 {
  padding-top:15px;
}
.pl0 {
  padding-left:0px;
}
.pl3 {
  padding-left:3px;
}
.pl5 {
  padding-left:5px;
}
.pl10 {
  padding-left:10px;
}
.pr3 {
  padding-right:3px;
}
.pr5 {
  padding-right:5px;
}
.pr10 {
  padding-right:10px;
}
.pr15 {
  padding-right:15px;
}
.ml3 {
  margin-left:3px;
}
.ml5 {
  margin-left:5px;
}
.ml10 {
  margin-left:10px;
}
.ml20 {
  margin-left:20px;
}
.mr3 {
  margin-right:3px;
}
.mr5 {
  margin-right:5px;
}
.mr10 {
  margin-right:10px;
}
.mr20 {
  margin-right:20px;
}
.mb0 {
  margin-bottom:0px;
}
.mb3 {
  margin-bottom:3px;
}
.mb5 {
  margin-bottom:5px;
}
.mb10 {
  margin-bottom:10px;
}
.mb20 {
  margin-bottom:20px;
}
.mb25 {
  margin-bottom:25px;
}
.mt0 {
  margin-top:0px;
}
.mt5 {
  margin-top:5px;
}
.mt10 {
  margin-top:10px;
}
.mt15 {
  margin-top:15px;
}
.mt20 {
  margin-top:20px;
}
.mt30 {
  margin-top:30px;
}
.mtb10 {
  margin-top:10px;
  margin-bottom:10px;
}
.mtb15 {
  margin-top:15px;
  margin-bottom:15px;
}
.mtb20 {
  margin-top:20px;
  margin-bottom:20px;
}
.ptb10 {
  padding-top:10px;
  padding-bottom:10px;
}
.ptb20 {
  padding-top:20px;
  padding-bottom:20px;
}
.nomargin {
  margin:0 0 0 0;
}
.noleftmargin {
  margin-left:0;
}
.noleftpadding {
  padding-left:0;
}
.img-center {
  display:block;
  margin:auto;
}
.border_red {
  border:1px solid red;
}
.border_blue {
  border:1px solid blue;
}
.border_green {
  border:1px solid green;
}
.showcursor{
  cursor:pointer;
}
.strike {
  text-decoration:line-through;
}

/**End generic typography adds**/

.Bod, .cs_content, .dlist dd{
  @include bs-body-txt-medium;
}
.BodBold{
  font-weight: 700;
  font-size:14px;
  font-weight:normal;
  line-height:21px;
  color:#333333;
}
.Bod_hdr{
  font-weight: 700;
  font-size:14px;
  line-height:21px;
  color:#333333;
}
.ResultPrice{
  font-weight: 700;
  font-size:14px;
  line-height:22px;
  color:#000;
}
.poff{
  width:100%;
  opacity:.80;
  height:16px;

}

h2.ResultItem,
a.ResultItem {
  font-family: $font-family-sans;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 0px;
  margin-top: 0px;

  &:hover {
    color:#005387;
  }
}

.BodSmall{
  @extend %body-txt-small;
}

.BodSmallBold{
  font-weight: 700;
}


/* Form adds */



/*Header 2014 */

.tb_pad {
  padding-bottom:1%;
}

#scart_menuline {
  float:right;
  margin-top:10px;
  margin-bottom:10px;
  margin-right:10px;
  text-align:right;
  color:#999;
}

.container_cart {
  margin-left:auto;
  margin-right:auto;
}

#cart_pop_contain {
  display:none;
  position:absolute;
  height:320px;
  width:415px;
  top:50px;
  right:5px;
  z-index:9999;
}

#cart_pop {
  display:none;
  position:fixed;
  border:4px solid #50711a;
  padding:10px;
  height:3220x;
  width:415px;
  background-color:#FFF;
  z-index:9999;
}

#cart_pop > .nomargin {
  margin-top:12px;
  margin-bottom:3px;
}

.fcolor_g{
  color:#6e9214;
}

.SansSmall,
a.SansSmall {
  font-family: Arial;
  font-style: normal;
  font-weight: 300;
  font-size:11px;
  color:#666;
}


/****************plantfinder PLANT FINDER specific********************/
.b_bg {
  background-color:#f1f3f5;
}

/****************fly page flypage FLYPAGE specific********************/
// this is used a fair bit on old pages
.bb {
  margin-top:20px;
  margin-bottom:20px;
  border-bottom:1px solid #CCC;
}


// lists in the shipping times pop-up
.ListBulleted {
  font-family:"Open Sans Regular";
  font-size:14px;
  line-height:21px;
  color:#333;
}
UL.ListBulleted {
  list-style:none;
  padding-left:0;
  margin-left:15px;
  margin-top:10px;
}

UL.ListBulleted li{
  line-height:26px;
  margin-left:-15px;
  text-transform:capitalize;
}
UL.ListBulleted li:before{
  content: "\2022 \00A0";
  color:#347ca3;
  font-size:1.5em;
  font-weight:bold;
  vertical-align:middle;
}


// price styles for old cart, order detail, wishlist, results
.Price {
  font-weight: 700;
  font-size:18px;
  color:#000;
}
.PriceSale {
  font-weight: 700;
  font-size:18px;
  color:#cd3722;
}
.PriceSlashed {
  font-size:14px;
  color:#333;
  text-decoration:line-through;
}

/****************BANNER BAR********************/
#banner2014 {
  background-color:#ce3722;
  background-color:#3d85ad;
  width: 100%;
  float:left;
  position:relative;
  top:0;
}



/****************FOOTER********************/

#bbb {
  width:32px;
  height:60px;
  float:left;
}

#bbb img{
  border:none
}

#bbb a:link{
  line-height:58px;
}

.f_guarantee_img{
  float:left;
}

/**************GIFT CERT pages styles**************/

#giftdiv{
  display:none;
}

#egiftdiv{
  display:block;
}



/* IC Templates */
/* common */

/*Alternate buttons and overrides and FORM form stuff*/

.btn-grn {
  color: #ffffff;
  font-weight: 600;
  background-color: #52732d;
  background-image: linear-gradient(to bottom, #6d9213, #52732d);
}

.btn-grn:hover,
.btn-grn:focus,
.btn-grn:active,
.btn-grn.active,
.open .dropdown-toggle.btn-grn {
  color: #ffffff;
  background-color: #52732d;
  background-image: none;
}

a.btn-grn {
  &:hover,
  &:active,
  &.active {
    background-color: #52732d;
    background-image: none;
  }
}

.btn-grn.disabled,
.btn-grn[disabled],
fieldset[disabled] .btn-grn,
.btn-grn.disabled:hover,
.btn-grn[disabled]:hover,
fieldset[disabled] .btn-grn:hover,
.btn-grn.disabled:focus,
.btn-grn[disabled]:focus,
fieldset[disabled] .btn-grn:focus,
.btn-grn.disabled:active,
.btn-grn[disabled]:active,
fieldset[disabled] .btn-grn:active,
.btn-grn.disabled.active,
.btn-grn[disabled].active,
fieldset[disabled] .btn-grn.active {
  background-color: #638638;
  border-color: #587930;
}

.btn-red,
a.btn-red {
  color: #ffffff;
  background-color: #aa2b17;
  background-image: (to bottom, #cd3722, #aa2b17);
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.open .dropdown-toggle.btn-red {
    color: #ffffff;
    background-color: #aa2b17;
    background-image: none;
  }
}

.btn-grey {
  color: #ffffff;
  background-color: #757575;
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.open .dropdown-toggle.btn-grey {
    color: #ffffff;
    background-color: #757575;
    background-image: none;
  }
}

/*includes*/

/*fp_solutions*/
/************** fp_category **********/

.fp_cat_row {
  margin-bottom:30px;
  margin-left:-30px;
  &::before{
    display: inherit;
    content: unset;
  }
}
.fp_sol_row {
  margin-bottom:30px;
  line-height:10px;
}
.fp_free_cat {
  margin-top:30px;
}


.gift-cert-amount {
  .form-control-feedback-l {
    left: 12px;
  }

  .form-control.gc-amount-input {
    padding-right: 1.83em;
  }
}

// wishlist
.wl-action {
  @extend %body-txt-small;
}

.lost_email {
  @media screen and (max-width: $screen-sm-max) {
    margin-bottom: 1em;
  }
}

