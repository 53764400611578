.cart-heading {
  position: relative;
  margin-bottom: 1.5em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid #ccc;

  @media screen and (min-width: 767px) {
   .cart-head-checkout-btn {
     position: absolute;
     top: 0;
     right: 0;
     width: 12em;
   }
  }
}

.cart-heading > h1,
.cart-heading > h2
{
  @extend %headline-large;
}

.cart-help {
  @extend %body-txt-large;
}

.cart-shiptime-heading,
.co-beta h3.cart-shiptime-heading {
  margin-top: 45px;
  @include bs-headline-medium-modified;
  padding-bottom: 0.5em;
  margin-bottom: 1em;

  .co-beta & {
    margin-bottom: 0.5em;
  }

  .cart-body &:nth-child(1n+2) {
    border-top: 1px solid #ddd;
    padding-top: 1em;
  }
}


.cart-body .cart-item {
  border-bottom: none;

  // a, .cart-action-link {
  //   color: $blue;
  // }
}


.item-qty-input {
  width: 3.5em;
  border: 1px solid #ccc;
  display: inline-block;
}

.item-figure {
  // line up top of image with top of item name
  padding-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-pricing {
  @media screen and (min-width: 767px) {
    text-align: right;
  }
  @media screen and (max-width: 766px) {
    margin-top: 10px;
  }
}

.item-price {
  @include bs-body-txt-price;
  @extend %no-bottom-margin;
  font-weight: 700;

}

.item-price--sale {
  color: $red;
  font-weight: 700;
}

.item-price--qty-sale-price {
  display: inline-block;
  margin-left: 0.3em;
}

.cart-item .pd-three-plus,
.cart-item .muted {
  padding-right: 0;
}

.pd-three-plus--qty-sale.muted {
  padding-left: 0;
}

// .item-price-msg,
// .cart-action-link,
.item-actions {
  margin-top: 10px;
  a, button {
    @include bs-body-txt-link;
  }
  > span{
    margin: 0 5px;
    @media screen and (max-width: 767px){
      display: block;
      visibility: hidden;
      line-height: 0;
      margin-bottom: 5px;
    }
  }
}

.item-details-wrap {
  // 20px gutter instead of Bootstrap's default 30px
  padding-left: 35px;

  @media screen and (max-width: 420px){
    padding-left: 20px;
  }
}

.cart-totals {
  width: 100%;
}

.cart-totals,
.cart-actions-form,
.checkout-and-links {
  margin-bottom: 1em;
}

.cart-totals-label,
.cart-totals-value {
  padding: 0.1em;
}

.cart-totals-label {
  padding-left: 0;
  @include bs-body-txt-small;
}

.cart-e-zip-label {
  display: block;
}

.cart-totals-value {
  text-align: right;
  padding-right: 0;
  font-weight: 700;
  @include bs-body-txt-price;
  &.cart-totals-savings {
    font-weight: 900;
    color: $bs-green;
    padding-left: 0;
    font-family: $bs-font-family-sans-serif;;
    font-size: 15px;
    padding-top: 0;
  }
}

.cart-totals-shipping_x {
  .cart-totals-label,
  .cart-totals-value {
    padding-bottom: 1.0em;
  }
}

.cart-totals-grand-totals {
  .cart-totals-label,
  .cart-totals-value {
    padding-top: 15px;
    border-top: 1px solid #ccc;
  }
}

.cart-totals-grand-total-label {
  @include bs-headline-tiny;
}
.cart-totals-grand-total{
  @include bs-body-txt-medium;
  font-weight: bold;
}

h2.cart-totals-caption,
.cart-totals-caption,
.cart-coupon-form-heading {
  margin-bottom: 0px;
}
.cart-coupon-form-heading {
  @include bs-headline-small;
}

h2.cart-totals-caption {
    margin-bottom: 0px;
}

.cart-totals-caption {
  padding-top: 0;
}

.last-item-before-total{

> .cart-totals-label, .cart-totals-value{
  padding-bottom: 20px;
}

}

.cta-checkout-btn,
.cart-coupon-form-btn {
  width: 100%;
  text-align: center;
  min-width: auto;
}

.checkout-ctas--bottom .co__divider-wrap {
  margin: 0 1em 0 0;
}

.co__divider-wrap {
  width: 7em;
  margin: 0 auto;
}

.co__divider {
  margin: 0.5em 0 1em 0;
  display: flex;

  &::before,
  &::after {
    content: '';
    flex: 1;
    background: linear-gradient(to bottom,
      rgba(255, 255, 255, 0) 45.5%
      #ccc 45.5%,
      #ccc 52.5%,
      rgba(255, 255, 255, 0) 52.5%);
  }

  &::after {
    margin-left: 0.2em;
  }

  &::before {
    margin-right: 0.2em;
  }
}

.btn--co-continue {
  display: block;
  min-width: auto;
  margin-top: 1em;
  margin-bottom: 3em;
  width: 100%;
}

button.bs-btn.btn--co-continue[form=order-info-form].left-btn{
  @media screen and (min-width: $screen-md-min) {
    max-width: 220px;
  }
}

.cart-main {
  @media screen and (max-width: 767px) {
    .rsp-btn {
      width: 100%;
    }
  }
}

.secure-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.cart-footer-main,
.cart-footer-secondary {
  border: 4px solid $green;
  padding: 25px;
}

.cart-ezip-input {
  height: 40px;
}

.ezip-input-group {
  display: flex;
  margin-bottom: 2em;
}

.rsp-btn--ezip-submit {
  border-radius: 0 3px 3px 0;
}

.cart-shipping-est-form-wrap {
  display: none;
  border-top: none 0;
  .cart-ezip-input {
    @media screen and (min-width: 768px) {
      margin-right: -5px;
    }

    @media screen and (max-width: $screen-xs-max) {
      margin-bottom: 1em;
    }
  }


}

.cart-footer {
  input[type="text"] {
    border: 1px solid #ccc;
    padding: 0.5em 0.35em;
    @include plain-text-input;
    height: 40px;
    @media screen and (max-width: 767px) {
      margin-bottom: 1em;
    }
  }
}

.cart-footer-main {
  display: flex;

  @media screen and (max-width: 767px) {
    flex-direction: column;

    .cta-checkout-btn {
      margin-bottom: 1em;
      margin-right: 0.5em;
    }

    .cart-coupon-input {
      width: 100%;
    }

    .cart-checkout-links {
      text-align: center;
      a {
        display: block;
        margin: 0.5em 0;
        &.edit-zip-link {
          display: inline;
        }
      }
    }

    .cart-shipping-est-form-wrap {
      width: 100%;
      margin-bottom: 2em;

      &:after {
        content: '';
        height: 4px;
        display: block;
        margin: 2em -25px 0 -25px;
        background-color: $dark-green;
      }
    }
  }


  .cart-coupon-form-wrap {
    order: 4;
  }

  .cart-shipping-est-form-wrap {
    order: 3;
  }

  @media screen and (min-width: 768px) {
    flex-wrap: wrap;
    .cart-totals {
      width: 41%
    }

    .cart-coupon-form-wrap {
      width: 58%;
      .cart-coupon-input {
        width: 16em;
      }

    }

    .cart-totals {
      order: 2;
      // kinda hacky but aligning this by hand b/c the layout is complicated by
      // flexbox, re-ordering etc
      margin-left: 8px;
    }

    .checkout-and-links {
      order: 3;
    }

    .cart-coupon-form-wrap {
      order: 1;
    }

    .cart-coupon-form {
      order: 1;
      display: inline-flex;
      .cart-coupon-input {
        margin-right: 0.5em;
      }
    }

    .checkout-and-links {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .cta-checkout-btn {
        order: 1;
        width: 12em;
      }
    }

    .cart-shipping-est-form-wrap {
      order: 4;
      width: 100%;
      &:before {
        content: '';
        display: block;
        height: 4px;
        margin: 1.5em -25px;
        background-color: $green;
      }
    }

    .cart-checkout-links {
      margin-bottom: 0;
      display: inline-flex;
      align-items: center;
      margin-right: 1em;
      & > li {
        margin: 0 0.5em;
      }
    }
  }

}

.sidebar-quick-order {
  padding-bottom: 1.5em;
  margin-bottom: 1em;
  border-bottom: 1px solid #ccc;
}

.secure-checkout {
  margin-bottom: 2em;
}

.secure-checkout-tf-link,
a.secure-checkout-tf-link {
  display: block;
  padding: 0.5em;
  padding-bottom: 0;
  @include bs-headline-small;
}

.sidebar-quick-order {
  @media screen and (min-width: 993px) {
    .quick-order-sku-input,
    .quick-order-add-to-cart-btn {
      width: 100%;
      margin-bottom: 10px; // to match default bottom margin of <p>
    }

    .quick-order-sku-input {
      margin-bottom: 0.5em;
      padding: 0.5em 0.25em;
    }
  }
}

.sidebar-toll-free,
.guarantee-tagline,
.guarantee-tagline > a {
  margin: 0.4em 0 0.15em 0;
  color: $bs-dark-green!important;
}

.quick-order-multi-form {
  margin-bottom: 5em;
}

// mobile/tablet only
.cart-quick-order {
  background: $light-blue;
  padding: 10px 15px;


  .quick-order-sku-input {
    height: 40px;
    border: 1px solid #ccc;
    width: 8em;
  }

  .cart-quick-order-input-group {
    display: flex;
    margin: 1em 0;
    :first-child {
      margin-right: 10px;
    }

    .rsp-btn {
      width: auto;
      padding-left: 1em;
      padding-right: 1em;
      @media screen and (min-width: 420px) {
        padding-left: 2em;
        padding-right: 2em;
      }
    }
  }
}

.gift-info-detail {
  @extend %no-bottom-margin;
  @extend .headline-h5;
  @extend .body-txt-small;
  font-weight: normal;
  line-height: normal;
}

.spacing{

  @media screen and (max-width: 767px) {
    margin-bottom: 20px!important;

  }

  @media screen and (min-width: 768px) {
    margin-bottom: 35px!important;

  }
}

.gift-info-detail:nth-child(4), .gift-info-detail:nth-child(5){
  margin-bottom: 15px;
}

.gift-info-detail:nth-child(5){
  margin-bottom: 25px;
}

.gift-info-label {
  font-weight: 400;
}

.gift-amount-input {
  text-align: right;
  display: inline-block;
  width: auto;
}

.cart-item-gift-edit {
  display: none;
  &.cart-item-gift-edit--gbox {
    margin-bottom: 1em;
  }
}

.gift-edit-details {
  appearance: none;
  border: none 0;
  background-color: #fff;
  padding: 0.2em 0.5em;
  margin-left: -0.5em;
}

.gift-info-form-legend {
  font-size: 1.6rem;
  font-weight: 700;
  border: none 0;
  margin: 0 0 0.25em 0;
}

.btn--gc-edit-toggle {
  appearance: none;
  border: none 0;
  background: #fff;
}

.pd-gift-checkbox {
  margin-top: 30px;
}

.custom-checkbox-input:checked~.custom-label--checkbox[for="js-gift-checkbox"]:after{
  top: 4px;
}

.gift-amount-cents {
  display: inline-block;
  margin-right: 0.5em;
}

.rsp-btn--save-gift-info {
  padding-right: 2.5em;
  padding-left: 2.5em;
}

.row.edit-gift-form {
  @media screen and (max-width: 426px) {
    margin-top: 1em;
  }
}

.gift-table {
  td > b {
    display: block;
    text-align: right;
    padding-top: 0.6em;
  }

  textarea {
    border-color: #ddd;
  }
}


.rsp-btn--calculate {
  padding-left: 3em;
  padding-right: 3em;
}

.cart-wishlist {
  margin-top: 4em;
}


.pagination--wl > li:first-child {
  > a,
  > span {
    border-radius: 0;
  }
}

.pagination--wl > li:last-child {
  > a,
  > span {
    border-radius: 0;
  }
}

.pagination--wl {
  > li > {
    a,
    span {
      color: $blue;
      padding: 8px 16px;
    }
  }
}

.pagination--wl {
  > .active > {
    a,
    a:hover,
    a:active,
    a:focus,
    span {
      color: $body-text;
      background-color: #fff;
      border-color: #ddd;
      text-decoration: underline;
    }
  }
}

.pagination--wl > li > a:focus {
  outline: none;
}

.pagination--wl .disabled {
  @media screen and (max-width: 650px) {
    display: none;
  }
}

.wishlist-pagination {
  text-align: center;
  @media screen and (min-width: 420px) {
    float: right;
  }
}

#wl-app[v-cloak] {
  display: none;
}

.co-box {
  padding: 35px 35px 50px;
  background: $white;
  border: 10px solid $bs-cyan;
  @media screen and (max-width: 420px) {
    padding: 17px 17px 25px;
    &.mb2{
      margin-bottom: 0;
    }
  }
}

.co-box.mb2{
  
}

.paypal-wordmark {
  width: 59px;
}

.btn--paypal-checkout {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  height: 40px;

  .checkout-ctas--top & {
    width: 100%;
  }

  &:focus {
    box-shadow: -1px -1px 18px 1px rgba(0, 0, 0, 0.25) inset;
    outline: none;
  }

  &:hover, &:active {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.05)
  }
}

.checkout-ctas--top {
  .apple-cta-btn,
  .paypal-button-container,
  .cta-checkout-btn{
    margin-top: 20px;
  }
}
.paypal-button-container {
  position: relative;
  z-index: 0;
  height: 55px;
}

.paypal-button-text,
a .paypal-button-text {
  color: $body-text;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px; // to match the styles PayPal are using for the smart buttons
  font-weight: 500;
  display: inline-flex;
  margin-right: 0.35em;
}

.checkout-ctas--bottom {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  align-items: normal;
  margin-bottom: 3em;
  @media screen and (max-width: 991px){
    justify-content: center;
  }
  @media screen and (max-width: 767px){
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
  }
  .btn--paypal-checkout,
  .cta-checkout-btn,
  .paypal-button-container {
  // .apple-button-container {
    width: 100%;
  }
}

.rsp.checkout-tmpl .page-wrap {
  flex: 1 0 auto;
}

.page--payment .co-alpha {
  @media screen and  (max-width: $screen-sm-max) {
    order: -1;
  }
}

.page--payment .btn--co-continue {
  margin-bottom: 1em;
}

.footer-rsp--checkout {
  padding: 1.5em 0;
}

.co {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 55px;

  @media screen and (max-width: 420px){
    margin-top: 15px;
  }
}

.co-gamma {
  margin-bottom: 3em;

  @media screen and (max-width: $screen-sm-max) {
    order: 4;
  }
}

.vert-divider {
  display: inline-flex;
  width: 1px;
  background: #ccc;
  height: 0.8em;
  margin: 0 0.5em;
}

.nav-tabs.cart-tabs {
  margin-bottom: 2em;
}

.nav.nav-tabs.cart-tabs > li > a {
  color: $body-text;
  border: 4px solid transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  padding-right: 0;
  padding-left: 0;
  margin-right: 1.5em;
}

.nav-tabs.cart-tabs > li.active > a {
  color: $bs-green;
  border-color: $bs-green;

  &:hover,
  &:active,
  &:focus {
    color: $bs-green;
    border: 4px solid $bs-green;
    border-top: none;
    border-right: none;
    border-left: none;
  }
}

.nav-tabs.cart-tabs > li > a:hover {
  border-color: #ccc;
  background-color: transparent;
}

.container--main-checkout {
  margin-top: 0;
}

.dev-site-banner {
  transform-origin: top left;
  transform: rotate(-90deg);
  text-transform: uppercase;
  background: #c00;
  color: #fff;
  padding: 0.3em 3em;
  position: fixed;
  top: 100%;
  left: 0;
}



.sign-in-promo .rsp-btn {
  @media screen and (min-width: $screen-sm-min) {
    margin-top: -0.5em;
    margin-left: 1em;
  }
}

.sign-in-promo {
  border-bottom: 1px solid #ccc;
  padding-bottom: 80px;
  margin-bottom: 30px;
  @media screen and (max-width: 420px){
    h2{
      margin-top: 0!important;
    }
  }
}

.co-status-msg {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.initially-hidden {
    display: none;
  }

  &.co-status-msg--modal-login {
    margin-bottom: -15px;
  }

  &.error {
    color: $bs-red;
    font-weight: 600;
    font-style: italic;
    // &:before {
    //   content: '\0021';
    //   width: 18px;
    //   height: 18px;
    //   border-radius: 50%;
    //   color: #fff;
    //   background-color: $red;
    //   margin-right: 0.5em;
    //   display: inline-flex;
    //   justify-content: center;
    //   align-items: center;
    //   font-size: 0.9em;
    //   text-shadow: 0 0 2px rgba(0,0,0, 0.75);
    //   transform: translateY(-1px);
    // }
  }
}

.co-form {
  // margin-top: 20px;
}

.co-form .form-control {
  height: 40px;
}

.co-form textarea.form-control {
  height: auto;
}

.co-form input[type="checkbox"] {
  margin-top: 5px;
}

.co-form .form-group {
  margin-bottom: 20px;
}

.co-apply-btn {
  min-width: 12em;
}

.checkbox.co-bottom-field {
  margin-bottom: 2.5em;
}
.co-bottom-field {
  margin-bottom: 2.5em;
}

.co-invalid-group label,
.invalid-group label {
  color: $red;
}

.co-invalid.form-control,
.invalid-group .form-control {
  border-color: $red;
}

.co-invalid-group p.co-invalid.error,
.invalid-group p.error {
  @extend .error-txt; 
  color: $bs-red;
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  font-style: italic;
  font-size: 16px;
  font-weight: bold;
  // &:before {
  //   content: '\0021';
  //   width: 18px;
  //   height: 18px;
  //   border-radius: 50%;
  //   color: #fff;
  //   background-color: $bs-red;
  //   margin-right: 0.5em;
  //   display: inline-flex;
  //   justify-content: center;
  //   align-items: center;
  //   font-size: 0.9em;
  //   text-shadow: 0 0 2px rgba(0,0,0, 0.75);
  // }
}


.co-header {
  display: flex;
  flex-wrap: wrap;
}

.co-header .bp-logo-link--checkout {
  // width: 95px;
  // height: 44px;
  display: flex;
}

.co-header .co-return-to-cart-link-wrapper {
  display: flex;
  flex: 1 0 auto;
  justify-content: end;
  align-items: center;
}

.co-return-to-cart-link,
a.co-return-to-cart-link {
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: $font-family-serif;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 0.85em;
}

.co-step {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  font-size: 0.8em;
  margin-right: 35px;
  &.active {
    color: $blue;
  }

  > svg{
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

}

.co-step-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8d8f92;
  color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 0.5em;

  .active & {
    background-color: $blue;
  }

  .completed &{
    background-color: $green;
    font-weight: 700;
  }
}

.co-cart-item-count {
  color: $body-text;
  font-family: $font-family-sans;
  font-weight: normal;
}

.co-steps {
  display: flex;
  justify-content: space-between;
  margin-left: 2em;
}

.co-step-label-segment {
  display: block;

  @media screen and (min-width: $screen-sm-min) {
    display: inline;
  }
}

.shiptime-select__form {
  //display: flex;
}

.shiptime-select__muted {
  font-size: 0.9em;
  color: lighten($body-text, 20%);
}

.shiptime-select__radio-label {
  font-weight: 600;
  margin-bottom: 0;
}

.shiptime-select__shipdate-input,
.shiptime-select__shipdate-picker {
  margin-bottom: 2em;
}

.shiptime-select__calendar .cell.day.selected {
  background: $blue;
  color: #fff;
}

.shiptime-select__picker-input {
  width: auto;
  min-width: 18em;
  padding: 6px 12px;

  .co-form &.form-control {
    height: 50px;
    border-radius: 3px;
  }

  &[readonly] {
    background: #fff;
  }
}

.shiptime-select__est {
  font-weight: 600;
  color: $blue;
  margin-bottom: 0;

  &.shiptime-select__est--alert {
    color: $red;
    font-weight: normal;
  }
}



input#special-circumstance {
  margin-left: 0;
}

label[for="special-circumstance"] {
  margin-bottom: 1em;

  input#special-circumstance:checked + & {
    margin-bottom: 1em;
  }
}

.shiptime-select__special-instructions {
  margin-bottom: calc(1em + 20px);
}


.receipt__heading {
  // @extend %headline-large;
}

.receipt__account-benefits {
  padding-left: 1.3em;
  margin-bottom: 3em;
}

.receipt__copy, .receipt__account-benefits {
  font-family: $font-family-serif;
}

.receipt__conf-num {
  margin-top: 40px;
}

.receipt__divider {
  margin: 3em 0;
}

.co-create-acct-form-msg {
  margin-bottom: -1em;
  color: $dark-green;

  &.error {
    color: $red;
  }
}

// desktop and up
@media screen and (min-width: $screen-md-min) {
  .co-steps {
    margin: 0;
    margin-left: 50px;
    justify-content: space-around;
    order: 2;
  }
  .co-return-to-cart-link-wrapper {
    order: 3;
  }
  .bp-logo-link--checkout{
    order: 1;
  }
}

// tablet and down
@media screen and (max-width: $screen-sm-max){
  .co-steps{
    order: 3;
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
  }
  .co-return-to-cart-link-wrapper {
    order: 2;
  }
  .bp-logo-link--checkout{
    order: 1;
  }
}

@media screen and (max-width: 420px) {
  .co-steps{
    display: none;
  }
}

.co-cc-logos {
  @media screen and (max-width: $screen-xs-max) {
    margin: -13px 0 20px 0;
  }

  @media screen and (min-width: $screen-sm-min) {
    transform: translateY(2.6em);
  }
}

.co-beta h3 {
  @include bs-headline-small;
}

.shipping-options-group h3 {
  @include bs-headline-small;
}

.co-address-details {
  margin-bottom: 1em;
}

.co-address-details p {
  margin: 0;
  line-height: 1.5;
}

.gc-message-container {
  margin-top: -10px;
}

.gc-message-container .alert {
  padding: 5px 10px;
}

.cart-alert {
  min-height: 55px;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: bold;

  > ul {
    margin-bottom: 0;
  }
}

@media screen and (min-width: $screen-md-min) {
  .co {
    display: grid;
    grid-template-columns: 4.5fr minmax(355px, 1.5fr);
  }

  .co-heading {
    grid-row: 1;
    grid-column: 1;
  }

  .co-alpha {
    grid-column: 2;
    grid-row: 1 / 3;
    position: sticky;
    top: 2em;
    z-index: 2;
  }

  .co-beta {
    grid-row: 2 / 5;
    grid-column: 1;
    margin-bottom: 40px;
    @media screen and (min-width: $screen-md-min) {
      // margin-right: 90px;
    }
  }

  .co-beta {
    .row{
      margin-left: 0;
      margin-right: 0;
    }
    
  }

  .co-gamma {
    grid-row: 3;
    grid-column: 2;
  }

  .co-delta {
    grid-row: 5;
    grid-column: 1 / 3;
  }


  .checkout-ctas--bottom .btn--paypal-checkout,
  .checkout-ctas--bottom .paypal-button-container {
  }

  .checkout-ctas--bottom {
    // flex-direction: row-reverse;

    .cta-checkout-btn {
    }

    .btn--paypal-checkout,
    .paypal-button-container {
    }

    .btn--paypal-checkout {
      margin-bottom: 5px;
    }
  }

  .co-header {
  }

  .co-header .bp-logo-link--checkout {
    // width: calc(190px + 1em);
    // height: calc(88px + 1em);
  }

  .co-steps {
  }

  .co-step {
    font-size: 0.9em;
  }
}

.guarantee-xtagline{
  @include bs-headline-small;
}

.checkout-header{
  padding: 20px 0;
}

.line-after-header{
  margin-top: 0;
  margin-bottom: 20px;
  @media screen and (max-width: 420px){
    margin-bottom: 15px;
  }
}
.sign-in-promo-cta{
  margin-top: 5px;
}

.item-pricing{
  >.body-txt--oldprice{
    margin-right: 5px;
  }
}

.finalize-cart-quantity{
    vertical-align: top;
    @media screen and (max-width: 420px){
    text-align: left;
  }
}

// quantity input box
.col-sm-3.no-padding.text-center.quantity{
  text-align: left;
}

.cart-gift-submit{
  min-width: 100%;
}

.form-group.col-sm-6.no-padding.item-details-wrap,.col-sm-6.no-padding.item-details-wrap{
  @media screen and (max-width: 767px){
    padding-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .co-alpha > .co-box.mb2{
    margin: 25px 0;
  }
}

@media (min-width: 992px){
  .js-order-summary {
      display: block;
      height: 100% !important;
  }
}
@media (max-width: 991px){
  .js-order-summary {
      display: none;
  }
  .co-alpha>.co-box:has(>div>h3[aria-expanded=false]) {
    padding: 0.5em 1em;
  }
}

.cart-totals-caption[aria-expanded=true]{
  &::after{
    content: '-';
    font-weight: bolder;
    font-size: 35px;
    float: right;
  }
}
.cart-totals-caption[aria-expanded=false]{
  &::after{
    content: '+';
    font-weight: bolder;
    font-size: 35px;
    float: right;
  }
}

.item-buying-options.h4{
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.group-separator{
  margin-bottom: 45px;
}

.cart-body > hr:nth-child(2){
  display: none;
}