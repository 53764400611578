input {
  padding: 0;
}

.input-group-addon {
  outline: 1px solid $bs-light-grey;
}

.error-icon{
  display: none;
}

.error-icon-svg{
  height: 17px;
  width: 5px;
}

@mixin input-properties {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  border: none;
  border: 1px solid $bs-light-grey;
  padding: 12px 16px;
  height: 55px;
  color: $bs-dark-green;

  &:focus {
    border: 3px solid $bs-blue;
  }

  &.error {
    border: 3px solid $bs-red;
    display: inline-block;
    color: $bs-red;

    +span.error-icon {
      display: inline-block;
      position: absolute;
      right: 10px;
      margin-top: 12px;
    }
  }

  @media screen and (max-width: $screen-xs-max), (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    height: 50px;
  }
}

.bs-input,
.bs-input.form-control {
  @include input-properties;
  font-size: 20px;
}

.bs-input-small{
  @include input-properties;
  @include bs-body-txt-input-small;
  height: 50px;
  border: 1px solid $bs-light-grey;
}

textarea.bs-input.form-control {
  height: auto;
  @include input-properties
}

select.bs-input{
  padding-right: 25px!important;
  &.error {
    +span.error-icon {
      right: 20px!important;
    }
  }
}

.bs-tag{
  border-radius: 3px;
  border: none;
  outline: 2px solid $bs-soft-grey;
  padding: 11px 16px;
  height: 40px;
  @media screen and (max-width: $screen-xs-max), (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    height: 35px;
    padding: 8px 16px;
  }
  &::after{
    content: "\00d7";
    display: inline-block;
    margin-left: 10px;
    transform: scale(1.25);
    line-height: 1;
    cursor: pointer;
  }
}

.message {
  font-family: $bs-font-family-sans-serif;
  font-weight: bold; 
  @media screen and (max-width: $screen-xs-max) {
    font-size: 20px;
    line-height: calc(28 / 20);
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
    font-size: 22px;
    line-height: calc(32 / 22);
  }

  @media (min-width: $screen-md-min){
    font-size: 22px;
    line-height: calc(32 / 22);
  }

  &.error {
    color: $bs-red;
  }

  &.success {
    color: $bs-green;

    &::before{
      content: url(/dist/rsp/svg/intermediate-site-icons/Checkmark.svg);
      margin-right: 7px;
    }
  }
}



input::-webkit-input-placeholder { /* Edge */
  color: $bs-light-grey!important;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $bs-light-grey!important;
}

input::placeholder {
  color: $bs-light-grey!important;
}
input:-moz-placeholder {
  color:$bs-light-grey!important;
}


textarea::-webkit-input-placeholder { /* Edge */
  color: $bs-light-grey!important;
}

textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $bs-light-grey!important;
}

textarea::placeholder {
  color: $bs-light-grey!important;
}
textarea:-moz-placeholder {
  color:$bs-light-grey!important;
}