@import "bootstrap_variables";

a {
  text-decoration-skip-ink: auto;
}

// .form-control {
//   border-radius: 0;
// }

.larger-input {
    border-radius: 3px;
}

hr {
  border-color: #bfbfbf;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 0;
}

.pagination > li.active {
  a, span {
    background-color: #fff;
    text-decoration: underline;
    color: $body-text;
    border-color: $pagination-border;

    &:hover, &:active, &:focus {
      background-color: $pagination-bg;
      border-color: $pagination-border;
      color: $body-text;
    }

  }
}

.pagination > li {
  a, span {
    &:active, &:focus {
      outline-color: #ddd;
    }
  }
}

@media (prefers-reduced-motion) {
  .img-thumbnail, .fade {
    transition: none;
  }
}

.btn-lg {
    padding: 12px 32px;
}

@mixin container {
  @media (max-width: 420px){
    width: 100%;
    padding-right: ceil(($grid-gutter-width-xs / 2));
    padding-left: floor(($grid-gutter-width-xs / 2));
  }
  @media (min-width: 421px) and (max-width: 767px) {
    width: 100%;
    padding-right: ceil(($grid-gutter-width-sm / 2));
    padding-left: floor(($grid-gutter-width-sm / 2));
  }
  @media (min-width: 768px) and (max-width: 1540px) {
    width: 100%;
    padding-right: ceil(($grid-gutter-width-md / 2));
    padding-left: floor(($grid-gutter-width-md / 2));
  }
  @media (min-width: 1541px) 
  // and (max-width: 1999px) {
  //   max-width: 1300px+$grid-gutter-width-md;
  //   padding-right: ceil(($grid-gutter-width-md / 2));
  //   padding-left: floor(($grid-gutter-width-md / 2));
  // }
  // @media (min-width: 2000px) 
  {
    max-width: 1300px;
    padding-right: 0;
    padding-left: 0;
  }
}

.container{
  @include container;
}

@mixin container-header {
  @media (max-width: 420px){
    width: 100%;
    padding-right: ceil(($header-grid-gutter-width-xs / 2));
    padding-left: floor(($header-grid-gutter-width-xs / 2));
  }
  @media (min-width: 421px) and (max-width: 767px) {
    width: 100%;
    padding-right: ceil(($header-grid-gutter-width-sm / 2));
    padding-left: floor(($header-grid-gutter-width-sm / 2));
  }
  @media (min-width: 768px) and (max-width: 1540px) {
    width: 100%;
    padding-right: ceil(($header-grid-gutter-width-md / 2));
    padding-left: floor(($header-grid-gutter-width-md / 2));
  }
  @media (min-width: 1541px) 
  // and (max-width: 1999px) {
  //   max-width: 1460px+$header-grid-gutter-width-md;
  //   padding-right: ceil(($header-grid-gutter-width-md / 2));
  //   padding-left: floor(($header-grid-gutter-width-md / 2));
  // }
  // @media (min-width: 2000px) 
  {
    max-width: 1460px;
    padding-right: 0;
    padding-left: 0;
  }
}

.container-header{
  @include container-header;
}

.container-fluid{
  padding-left: 0px;
  padding-right: 0px;
}

a {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
